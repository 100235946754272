import React, { Component } from 'react';
import { Button, Input, TabContent, TabPane, Nav, NavItem, NavLink, Modal } from 'reactstrap';
import toastr from 'toastr';
import objectToFormdata from 'object-to-formdata';
import Loadable from 'react-loading-overlay';
import urlParse from 'url-parse';
import bytes from 'bytes';
import classnames from 'classnames';
import AdSense from 'react-adsense';
import reactWindowSize from 'react-window-size';
import { Menu as FabMenu, MainButton as FabMainButton, ChildButton as FabChildButton } from 'react-mfb';
import platform from 'platform';
import ReactTooltip from 'react-tooltip';
import { Range } from "../ui";

import 'video-react/dist/video-react.css';

import apiClient from '../util/api-client';
import AnimationPlayer from './AnimationPlayer';
import AnimationImage from './AnimationImage';
import AnimationMarkersPane from './AnimationMarkersPane';
import AnimationCommentForm from './AnimationCommentForm';
import AnimationGetUrlModal from './AnimationGetUrlModal';
import ProjectShareModal from './ProjectShareModal';
import ProjectInfoSection from './ProjectInfoSection';
import ProjectFormModal from './ProjectFormModal';
import AnimationUploaderModal from './AnimationUploaderModal';
import CompletionModal from './CompletionModal';
import PublicUrlModal from './PublicUrlModal';
import { canUseUrlFeature, canUseZoomImageFeature, canUpdateProject, canRequestAgency, canCreateAnimation, canDeleteAnimation, canUpdateAnimation, canCreateAssignment, canCreateAnimationMarker, canCreateComment, canCreateCommentPublicUser, canCreateAndUpdatePage, canDeletePage, canUserNotPermission, canHideCommentsFromCollaborators } from '../authorizations';
import { version } from '../util/animation';
import AnimationUrl from './AnimationUrl.jsx'
import AnimationNotify from './AnimationNotify'
import AnimationPages from './AnimationPages.jsx'
import { reload as reloadActivities } from './ActivitiesButton';
import IconUploadNew from 'images/upload-new.png';
import bigIconUpload from '../ui/icons/bigIconUpload.svg';
import bigIconEarth from '../ui/icons/iconEarth.svg';
import iconUpload from '../ui/icons/upload-img.png'
import iconPen from '../ui/icons/icon_pen_new_white.svg'
import iconLink from '../ui/icons/link-img.png'
import iconGlobal from '../ui/icons/iconEarth.svg'
import { btnLoadTicketPage } from '../util/buildUrl';
import GetUrlYoutube from './GetUrlYoutube'
import axios from 'axios';
import ShowEmbedVideo from './ShowEmbedVideos';
import { fetchHeightHeader } from '../shared';
import ModalDownloadImage from './ModalDownloadImage'
import ModalDownloadVideo from './ModalDownloadVideo'
import ModalFirstUploadAkaire from './ModalFirstUploadAkaire'
import AnimationMarkerLine from './AnimationMarkerLine';
import ModalChangeCommentList from './ModalChangeCommentList.jsx';
import NoAnimationPreview from 'images/no_animation_preview.png';
import AnimationMarkerPeriod from './AnimationMarkerPeriod.jsx';
import ModalConfirmSendMailNotLogin from './ModalConfirmSendMailNotLogin.jsx';
import ModalInputInfoOfNotLogin from './ModalInputInfoOfNotLogin.jsx';
import ModalShowCreateCommentNotLogin from './ModalShowCreateCommentNotLogin.jsx';

const url = new URL(location.href);
const queryAnimationId = url.searchParams.get("animation_id");
const queryPageId = url.searchParams.get("page_id") || url.searchParams.get("update_version_page");

const _10_MB = 10 * 1024 * 1024;
const MARKER_NOTIFICATION_TEXT =
  `[via Akapon]
  赤入れが投稿されました。
  `;

const ONE_GB_TO_BYTES = Math.pow(2,30);
const PLANS = ['free', 'minimum', 'basic', 'master']
const PLANS_TEXT = ['無料','ミニマム','ベーシック','マスター']
const REGEXP_URL = /projects\/(\d+)\/(\d+)\/akaire\/(\S)/
const TICKET_REGEXP_URL = /projects\/(\d+)\/(\d+)\/(\d+)\/akaire\/(\S)/
const TASK_TYPE = { web: 'web', image: 'image' }

class AnimationInspector extends Component {
  constructor(props) {
    super(props);
    const { id, baseURL, rootUrl, port, last_page_id, currentTicketId, token_public_url, is_create_video_embed, is_create_pass_video,
      pass_word_share_url, page_last_animation, noti_compare_page, noti_compare_version, avatar_url, show_mode, akaire_file_id, akaire_file_name } = props;
    this.props = { ...this.props, ...props };
    this.state = {
      user: {},
      project: {},
      loadingText: null,
      percentComplete: 0,
      animations: [],
      markers: [],
      pages: [],
      tickets: [],
      comments: [],
      commentPoint: null,
      currentAnimationId: null,
      currentPageId: last_page_id,
      assignments: [],
      shouldShowGetUrlModal: false,
      shouldShowProjectShareModal: false,
      shouldShowProjectFormModal: false,
      shouldShowAnimationUploaderModal: false,
      shouldShowCompletionModal: false,
      shouldShowPublicUrlModal: false,
      shouldShowGetUrlYoutube: false,
      isLoading: false,
      activeTab: 'comments-timeline',
      timerCheckAnimation: null,
      responseConvertVideo: null,
      currentAnimationExtension: {},
      timerDownloadExtensionFile: null,
      downloadExtensionUrl: null,
      convertFile: false,
      completed: 0,
      finishConvert: false,
      heightAniamtion: 0,
      changeCss: false,
      isDeletePage: false,
      readMoreText: 'Read More >>',
      readMoreStyle: {},
      uploadedDataInfo: {},
      scaleImage: 100,
      currentTicketId: currentTicketId,
      penColor: 'black',
      lineWidth: 3,
      onMode:false,
      isNewAnimationCreated: false,
      displayIndex: 0,
      membersInComment: [],
      shouldShowAd: false,
      is_show_draw_component: false,
      animationSelectCurrent: null,
      is_create_video_embed: is_create_video_embed,
      isShowDrawComponent: false,
      is_capture_video_youtube: false,
      is_cancel_capture_video_youtube: false,
      isShowFullscreenAnimation: false,
      isShowModalDownloadImage: false,
      selectTypeDownload: '',
      isShowModalDownloadVideo: false,
      selectTypeDownloadVideo: '',
      is_create_pass_video: is_create_pass_video,
      isShowInputPassVideo: false,
      passwordShareVideo: '',
      isDisabledButtonSavePass: true,
      isShowInputPassUrl: false,
      passwordShareUrl: pass_word_share_url,
      isDisabledButtonSavePassUrl: true,
      isShowInputDemo: false,
      isShowInputDemoUrl: false,
      currentPasswordShareVideo: '',
      currentPasswordShareUrl: pass_word_share_url,
      page_last_animation: page_last_animation,
      isOpenModalUploadFirstAkaire: false,
      selectedMarker: null,
      notiComparePage: noti_compare_page,
      notiCompareVersion: noti_compare_version,
      avatarUrl: avatar_url,
      modalChangeCommentListIsOpenIsOpen: false,
      modalChangeCommentListText: '',
      modalChangeCommentListCurrentAnimation: null,
      modalChangeCommentListKind: null,
      disabledNoti: false,
      listAnimationDownloads: [],
      activeTabMarkerMobileImage: '',
      isShowMobileMarkerImage: false,
      isOpenNouislider: false,
      isDisabledButtonSendMailNotLogin: true,
      modalConfirmSendMailLogin: false,
      isInputInfoMationOfNotLogin: false,
      emailTempOfNotLogin: '',
      userNameTempOfNotLogin: '',
      showModalInputInfoOfNotLogin: false,
      isShowModalCreateCommentNotLogin: false,
      isNotShowCreateCommentNotLogin: false,
      isChangePage: false,
      firstAnimationId: '',
      lastAnimationId: '',
      showPeriodRange: [],
    };
    this.currentURL = baseURL;
    if(baseURL && baseURL.match(REGEXP_URL)) {
      this.baseURL = '/akaire_feature/projects/' + baseURL.match(REGEXP_URL)[1] + '/tasks/' + baseURL.match(REGEXP_URL)[2];
    } else if(baseURL && baseURL.match(TICKET_REGEXP_URL)){
      this.baseURL = '/akaire_feature/projects/' + baseURL.match(TICKET_REGEXP_URL)[1] + '/tasks/' + baseURL.match(TICKET_REGEXP_URL)[2] + '/tickets/' + baseURL.match(TICKET_REGEXP_URL)[3];
    } else {
      this.baseURL = baseURL || `/akaire_feature/projects/${id}`;
    }
    this.apiClient = apiClient({ baseURL: this.baseURL });
    this.percentConvert = null;
    this.shouldShowReadMore = false;
    this.showMode = show_mode;
    this.akaireFileId = akaire_file_id;
    this.akaireFileName = akaire_file_name;
  }
  componentDidMount() {
    this.fetchUser();
    this.fetchProject();
    this.fetchMembersInReplyTo();
    this.changStatePageAndAnimation();
    this.showPopupCreateNoAnimation();
    const emailTemp = localStorage.getItem('email_temp_of_not_login')
    const userTemp = localStorage.getItem('user_temp_of_not_login')
    const isNotShowCreateCommentNotLogin = localStorage.getItem('isNotShowCreateCommentNotLogin')
    if (isNotShowCreateCommentNotLogin) {
      this.setState({
        isNotShowCreateCommentNotLogin: isNotShowCreateCommentNotLogin,
        isDisabledButtonSendMailNotLogin: false
      })
    }
    if (emailTemp && userTemp) {
      this.setState({
        emailTempOfNotLogin: emailTemp, userNameTempOfNotLogin: userTemp,
        isInputInfoMationOfNotLogin: true,
      })
    }
  }
  handleDisplayIndex(index){
    this.setState({displayIndex: index})
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isRefresh !== this.props.isRefresh) {
      this.fetchProject();
      this.fetchMembersInReplyTo();
      this.changStatePageAndAnimation();
    }
  }

  async changStatePageAndAnimation() {
    this.setState({ isLoading: true });
    const regex = /projects\/(\d+)\/(\d+)\/akaire\/(\S+)\/(\S+)\/(\S+)/
    const { windowWidth } = this.props;
    if (this.currentURL && this.currentURL.match(regex)) {
      this.setState({
        currentPageId : parseInt(this.currentURL.match(regex)[4]) ? parseInt(this.currentURL.match(regex)[4]) : null,
        currentAnimationId : parseInt(this.currentURL.match(regex)[5]) ? parseInt(this.currentURL.match(regex)[5]) : null,
        animationSelectCurrent: parseInt(this.currentURL.match(regex)[5]) ? parseInt(this.currentURL.match(regex)[5]) : null,
      })

      try {

        // Call api get pages
        const publicParams = queryPageId && this.baseURL.split('/').some(_ => _ == 'p') ? `?page_id=${queryPageId}` : ''
        const data = await this.apiClient.get(`/pages${publicParams}`);
        this.setState({ pages: data.data })

        // Call api get versions
        const { can_use_page_management: canUsePageManagement } = this.props;
        const {currentPageId} = this.state;
        const uri = canUsePageManagement ? `pages/${currentPageId}` : ''
        const publicParam = queryAnimationId && this.baseURL.split('/').some(_ => _ == 'p') ? `?animation_id=${queryAnimationId}` : ''
        const animations = await this.apiClient.get(`${uri}/animations${publicParam}`);
        this.setState({ animations: animations.data });

        if(parseInt(this.currentURL.match(regex)[3])) {
          this.onClickMarker(parseInt(this.currentURL.match(regex)[3]));
        }

      } catch (e) {
        toastr.error('ページが見つかりません。');
      }
    } else {
      await this.fetchPagesAndAnimations();
    }
    this.setState({ isLoading: false });
  }
  onChangeScaleImage=(scaleImage) =>{
    let outerContent = $('#wrap-center');
    let innerContent = $('#animation-preview');
    if(!innerContent.length)
    innerContent=$('#animation-player')
      outerContent.animate({
        scrollTop: (innerContent.height() - outerContent.offset().top ) / 2,
        scrollLeft: (innerContent.width() - outerContent.width()) / 2,
    }, 0);
    this.setState({
      scaleImage
    })
  }
  onChangeMode=(e) =>{
    this.setState({
      onMode:!this.state.onMode
    })
  }

  onClickFinalizeAnimation = (e) => {
    const { currentAnimationId } = this.state;
    const {reFreshNow} = this.props;
    this.startLoading('Now processing...');

    return this.apiClient.post(`animations/${currentAnimationId}/finalization/finalize_animation`)
      .then((response) => {
        toastr.success('最終稿にしました');
        this.fetchAnimations();
        this.fetchProject();
        reloadActivities();
        Popup.alert(`${response.data.version_name}バージョンを最終稿に変更しました。`)
        if(this.showMode === 'compare') {
          reFreshNow()
        }
      })
      .catch(() => {
        toastr.error('最終稿の設定が失敗しました');
      })
      .finally(this.endLoading);
  }
  onClickCancelFinalAnimation = (e) => {
    const { currentAnimationId } = this.state;
    const {reFreshNow} = this.props;
    this.startLoading('Now processing...');

    return this.apiClient.post(`animations/${currentAnimationId}/finalization/cancel_finalize_animation`)
      .then((response) => {
        toastr.success('最終稿を解除しました');
        this.fetchAnimations(currentAnimationId);
        this.fetchProject();
        reloadActivities();
        Popup.alert(`${response.data.version_name}バージョンを最終稿を解除しました。`)
        if(this.showMode === 'compare') {
          reFreshNow()
        }
      })
      .catch(() => {
        toastr.error('最終稿の解除が失敗しました');
      })
      .finally(this.endLoading);
  }

  is_pages_available = () => {
    const { pages } =this.state;
    return (pages.length > 0)
  }

  getFirstDisplayAnimation = () => {
    const { animations } = this.state;
    const { show_mode, animation_id } = this.props;
    if(show_mode == 'compare') {
      return animations.find(_ => _.id == animation_id)
    } else {
      return ((queryAnimationId && animations.find(_ => _.id === parseInt(queryAnimationId, 10))) || animations.find(_ => _.final)
      || animations.sort((a, b) => b.id - a.id)[0])
    }
  }

  componentDidUpdate(_, prev) {
    const { currentAnimationId, is_cancel_capture_video_youtube, is_capture_video_youtube } = this.state;
    if (currentAnimationId !== prev.currentAnimationId) {
      this.onCurrentAnimaitionIdChanged();
    }
    if (is_cancel_capture_video_youtube && is_capture_video_youtube !== prev.is_capture_video_youtube &&
      is_capture_video_youtube === false && prev.is_capture_video_youtube === true) {
      this.setState({is_cancel_capture_video_youtube: false})
      this.disabledPentool()
    }
  }
  onCurrentAnimaitionIdChanged() {
    this.fetchMarkers();
    this.fetchComments();
  }
  async fetchPages() {
    const {last_page_id: lastPageId} = this.props;
    const publicParams = queryPageId && this.baseURL.split('/').some(_ => _ == 'p') ? `?page_id=${queryPageId}` : ''
    const {isDeletePage, currentPageId} = this.state;
    try {
      const data = await this.apiClient.get(`/pages${publicParams}`);
      const page_id = (data.data.slice(-1)[0] && data.data.slice(-1)[0].id) || lastPageId
      const currentSelectedPageId = isDeletePage ? page_id : (currentPageId || queryPageId || page_id);
      this.setState({
          pages: data.data,
          currentPageId: currentSelectedPageId
        }
      )
      $(data.data).each((i, page) => {
        $(`.option-select-page-${page.id}`).text(page.title)
      })
      AkaireFile.selectPage($(`.option-select-page-${currentSelectedPageId}`))
      // this.changeUrlCreateAnimation(currentSelectedPageId)
      if (isDeletePage) {
        await this.fetchAnimations()
      }
      return data;
    } catch (e) {
      console.error(e.message)
    }

  }

  changeUrlCreateAnimation(pageId) {
    const button = $('.create-new-animation-from-akaire-detail');
    button.attr('href', Routes.akaire_feature_akaires_path({from_page_id: pageId}))
  }

  isStillAvailableStorage = fileSize =>{
    const {
      project: {
        total_file_size: totalFileSizeInProject,
        owner_total_file_size_on_this_cycle: ownerTotalSizeOnThisCycle,
        owner_total_file_size_limit: totalFileSizeLimit,
        owner_available_storage_size: availableStorageSize
      }
    } = this.state;
    const { role } = this.props;

    return (
      // role == 'public_visitor' &&
      parseInt(totalFileSizeInProject) + fileSize < 0.5 * Math.pow(10, 9) ||
      fileSize + parseFloat(ownerTotalSizeOnThisCycle) <= parseFloat(totalFileSizeLimit) &&
      fileSize <= parseFloat(availableStorageSize)
    )
  }
  async fetchAnimations(currentAnimationId = null) {
    const { can_use_page_management: canUsePageManagement, animation_id } = this.props;
    const {currentPageId} = this.state;
    const uri = canUsePageManagement ? `pages/${currentPageId}` : ''
    const publicParam = queryAnimationId && this.baseURL.split('/').some(_ => _ == 'p') ? `?animation_id=${queryAnimationId}` : ''
    try {
      const data = await this.apiClient.get(`${uri}/animations${publicParam}`);
      const showDeletionDateSP = document.getElementsByClassName('show_deletion_date_sp')

      if (data.data && data.data.deletion_date) {
        Popup.alert(`このページは有効期限切れの為<br>${data.data.deletion_date}に削除されました。`, '', '', {kind: 'akaire'})
        this.toggleElementList('show_deletion_date', true)
        if (showDeletionDateSP.length > 0) { showDeletionDateSP[0].style.marginBottom = "1.2rem"; }
      } else {
        this.toggleElementList('show_deletion_date', false)
        if (showDeletionDateSP.length > 0) { showDeletionDateSP[0].style.marginBottom = "-1rem"; }
        if (this.showMode === 'compare' && animation_id) {
          this.setState({ animations: data.data.sort((a, b) => b.id - a.id) }, this.setCurrentAnimationId(data.data, animation_id));
        } else {
          this.setState({ animations: data.data.sort((a, b) => b.id - a.id) }, this.setCurrentAnimationId(data.data, currentAnimationId));
        }
        const pageExpirationDate = data.data && data.data[0] && data.data[0].page_expiration_date;
        this.togglePageExpirationDate(pageExpirationDate)
      }
      this.checkAnimationLastPage()
      return data;
    } catch (e) {
      console.error(e.message)
    }
  }

  togglePageExpirationDate(pageExpirationDate) {
    const expirationNoticeSp = document.getElementsByClassName('expiration_notice_sp');
    if (!!pageExpirationDate) {
      if (!!document.getElementsByClassName('page-akaire-expiration-date')) {
        const classExpirationDates = document.getElementsByClassName('page-akaire-expiration-date')
        for (let i = 0; i < classExpirationDates.length; i++) {
          classExpirationDates[i].innerHTML = pageExpirationDate
        }
        if (expirationNoticeSp.length > 0) { expirationNoticeSp[0].style.marginBottom = "1.7rem"; }
        this.toggleElementList('parent-expiration_notice', true)
        this.toggleElementList('nav-bar-parent-expiration_notice', true)
        this.toggleDisplayElementList('nav-bar-parent-expiration_notice', true)
      }
    } else {
      if (expirationNoticeSp.length > 0) { expirationNoticeSp[0].style.marginBottom = "-1rem"; }
      this.toggleElementList('parent-expiration_notice', false)
      this.toggleElementList('nav-bar-parent-expiration_notice', false)
      this.toggleDisplayElementList('nav-bar-parent-expiration_notice', false)
    }
  }

  toggleElementList(className, show = true) {
    const elementList = document.getElementsByClassName(className);
    for (let i = 0; i < elementList.length; i ++) {
      elementList[i].style.visibility = show ? "visible" : "hidden";
    }
  }

  toggleDisplayElementList(className, show = true) {
    const elementList = document.getElementsByClassName(className);
    for (let i = 0; i < elementList.length; i ++) {
      elementList[i].style.display = show ? "block" : "none";
    }
  }

  setCurrentAnimationId = (animations, currentAnimationId) => {
    const {passwordShareUrl, project, isChangePage} = this.state
    const { animation_id } = this.props;
    let animation = animations.filter(obj => {
      return obj.id == currentAnimationId || obj.final
    })[0];
    if (this.state.isNewAnimationCreated) {
      animation = animations.sort((a, b) => a.id - b.id).slice(-1)[0];
      this.setState({ isNewAnimationCreated: false })
    } else if(this.showMode === 'compare' && animation_id && !isChangePage) {
      animation = animations.filter(obj => {
        return obj.id == animation_id
      })[0];
    } else {
      animation = animation || animations.sort((a, b) => a.id - b.id).slice(-1)[0];
    }
    this.setState({ currentAnimationId: animation && animation.id,  });
    this.setState({
      isShowInputPassVideo: animation && animation.password_share_video,
      passwordShareVideo: animation && animation.password_share_video,
      isShowInputPassUrl: !!passwordShareUrl,
      isShowInputDemo: animation && animation.password_share_video,
      isShowInputDemoUrl: !!passwordShareUrl,
      currentPasswordShareVideo: animation && animation.password_share_video,
    })
  }
  fetchAnimationsFistTime = async () => {
    await this.fetchAnimations()
      .then(() => {
        const firstDisplayAnimation = this.getFirstDisplayAnimation();
        if (!firstDisplayAnimation) return;
        this.setState({
          currentAnimationId: firstDisplayAnimation && firstDisplayAnimation.id,
          animationSelectCurrent: firstDisplayAnimation && firstDisplayAnimation.id,
        });
      })
  }
  fetchMarkers = async () => {
    this.setState({ markers: [] })
    const { id, project_type } = this.props;
    const { currentAnimationId, currentPageId } = this.state;
    if (typeof currentAnimationId == "undefined"){
      return;
    }

    return await this.apiClient.get(`animations/${currentAnimationId}/animation_markers.json?project_type=${project_type}`)
      .then(_ => {
        this.setState({ markers: _.data });
      })
      .catch(console.error);
  }
  getTotalSizeAnimation = () => {
    const {comments, markers} = this.state;
    const currentAnimationSize = (this.currentAnimation() && this.currentAnimation().file_size);
    return comments.reduce((sum, comment) => sum + comment.file_size, 0) + markers.reduce((sum, marker) => sum + marker.file_size, 0) + currentAnimationSize;
  }
  fetchComments = () => {
    const { id } = this.props;
    const { currentAnimationId, currentPageId } = this.state;
    if (typeof currentAnimationId == "undefined"){
      return this.setState({ markers: [] })
    }
    return this.apiClient.get(`/animations/${currentAnimationId}/animation_comments`)
      .then(_ => this.setState({ comments: _.data }))
      .catch(console.error);
  }

  fetchMembersInReplyTo = () => {
    const { id } = this.props;
    return this.apiClient.get(`/comment_members`)
      .then(_ => {
        this.setState({ membersInComment: _.data })
      })
      .catch(console.error);
  }

  updatePageId = () => {
    const {can_use_page_management: canUsePageManagement} = this.props;
    const {currentPageId, currentAnimationId} = this.state;

    if (!canUsePageManagement)
      return this.fetchAnimations();

    return this.apiClient.patch(`/pages/${currentPageId}/animations/${currentAnimationId}`,{})
              .then((response) => {
                this.fetchAnimations()
              })
  }
  updatePage = (formData) => {
    const {reFreshNow} = this.props;
    return this.apiClient.put(`/pages/${formData['pageId']}`,formData)
      .then(() => {
        this.fetchPages();
        toastr.success('変更成功しました')
        reFreshNow()
      })
      .catch((e) => toastr.error('ページ名を変更できません'))
  }
  disabledNotiComparePage = () => {
    apiClient().post(`/users/disable_noti_compare_page`, { user: { notiComparePage: false } })
    return this.setState({ notiComparePage: false });
  }
  disabledNotiCompareVersion = () => {
    var statusNoti = !this.state.notiCompareVersion;
    apiClient().post(`/users/disable_noti_compare_version`, { user: { notiCompareVersion: statusNoti } })
    return this.setState({ notiCompareVersion: statusNoti });
  }
  deletePage = async (pageId, pageLabel) => {
    const { pages, is_create_video_embed} = this.state;
    const currentAnimation = this.currentAnimation();
    const {reFreshNow} = this.props;
    this.setState({isDeletePage: true})
    try {
      const data = await this.apiClient.delete(`/pages/${pageId}`);
      await this.fetchPages();
      $(`.option-select-page-${pageId}`).remove()
      this.setState({ isDeletePage: false });
      this.changeURL(this.state.currentPageId);
      if (currentAnimation.is_video_embed && !is_create_video_embed) {
        this.setState({ is_create_video_embed: true })
      }
      Popup.alert(`<span class="font-weight-bold">ページを削除しました。</span>`);
      return data;
    } catch (error) {
      Popup.alert('<span class="font-weight-bold">ページを削除できません。</span>');
    }
  }
  deleteVersion = async (versionId) => {
    try {
      const data = await this.apiClient.delete(`/animations/${versionId}`);
      await this.fetchAnimations()
      const { currentAnimationId } = this.state;
      this.setState({animationSelectCurrent: currentAnimationId })
      Popup.alert('<span class="font-weight-bold">バージョンを削除しました。</span>');
      return data;
    } catch (error) {
      Popup.alert('<span class="font-weight-bold">バージョンを削除できません。</span>');
    }
  }
  currentAnimation = () => this.state.animations.find(_ => _.id === this.state.currentAnimationId)
  currentPage = () => this.state.pages.find(_ => _.id === parseInt(this.state.currentPageId))
  currentDownloadExtensionUrl = () => {
    const currentAnimation = this.currentAnimation()
    return this.state.downloadExtensionUrl || (currentAnimation && currentAnimation.download_url)
  }
  textFor = (plan) => {
    return PLANS.indexOf(plan) >= 0 && PLANS_TEXT[PLANS.indexOf(plan)]
  }
  showAlertLimitVideo = () => {
    const {current_limit_video: currentLimitVideo, current_subscription_type: currentSubscriptionType} = this.props;
    const nextLevelPlan = this.textFor(PLANS[PLANS.indexOf(currentSubscriptionType)+1])
    const text = nextLevelPlan ? `${nextLevelPlan}プラン以上にアップグレードするか、データの圧縮をしてからアップロードをお願い致します。` : ''
    toastr.error(`${this.textFor(currentSubscriptionType)}プランの場合、1回でのアップロード制限は${currentLimitVideo}GB以内です。${text}`);
  }
  onSubmitMarkerForm = ({ body, x, y, width, height, shape, time, files, editorSetting={}, periodRange=null }) => {
    const { id, role } = this.props;
    this.setState({commentPoint: body});
    const { currentAnimationId, currentPageId, isShowFullscreenAnimation, emailTempOfNotLogin, userNameTempOfNotLogin, isOpenNouislider } = this.state;
    const params = {
      animation_marker: {
        body, x, y, width, height, shape, seek_time: periodRange ? periodRange[0] : time,
        border_color: gon.is_login ? editorSetting.borderColor : '#000000',
        animation_marker_files_attributes: files.map(file => {
          return {
            file_object: file
          };
        }),
        file_size: files.reduce((sum, file) => sum + file.size, 0),
        period_end: periodRange ? periodRange[1] : null,
        email_temp: emailTempOfNotLogin,
        user_name_temp: userNameTempOfNotLogin
      }
    }
    const data = objectToFormdata(params);

    return this.apiClient.post(`animations/${currentAnimationId}/animation_markers`, data)
      .then(() => {
        this.fetchMarkers();
        this.fetchProject();
        if (isOpenNouislider) {
          this.updateIsOpenNouislider();
        }
        if (isShowFullscreenAnimation) {
          this.showPopupAlertForFullscreen(body, editorSetting.borderColor)
        }
        if (!gon.is_login) {
          const { isNotShowCreateCommentNotLogin } = this.state;
          this.setState({ isDisabledButtonSendMailNotLogin: false })
          $('.btn-click-auto-comment').removeClass('disabled')
          if (isNotShowCreateCommentNotLogin) return
          this.setState({isShowModalCreateCommentNotLogin: true})
        }
        if (role === 'reader') {
          this.setState({ isDisabledButtonSendMailNotLogin: false })
        }
      })
      .catch(() => {
        if (isShowFullscreenAnimation) {
          this.showPopupAlertForFullscreen('赤入れの投稿に失敗しました')
          return
        }
        Popup.alert('赤入れの投稿に失敗しました', '', '', {kind: 'akaire'});
      });
  }
  showPopupAlertForFullscreen = (text, border="#E60C11") => {
    const divShow = $('div.show-popup-text-new-comment')
    divShow.find('span').html(text)
    divShow.removeClass('d-none').addClass('d-flex')
    setTimeout(() => {
      divShow.find('span').html('')
      divShow.removeClass('d-flex').addClass('d-none')
    }, 2000);

  }

  hex2RGBA = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.15)`
  }
  convertVideo = ({ signedUrl }, filename) => {
    const {currentPageId} = this.state;
    var id_interval = setInterval(() => {
      this.apiClient.put(`animations/convert_mp4`, { pathname: decodeURI(urlParse(signedUrl).pathname), filename: filename }) // decode the file name to the correct original name
        .then((res) => {
          if (res.data.type == 'success') {
            setTimeout(() => {
              toastr.success('動画をアップロードしました。');
              this.getAnimationAdded()
                .then(() =>{
                  this.fetchProject();
                });
              reloadActivities();
              this.setState({ convertFile: false });
              this.finishConvert(true)
            }, 1000);
            setTimeout(() => {
              this.close('AnimationUploaderModal');
              this.endLoading();
            }, 2000);
            clearInterval(id_interval);
          }
        })
        .catch(() => {
          this.endLoading();
          this.close('AnimationUploaderModal');
        })
    }, 2000);
  }
  convertPdf = (file) => {
    const {currentPageId} = this.state;
    let formData = new FormData();
    formData.append('file', file);
    this.startLoading(`PDF file is uploading...`)
    this.apiClient.put(`animations/convert_pdf`, formData)
      .then((res) => {
        if (res.data.success) {
          this.startLoading(`PDF file is converting...`)
          var id_interval = setInterval(() => {
            this.apiClient.get(`animations/check_status_convert_pdf?extension_id=${res.data.extension_id}`)
              .then((res) => {
                if (res.data.type == 'success') {
                  setTimeout(() => {
                    toastr.success('動画をアップロードしました。');
                    this.getAnimationAdded()
                      .then(() =>{
                        this.fetchProject();
                      });
                    reloadActivities();
                    this.finishConvert(true)
                  }, 1000);
                  setTimeout(() => {
                    this.close('AnimationUploaderModal');
                    this.endLoading();
                  }, 2000);
                  clearInterval(id_interval);
                }
              })
              .catch(() => {
                Popup.alert(`PDFファイルを画像に変換処理が失敗しました。再度やり直してください。`, '', '', {kind: 'akaire'})
                clearInterval(id_interval);
                this.endLoading();
                this.close('AnimationUploaderModal');
              })
          }, 5000);
        }
      })
      .catch(() => {
        Popup.alert(`PDFファイルを画像に変換処理が失敗しました。再度やり直してください。`, '', '', {kind: 'akaire'})
        toastr.error('画像のアップロードに失敗しました。');
        this.endLoading();
        this.close('AnimationUploaderModal');
      })
  }
  file_extension = (filename) => {
    const extensions = filename.split('.').slice(-1)
    if (extensions.length) { return extensions[0].toUpperCase() }
  }
  checkAnimationExist = (file_object) => {
    const {currentPageId} = this.state;
    this.apiClient.put(`animations/file_mp4_exists`, objectToFormdata({ file_object: file_object }))
      .then(res => {
        const { status } = res
        if(status == 200)
          return
        if(status == 201) {
          clearInterval(this.timerCheckAnimation);
          this.close('AnimationUploaderModal');
          this.endLoading();
          this.setState({
            responseConvertVideo: null,
            timerCheckAnimation: null
          })
          const { data } = res
          if(data.type == 'error') { return toastr.error(data.message) }
          if (data.type == 'success') {
            toastr.success(data.message)
            this.getAnimationAdded()
              .then(() =>{
                this.fetchProject();
              });
            // reloadActivities();
          }
        } else {
          toastr.error(data.message)
        }
      })
  }
  onSubmitProjectForm = (name, description) => {
    const { id } = this.props;
    const data = objectToFormdata({
      project: {
        name,
        description
      }
    });
    return apiClient().patch(`${this.baseURL}.json`, data)
      .then(() => {
        toastr.success('プロジェクトを更新しました');
        this.fetchProject()
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('プロジェクトを更新できませんでした');
      })
      .finally(() => {
        this.close('ProjectFormModal');
      });
  }
  onChangeAssignments = () => {
    this.fetchMembersInReplyTo();
    this.fetchUser();
    // reloadActivities();
  }

  onClickInputFile = () => {
    this.fetchUploadedInfo();
  }

  fetchUploadedInfo = () => {
    return this.apiClient.get(`/uploaded_data_info`)
      .then(_ => this.setState({ uploadedDataInfo: _.data }))
      .catch(console.error);
  }

  fetchUser = () => {
    return this.apiClient.get(`/users`)
      .then(_ => this.setState({ user: _.data }))
      .catch(console.error);
  }
  fetchProject = () => {
    return apiClient().get(`${this.currentURL}.json`)
      .then(_ => this.setState({ project: _.data }))
      .catch(console.error);
  }
  getAnimationAdded = () => {
    return this.apiClient.get(`/animations`)
      .then(async (res) => {
        const lastAnimationInProject = res.data.slice(-1)[0];
        if (lastAnimationInProject) {
          if (lastAnimationInProject.page_id) {
            this.setState({
              currentAnimationId: lastAnimationInProject.id, isNewAnimationCreated: true,
              animationSelectCurrent: lastAnimationInProject.id
            });
            this.fetchAnimations()
            return
          }
          await this.setState({
            currentAnimationId: lastAnimationInProject.id, isNewAnimationCreated: true,
            animationSelectCurrent: lastAnimationInProject.id
          });
          await this.updatePageId( lastAnimationInProject.id);
        }
      })
      .catch(console.error)
  }
  fetchPagesAndAnimations = async() => {
    const { can_use_page_management: canUsePageManagement} = this.props;
    if (canUsePageManagement) {
      await this.fetchPages()
      if (!this.is_pages_available()) {return}
      await this.fetchAnimationsFistTime()
      return
    }
    this.fetchAnimationsFistTime()
  }
  onMarkerDeletionCalled(markerId) {
    if (this.animationPlayer) {
      this.animationPlayer.reset(true)
    } else if(this.animationImage) {
      this.animationImage.reset(true)
    }
    return this.deleteMarker(markerId)
  }
  onMarkerUpdationCalled(values, markerId) {
    return this.updateMarker(values, markerId)
  }
  deleteAnimation = () => {
    const { id, project_type } = this.props;
    const { currentAnimationId, currentPageId, is_create_video_embed } = this.state;
    const currentAnimation = this.currentAnimation();
    var message = (project_type == TASK_TYPE.image) ? '画像' : '動画';

    Popup.confirm(
      `「${$('input.akaire_file_name').val()}」の赤入れデータを削除します。<br>復元できません。削除してよろしいですか？`,
      {
        success: () => {
          this.startLoading('Now deleting...');
        let is_video_embed = currentAnimation.is_video_embed
        return this.apiClient.delete(`animations/${currentAnimationId}`)
          .then(async () => {
            Popup.alert(`<span class="font-weight-bold">「${$('input.akaire_file_name').val()}」のデータが削除されました。</span>`);
            await this.fetchAnimations().then( async() => {
              const { animations } = this.state;
              const [animation] = animations.slice(-1);
              if (is_video_embed && !is_create_video_embed) {
                this.setState({ is_create_video_embed: true  })
              }
              await this.setState({
                currentAnimationId: animation && animation.id,
                animationSelectCurrent: animation && animation.id,
              });
            });
            this.fetchMarkers();
            this.fetchProject();
            this.changeURL(this.state.currentPageId);
            // reloadActivities();
          })
          .catch(() => {
            toastr.error(message + 'の削除に失敗しました');
          })
          .finally(this.endLoading);
        },
        confirmText: 'OK',
        kind: 'akaire',
      }
    )
  }
  finalizeAnimation = () => {
    const { id } = this.props;
    const { currentAnimationId, animations, currentPageId } = this.state;
    const currentAnimation = this.currentAnimation();
    this.startLoading('Now processing...');
    return this.apiClient.post(`animations/${currentAnimationId}/finalization`)
      .then(() => {
        toastr.success('完成しました');
        this.fetchAnimations();
        this.fetchProject();
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('完成できませんでした');
      })
      .finally(this.endLoading);
  }
  deleteMarker(markerId) {
    const { id } = this.props;
    const { currentAnimationId, currentPageId } = this.state;
    return this.apiClient.delete(`/animations/${currentAnimationId}/animation_markers/${markerId}`)
      .then(() => {
        toastr.success('赤入れを削除しました');
        this.fetchMarkers()
        this.fetchProject();
      })
      .catch(() => {
        toastr.error('赤入れの削除に失敗しました');
      });
  }

  deleteReplyMarker(replyMarkerId, parentReplyId) {
    return apiClient().delete(`/akaire_feature/animation_markers/${parentReplyId}/reply_comments/${replyMarkerId}`)
      .then(() => {
        toastr.success('赤入れを削除しました');
        this.fetchMarkers()
        this.fetchProject();
      })
      .catch(() => {
        toastr.error('赤入れの削除に失敗しました');
      });
  }

  updateMarker(values, markerId) {
    const {animationSelectCurrent} = this.state;
    const { id, current_user_id: currentUserId, owner, role: currentUserRole } = this.props;
    const { currentAnimationId, currentPageId } = this.state;
    const { status } = values;
    const action = { initial: '完了解除', completed: '完了' }[status];
    if (!currentUserId && owner || currentUserId && !canCreateCommentPublicUser(currentUserRole) && owner ){
      return toastr.error(`権限がありません`);
    }
    if(animationSelectCurrent !== currentAnimationId) return
    return this.apiClient.patch(`animations/${currentAnimationId}/animation_markers/${markerId}`, values)
      .then(() => {
        if (typeof action !== 'undefined'){
          toastr.success(`赤入れを${action}しました`);
        }
        this.fetchMarkers();
      })
      .catch(() => {
        toastr.error(`赤入れの${action}に失敗しました`);
      });
  }
  onClickMarker(id) {
    const { markers } = this.state;
    const marker = markers.find(_ => _.id === id);
    if (![null, undefined].includes(this.animationPlayer))
      this.animationPlayer.seekTo(marker.seek_time);
    this.changeURL(this.state.currentPageId, false, id);
    this.setState({ showPeriodRange: [] });
  }
  updateMakerShow(x, y, id){
    const newMarker = { ...this.state.markers }
    const { markers } = this.state
    let temp = markers.findIndex(i => i.id === id);
    let marker = newMarker[temp];
    marker.x = x;
    marker.y = y;
    newMarker[temp] = marker;
    this.setState({ marker:newMarker })
  }
  onSubmitCommentForm(body, files) {
    const { id } = this.props;
    const { currentAnimationId, commentPoint, currentPageId } = this.state;
    const data = objectToFormdata({
      comment: {
        body: body,
        animation_comment_files_attributes: files.map(file => {
          return {
            file_object: file
          };
        }),
        file_size: files.reduce((sum, file) => sum + file.size, 0)
      }
    });
    return this.apiClient.post(`animations/${currentAnimationId}/animation_comments`, data)
      .then(() => {
        toastr.success('コメントを投稿しました');
        this.fetchComments();
        this.fetchProject();
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('コメントの投稿に失敗しました');
      });
  }
  onCommentDeletionCalled(commentId) {
    return this.deleteComment(commentId)
  }
  deleteComment(commentId) {
    const { id } = this.props;
    const { currentAnimationId, currentPageId } = this.state;
    return this.apiClient.delete(`animations/${currentAnimationId}/animation_comments/${commentId}`)
      .then(() => {
        toastr.success('コメントを削除しました');
        this.fetchComments()
        this.fetchProject();
      })
      .catch(() => {
        toastr.error('コメントの削除に失敗しました');
      });
  }
  onClickAutoCommentButton = async () => {
    await this.fetchMembersInReplyTo()
    const { currentAnimationId , commentPoint, markers, membersInComment} = this.state;
    if (membersInComment.length < 2) {
      Popup.alert('このファイルには、他のメンバーが存在しない為、送信できません。', '', '', {kind: 'akaire'})
    } else if (markers.length === 0) {
      Popup.alert('1個以上のコメントがありません。', '', '', {kind: 'akaire'})
    } else {
      return this.apiClient.post(`animations/${currentAnimationId}/animation_comments`, {
        comment: {
          body: commentPoint
        },
      })
        .then(() => {
          // toastr.success('赤入れを通知しました');
          this.fetchComments()
          this.fetchProject();
          Popup.alert('メンバーに赤入れ通知を送信しました。', '', '', {kind: 'akaire'})
          // reloadActivities();
        })
        .catch((e) => {
          if (!!e.response && !!e.response.data && !!e.response.data.error) {
            Popup.confirm(e.response.data.error, {
              success: () => { location.reload() },
              cancel: () => {},
              confirmText: I18n.t('work.reload_page'),
              kind: 'akaire',
            })
          }
          // toastr.error('赤入れの通知に失敗しました');
        });
    }
  }

  toggleModalInputInfoOfNotLogin = () => {
    this.setState({showModalInputInfoOfNotLogin: !this.state.showModalInputInfoOfNotLogin})
    const {showModalInputInfoOfNotLogin} = this.state;
    if (!showModalInputInfoOfNotLogin) {
      this.setState({
        emailTempOfNotLogin: '',
        userNameTempOfNotLogin: '',
      })
      localStorage.setItem('email_temp_of_not_login', '')
      localStorage.setItem('user_temp_of_not_login', '')
    }
  }

  showPopupConfirmSendMailNotLogin = () => {
    this.togleModalConfirmSendMailNotLogin()
  }

  sendMailNotLogin = async () => {
    this.togleModalConfirmSendMailNotLogin()
    const {currentAnimationId, userNameTempOfNotLogin} = this.state;
    return this.apiClient.post(`animations/${currentAnimationId}/animation_markers/send_mai_not_login`, {
      sender_user: userNameTempOfNotLogin
    }).then(() => {
      Popup.alert('メンバーにコメントを送信しました。', '', '', {kind: 'akaire'})
    }).catch(() => {
    });
  }
  onClickReadMore = () => {
    const { readMoreText } = this.state;
    const isFullText = readMoreText == 'Read Less <<';
    this.setState({
      readMoreText: isFullText ? 'Read More >>' : 'Read Less <<',
      readMoreStyle: isFullText ? {maxHeight: '200px'} : {}
    })
  }
  readMore = () => {
    const { project: { description } } = this.state;
    const body = document.getElementById('body-description-project')
    const shouldShow = description && body && body.offsetHeight > 200
    if (shouldShow) {
      this.setState({readMoreStyle: {maxHeight: '200px'}})
    }
    if  (!this.shouldShowReadMore && shouldShow) {
      this.shouldShowReadMore = true;
    }
    return this.shouldShowReadMore
  }
  toggleTab = name => this.setState({ activeTab: name })
  toggleTabMobileMarkerImage = name => this.setState({ activeTabMarkerMobileImage: name });

  showHideTabMobileMarker = () => {
    const { isShowMobileMarkerImage } = this.state;
    const nameTab = isShowMobileMarkerImage ? '' : 'markers-image-mobile'
    this.toggleTabMobileMarkerImage(nameTab)
    this.setState({ isShowMobileMarkerImage: !isShowMobileMarkerImage })
  }
  toggle = bool => name =>
    this.setState({ [`shouldShow${name}`]: bool })
  open = this.toggle(true)
  close = this.toggle(false)
  onChangeAnimationSelect = async ({ target: selectedOption }) => {
    await this.setState({
      currentAnimationId: parseInt(selectedOption.value, 10),
      animationSelectCurrent: parseInt(selectedOption.value, 10),
      isShowDrawComponent: false
    }, () => {
      this.handleReset();
    });
    this.changeURL(this.state.currentPageId);
  }
  onChangeCommentVersion = async ({ target: selectedOption }) => {
    const { project_type } = this.props;
    const { currentAnimationId, notiCompareVersion } = this.state;
    const animationId = parseInt(selectedOption.value, 10)
    const currentAnimation = this.currentAnimation();
    const text = selectedOption.options[selectedOption.selectedIndex].text
    if (currentAnimationId !== animationId) {
      if (notiCompareVersion) {
        this.setState({
          modalChangeCommentListIsOpenIsOpen: true,
          modalChangeCommentListText: text,
          modalChangeCommentListCurrentAnimation: currentAnimation,
          modalChangeCommentListKind: 'disabledNotiCompareVersion',
          disabledNoti: true
        })
      }
      this.setState({
        isShowDrawComponent: false,
      })
      this.handleReset()
    }
    await this.apiClient.get(`animations/${animationId}/animation_markers.json?project_type=${project_type}`)
    .then(_ => {
      this.setState({ markers: _.data })
    })
    .catch(console.error);
    this.setState({animationSelectCurrent: animationId})
  }
  setCurrentAnimation = () => {
    const { currentAnimationId, animationSelectCurrent }= this.state
    if (currentAnimationId !== animationSelectCurrent) {
      this.setState({ animationSelectCurrent: currentAnimationId })
      this.fetchMarkers()
    }
  }
  onChangeAnimationExtension = ({ target: selectedOption }) => {
    const { currentAnimationId, currentAnimationExtension } = this.state;
    currentAnimationExtension[currentAnimationId] = selectedOption.value
    this.setState({ currentAnimationExtension: currentAnimationExtension });
  }
  selectedAnimationExtension = () => {
    const { currentAnimationId, currentAnimationExtension } = this.state;
    const currentAnimation = this.currentAnimation();
    const fileObjectArr =
      currentAnimation && currentAnimation.file_object && currentAnimation.file_object.url && currentAnimation.file_object.url.split('.')
    const arrGetUrl = fileObjectArr && fileObjectArr[fileObjectArr.length - 1].split('?')
    const currentExtension = arrGetUrl && arrGetUrl[0]
    return currentAnimationExtension[currentAnimationId] || currentExtension || 'mp4'
  }
  onClickDownloadButton = () => {
    const currentAnimation = this.currentAnimation();
    if (currentAnimation?.is_video_embed) {
      Popup.alert('YouTubeデータはダウンロードできません。')
    } else {
      this.setState({isShowModalDownloadVideo: true})
      document.addEventListener("click", this.closeModalDownload, false);
    }
  }

  onClickDownloadImageButton = (url, isHasPermission) => {
    this.setState({isShowModalDownloadImage: true})
    document.addEventListener("click", this.closeModalDownload, false);
    // if (isHasPermission) window.open(url, '_self');
  }

  createPage = () => {
    const {id: projectId} = this.props;
    const {pages} = this.state;
    const duplicatePageTitle = pages.filter((_) => _.title === `ページ${pages.length + 1}`)[0]
    const title = duplicatePageTitle ? `ページ${pages.length + 2}` : `ページ${pages.length + 1}`
    return this.apiClient.post(`/pages`, {
        title: title,
        project_id: projectId
      })
      .then( async(res) =>
        {
          if (res.data.message) {
            return toastr.warning(res.data.message)
          }
          await this.fetchPages()
            .then(() => {
              const { pages } = this.state;
              this.setState({
                currentPageId: pages[pages.length - 1].id
              }, () => {this.fetchAnimations()})
            });
          toastr.success('新しいページを作成しました');
        })
      .catch(() => toastr.error('ページを作成できません。'))
  }

  onChangePage = async (e) => {
    const selectedValue = e.value;
    const currentPage = this.currentPage();
    const text = this.state.pages.find(_ => _.id === parseInt(selectedValue)).title;
    const { notiComparePage } = this.state;

    if (selectedValue != 'new') {
      if (notiComparePage) {
        this.setState({
          modalChangeCommentListText: text,
          modalChangeCommentListCurrentAnimation: currentPage,
          modalChangeCommentListKind: 'disabledNotiComparePage',
          disabledNoti: false
        })
      }

      this.setState(
        { currentPageId: selectedValue, isShowDrawComponent: false }, async () => {
          this.setState({ isChangePage: true });
          await this.fetchAnimationsFistTime();
          this.handleReset();
          this.changeURL(this.state.currentPageId);
          await this.checkAnimationLastPage();
        }
      );
      AkaireFile.selectPage($(`.option-select-page-${selectedValue}`))
      // this.changeUrlCreateAnimation(selectedValue)
      return
    }
    await this.createPage();
    this.setState({ isShowDrawComponent: false, page_last_animation: false }, () => { this.handleReset(); });
    this.changeURL(this.state.currentPageId, true);
  }

  selectAnimation = (value) => {
    const {isFirstAnimation, isLastAnimation, setAnimationIdWhenChange} = this.props;
    if(this.showMode == 'compare') {
      setAnimationIdWhenChange(value.value, isFirstAnimation ? 'firstAnimation' : 'lastAnimation' )
    }
    const {animationSelectCurrent} = this.state;
    const isFetchNewMarker = animationSelectCurrent != value.value
    this.setState({ isShowDrawComponent: false,
                    page_last_animation: false,
                    currentAnimationId: value.value,
                    animationSelectCurrent: value.value }, () => { this.handleReset(); if (isFetchNewMarker) this.fetchMarkers()})
  }

  checkAnimationLastPage = async () => {
    const { pages } = this.state;
    if (pages.length === 0) return
    this.apiClient.post(`/pages/${pages[pages.length - 1].id}/check_animation`).then((data) => {
      this.setState({ page_last_animation: data.data.data })
    })
    .catch((e) => {
      toastr.error(e.message);
      this.endLoading();
    })
    .finally(() => {
      // this.endLoading();
    });
  }

  onChangeTicket = async (e) => {
    let baseURL = this.props.baseURL;
    const selectedValue = e.target.value;
    const token = $(e.target).find(':selected').attr('token')
    this.setState({ currentTicketId: selectedValue })
    if (baseURL.includes('/p/objects/')) {
      let url = `/p/objects/${token}`
        window.history.pushState( "", "", url );
    } else {
      if(selectedValue == "task") {
        let url = '/projects/' + baseURL.match(TICKET_REGEXP_URL)[1] + '/' + baseURL.match(TICKET_REGEXP_URL)[2] + '/akaire/new'
        window.history.pushState( "", "", url );
      } else if(baseURL.match(REGEXP_URL)){
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/' + selectedValue + "/akaire/new"
        window.history.pushState( "", "", url );
      } else {
        let url = '/projects/' + baseURL.match(TICKET_REGEXP_URL)[1] + '/' + baseURL.match(TICKET_REGEXP_URL)[2] + '/' + selectedValue + "/akaire/new"
        window.history.pushState( "", "", url );
      }
    }
    window.location.reload();
  }
  changeURL = (item, newPage = false, idAkaire = null) => {
    let baseURL = this.props.baseURL;
    if(baseURL && baseURL.match(REGEXP_URL)) {
      let pageId = 'new';
      let versionId = 'new';
      let akareId = 'new';
      this.state.pages.map((e,i) => {
        if (item == e.id) {
          pageId = e.id;

          if (this.state.animations.length > 0 && !newPage) {
            this.state.animations.map((item, index) => {
              if (item.id === this.state.currentAnimationId) {
                versionId = item.id;
              }
            });
          }
        }
      });

      if (idAkaire) {
        akareId = idAkaire;
      }
      if (akareId == 'new' && pageId == 'new' && versionId == 'new') {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/new';
      }
      else {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/'+ akareId + '/' + pageId + '/' + versionId;
      }
      window.history.pushState( "", "", url );
    }
  }
  checkExistsExtension = async (extension, animation) => {
    const { isLoading, currentAnimationId, downloadExtensionUrl} = this.state;
    const animationId = animation ? animation.id : currentAnimationId
    const message_error = '動画のダウンロードに失敗しました。';
    return this.apiClient.post(`animations/${animationId}/extension_files/check_exists`, { extension: extension })
      .then(_ => {
        const { data, status } = _;
        if (data.status == 'prepare' || data.status == 'started') {
          if (!isLoading) { this.startLoading(data.message) }
          if (data.status == 'prepare') {
            this.create_extension(extension, animation)
          };
          if (data.status == 'started') { this.checkProgressPercent(extension, animation) };
        } else {
          if (data.status == 'finished' || data.status == 'error') {
            clearInterval(this.timerDownloadExtensionFile);
            this.setState({ timerDownloadExtensionFile: null })
            this.setState({ percentComplete: 0 });
          }
          if (data.status == 'finished') {
            toastr.success('動画をダウンロードしました。');
            window.location.href = data.url;
          }
          if (data.status == 'error') { toastr.error(message_error); }
          this.endLoading();
        }
      })
      .catch((e) => {
        toastr.error(message_error);
        this.endLoading();
      })
      .finally(() => {
        // this.endLoading();
      });
  }
  checkProgressPercent = (extension, animation = null) => {
    const { currentAnimationId, currentPageId } = this.state;
    const currentAnimation = animation ? animation : this.currentAnimation();
    const timeConvert = currentAnimation && currentAnimation.file_size ? ((((currentAnimation.file_size / 1024) * 10000) / (40 * 1024)) + 10000) / 100 : 1000;
    const timeForTimerDownload = timeConvert < 5000 ? 5000 : timeConvert + 1000;
    clearInterval(this.timerDownloadExtensionFile);
    this.timerDownloadExtensionFile = setInterval(() => {
      clearInterval(this.percentConvert);
      this.percentConvert = null;
      this.percentConvert = setInterval(() => {
        const { percentComplete, loadingText } = this.state;
        if (percentComplete < 99 && loadingText && loadingText.includes('converting')) {
          let text = loadingText.replace(/[\d%]/g,'');
          text += '\n' + percentComplete + '%';
          this.setState({ loadingText: text });
          this.setState({ percentComplete: percentComplete + 1 });
        }
      }, timeConvert);
      this.checkExistsExtension(extension, currentAnimation)
    }, timeForTimerDownload);
  }
  create_extension = (extension, animation) => {
    let animationId = animation.id
    this.apiClient.post(`animations/${animationId}/extension_files`, { extension: extension }).then(res => {
      this.checkProgressPercent(extension, animation);
    }).catch((e) => {
      this.endLoading();
    })
  }

  fetchListAnimationsDownload = async (pageId) => {
    const newAnimations = await this.apiClient.get(`pages/${pageId}/animations`);
    this.setState({ listAnimationDownloads: newAnimations.data });
  }

  onFinishUpload = ({ signedUrl }, filename) => {
    this.apiClient.post(`animations`, { pathname: decodeURI(urlParse(signedUrl).pathname), filename: filename })
      .then(async(response) => {
        if (!this.state.convertFile) {
          this.endLoading();
          toastr.success('動画をアップロードしました。');
          await this.getAnimationAdded()
            .then(() =>{
              this.fetchProject();
            });
          // reloadActivities();
          this.changeURL(this.state.currentPageId);
          this.close('AnimationUploaderModal');
          this.checkAnimationLastPage()
          if(response.data.new_animation) this.OpenModalUploadFirstAkaire();
        }
      })
      .catch(() => {
        toastr.error('動画のアップロードに失敗しました。');
      })
      .finally(() => {
        this.setState({ isShowDrawComponent: false });
        this.handleReset();
        this.endLoading();
        this.close('AnimationUploaderModal');
      })
  }
  onFinishUploadImage = ({ signedUrl }, filename) => {
    this.endLoading();
    return this.apiClient.post(`animations`, { pathname: urlParse(signedUrl).pathname, filename: filename })
      .then((response) => {
        toastr.success('画像をアップロードしました。');
        this.getAnimationAdded()
          .then(() =>{
            this.fetchProject();
            this.checkAnimationLastPage()
            if(response.data.new_animation) this.OpenModalUploadFirstAkaire();
          });
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('画像のアップロードに失敗しました。');
      })
      .finally(() => {
        this.setState({ isShowDrawComponent: false });
        this.handleReset();
        this.endLoading();
        this.close('AnimationUploaderModal');
      });
  }
  onUploadImage = () => {
    this.setState({ isShowDrawComponent: false });
    this.handleReset();
    this.endLoading();
    this.getAnimationAdded()
      .then(() =>{
        this.fetchProject();
        this.checkAnimationLastPage()
      });
    reloadActivities();
  }
  onGenerateLink = () => {
    this.fetchProject();
  }
  onDeleteLink = () => {
    this.fetchProject();
  }
  getHeightAnimaiton(height){
    this.setState({ heightAniamtion: height})
  }
  startLoading = loadingText => {
    this.setState({ loadingText, isLoading: true });
  }
  endLoading = () => {
    this.setState({ loadingText: null, isLoading: false });
    clearInterval(this.percentConvert);
    this.percentConvert = null;
  }
  convertFile = (file) => this.setState({ convertFile: file })
  finishConvert = (type) => {
    this.setState({ finishConvert: type })
  }
  startUploadVideo = (file) => {

    const extension = this.file_extension(file.name);
    const {current_limit_video: currentLimitVideo} = this.props;
    const { user: {total_file_size: totalFileSize, total_file_size_on_this_cycle: totalAllCycle, max_storage: maxStorage, total_file_size_limit: totalFileSizeLimit } } = this.state;
    var file_size = parseInt(file.size);
    if (file_size / ONE_GB_TO_BYTES > currentLimitVideo) {
      this.showAlertLimitVideo();
      return
    }
    if (file_size + Number(totalAllCycle) > Number(totalFileSizeLimit) || file_size + Number(totalFileSize) > Number(maxStorage)) {
      toastr.error('上限オーバーです　エラー');
      return;
    }
    this.startLoading(`${extension} file is uploading...`)
  }
  onSubmitCompletionModal = () => {
    this.finalizeAnimation().then(this.close.bind(this, 'CompletionModal'));
  }

  onChangeCssScroll = ()  => {
    this.setState({
      changeCss : !this.state.changeCss
    })
  }
  renderPromotionTooltip() {
    return (
      <ReactTooltip id="sign-up-promotion-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          こちらは会員専用機能となります。
        </div>
        <div className="mt-2 mb-2">
          無料会員でも全機能が利用可！<br />
          保存期間も『無期限』
          <span className="small">※利用状況により3ヶ月間</span>
        </div>
        <a href="/signup" style={{ pointerEvents: 'initial' }} className="btn btn-warning" target="_blank">
          会員登録はこちら
        </a>
      </ReactTooltip>
    );
  }
  handleSetDraw = (settingData) => {
    this.setState({
      penColor : settingData.color,
      lineWidth: settingData.lineWidth
    }, () => {
      this.handleDraw()
    })
  }

  handleDraw = () => {
    if (this.animationPlayer) {
      this.animationPlayer.draw()
    }
    else if (this.animationImage) {
      this.animationImage.draw()
    } else {
      return
    }
  }
  handleEraser = () => {
    if (this.animationPlayer) {
      this.animationPlayer.eraser()
    } else if(this.animationImage) {
      this.animationImage.eraser()
    } else {
      return
    }
  }
  handleEraserAll = () => {
    if (this.animationPlayer) {
      this.animationPlayer.deleteAllDraw()
      this.animationPlayer.draw()
    } else if(this.animationImage) {
      this.animationImage.deleteAllDraw()
      this.animationImage.draw()
    } else {
      return
    }
  }
  handleReset = () => {
    if (this.animationPlayer) {
      this.closeNouislider();
      this.setState({ showPeriodRange: [] });
      this.animationPlayer.reset()
    } else if(this.animationImage) {
      this.animationImage.reset()
    } else {
      return
    }
  }

  handleCapture = () => {
    if (this.animationPlayer) {
      this.animationPlayer.capture()
    } else if(this.animationImage){
      this.animationImage.capture()
    } else {
      return
    }
  }

  changeShowDrawComponent = (isShow) => {
    this.setState({ is_show_draw_component: isShow })
  }

  disabledPentool = () => {
    this.setState({
      isShowDrawComponent: false,
      is_show_draw_component: false,
    })
    this.handleReset()
  }

  toggleModeDrawComponent = (showDraw) => {
    const {is_capture_video_youtube, currentAnimationId, animationSelectCurrent} = this.state

    if (navigator.userAgent.match(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i)) {
      Popup.alert('スマホ環境及びiPadでは、ペンツールはご利用頂けません。', '', '', {kind: 'akaire'});
      return
    }

    if (is_capture_video_youtube) {
      this.setState({is_cancel_capture_video_youtube: true})
      if (this.animationPlayer) {
        this.animationPlayer.startLoading('キャプチャを停止中です。少しお待ちください。。。')
      }
      return
    }
    if (currentAnimationId !== animationSelectCurrent) {
      if (this.animationPlayer) {
        this.animationPlayer.setOption()
      } else if (this.animationImage) {
        this.animationImage.setOption()
      }
      return
    }
    this.setState({
      isShowDrawComponent: showDraw,
    }, () => {
      this.changeShowDrawComponent(showDraw)
      if (!showDraw && document.getElementById('animation-player')) {
        document.getElementById('animation-player').style.overflow = 'unset'
      }
      this.state.isShowDrawComponent ? (
        this.handleDraw()
      ) : (
        this.handleReset()
      )
    });
  };

  changeIsCaptureVideoYoutube = () => {
    this.setState({ is_capture_video_youtube: !this.state.is_capture_video_youtube })
  }

  changeIsShowFullAnimation = (event) => {
    if(event.target.className.includes('right')) {
      var windowWidth = window.innerWidth;
      if(window.innerWidth > 1600) windowWidth = 1600
      const rotateSize = (windowWidth - 435)/windowWidth;
      $('.video-react-controls-enabled').css('padding-top', `${(9/16 * rotateSize * 100)}%`)
      $('.video-embed').css('padding-top', `${(9/16 * rotateSize * 100)}%`)
    } else {
      $('.video-react-controls-enabled').css('padding-top', '56.25%')
      $('.video-embed').css('padding-top', '56.25%')
    }
    this.setState({ isShowFullscreenAnimation: !this.state.isShowFullscreenAnimation})
  }

  changeTypeDownLoad = (event, type) => {
    this.setState({selectTypeDownload: type});
    this.disabledBtnSelectDownload(event)
  }

  changeTypeDownLoadVideo = (event, type) => {
    this.setState({selectTypeDownloadVideo: type});
    this.disabledBtnSelectDownload(event)
  }

  disabledBtnSelectDownload = (event) => {
    $(event.target).parents('.list-downloads-type').find('div').removeClass('disabled')
    if ($(event.target).hasClass('cursor-pointer')) {
      $(event.target).addClass('disabled')
    } else {
      $(event.target).parents('div.cursor-pointer').addClass('disabled')
    }
  }

  downloadImage = (selectAnimationId = null) => {
    const {selectTypeDownload} = this.state;
    if (selectTypeDownload === '') {
      return
    }
    let currentAnimation = null;
    if (selectAnimationId) {
      currentAnimation = this.animationSelect(selectAnimationId)
    } else {
      currentAnimation = this.currentAnimation();
    }
    this.setState({selectTypeDownload: '', isShowModalDownloadImage: false});
    this.checkExistsExtension(selectTypeDownload, currentAnimation)
    document.removeEventListener("click", this.closeModalDownload, false);
  }

  animationSelect = (selectAnimationId) => {
    const { listAnimationDownloads } = this.state;
    if (listAnimationDownloads.length > 0) {
      return listAnimationDownloads.find(_ => _.id === Number(selectAnimationId));
    } else {
      return this.state.animations.find(_ => _.id === Number(selectAnimationId));
    }
  }
  downloadVideo = (selectAnimationId = null) => {
    const {selectTypeDownloadVideo} = this.state;
    if (selectTypeDownloadVideo === '') {
      return
    }
    let currentAnimation = null;
    if (selectAnimationId) {
      currentAnimation = this.animationSelect(selectAnimationId)
    } else {
      currentAnimation = this.currentAnimation();
    }
    const {current_limit_video: currentLimitVideo} = this.props;
    if (currentAnimation && currentAnimation.file_size / ONE_GB_TO_BYTES > currentLimitVideo) {
      this.showAlertLimitVideo();
      return
    }
    if(currentAnimation?.is_video_embed) {
      Popup.alert('YouTubeデータはダウンロードできません。')
      return
    }
    this.setState({selectTypeDownloadVideo: '', isShowModalDownloadVideo: false});
    this.checkExistsExtension(selectTypeDownloadVideo, currentAnimation);
    document.removeEventListener("click", this.closeModalDownload, false);
  }

  downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloader(data, type, name) {
    let blob = new Blob([data], {type});
    let url = window.URL.createObjectURL(blob);
    this.downloadURI(url, name);
    window.URL.revokeObjectURL(url);
}

  checkExistsExtensionImage = (extension) => {
    const { isLoading, currentAnimationId, downloadExtensionUrl} = this.state;
    const message_error = '動画のダウンロードに失敗しました。';
    return this.apiClient.post(`animations/${currentAnimationId}/extension_files/check_exists`, { extension: extension })
      .then(_ => {
        const { data, status } = _;
        if (data.status == 'prepare' || data.status == 'started') {
          if (!isLoading) { this.startLoading(data.message) }
          if (data.status == 'prepare') { this.create_extension(extension) };
          if (data.status == 'started') { this.checkProgressPercent(extension) };
        } else {
          if (data.status == 'finished' || data.status == 'error') {
            clearInterval(this.timerDownloadExtensionFile);
            this.setState({ timerDownloadExtensionFile: null })
            this.setState({ percentComplete: 0 });
          }
          if (data.status == 'finished') {
            toastr.success('動画をダウンロードしました。');
            console.log(data)
            window.location = data.url
          }
          if (data.status == 'error') { toastr.error(message_error); }
          this.endLoading();
        }
      })
      .catch((e) => {
        toastr.error(message_error);
        this.endLoading();
      })
      .finally(() => {
        // this.endLoading();
      });
  }

  closeModalDownload = (event) => {
    if (event.target.classList.contains('modal-download-animation') ||
       event.target.classList.contains('icon') || event.target.classList.contains('btn-download-for-mobile') ||
       event.target.classList.contains('fm_item') ||
       $(event.target).parents('.modal-download-animation').length > 0 ||
       document.getElementsByClassName('swal2-container ')[0]?.contains(event.target)) {
      return
    }
    this.setState({
      selectTypeDownloadVideo: '', isShowModalDownloadVideo: false,
      selectTypeDownload: '', isShowModalDownloadImage: false
    });
  }

  changeIsShowInputVideo = () => {
    const {isShowInputPassVideo, passwordShareVideo} = this.state
    if (isShowInputPassVideo) {
      if (!passwordShareVideo || passwordShareVideo === '') {
        this.setPasswordForShareVideo('', false);
        this.setState({
          isShowInputPassVideo: !isShowInputPassVideo,
          passwordShareVideo: ''
        })
        return
      }
      Popup.confirm(
        `パスワード設定をOFFにすると<br>設定したパスワードはリセットされます。<br>OFFにしてよろしいですか？`,
        {
          success: () => {
            this.setPasswordForShareVideo('', false);
            this.setState({
              isShowInputPassVideo: !isShowInputPassVideo,
              passwordShareVideo: ''
            })
          },
          cancel: () => {},
          confirmText: 'OK',
          width: 'auto',
          kind: 'akaire'
        }
      )
      return
    }
    this.setState({
      isShowInputPassVideo: !isShowInputPassVideo,
      passwordShareVideo: ''
    })
  }

  setPasswordForShareVideo = (password, isshowPopup = true) => {
    const {currentAnimationId} = this.state
    this.apiClient.post(
      `animations/change_password_share_video`,
      { password: password, id: currentAnimationId }
    )
      .then(res => {
        if (isshowPopup) {
          Popup.alert('パスワードを保存しました。', '', '', {kind: 'akaire'})
        }
        this.fetchAnimations(currentAnimationId)
      })
      .catch((e) => {
        Popup.alert(`An error occurred`, '', '', {kind: 'akaire'})
        this.endLoading();
      })
  }

  checkPassWord = (event) => {
    const pass = $(event.target).val()
    this.setState({ passwordShareVideo: pass })
    if (pass.length >= 6) {
      this.setState({isDisabledButtonSavePass: false})
    } else {
      this.setState({isDisabledButtonSavePass: true})
    }
  }

  checkPassWordUrl = (event) => {
    const pass = $(event.target).val()
    this.setState({ passwordShareUrl: pass })
    if (pass.length >= 6) {
      this.setState({isDisabledButtonSavePassUrl: false})
    } else {
      this.setState({isDisabledButtonSavePassUrl: true})
    }
  }

  changeIsShowShareInputPassUrl = () => {
    const {isShowInputPassUrl, passwordShareUrl} = this.state
    if (isShowInputPassUrl) {
      if (!passwordShareUrl || passwordShareUrl === '') {
        this.setPasswordForShareUrl('', false);
        this.setState({
          isShowInputPassUrl: !isShowInputPassUrl,
          passwordShareUrl: ''
        })
        return
      }
      Popup.confirm(
        `パスワード設定をOFFにすると<br>設定したパスワードはリセットされます。<br>OFFにしてよろしいですか？`,
        {
          success: () => {
            this.setPasswordForShareUrl('', false);
            this.setState({
              isShowInputPassUrl: !isShowInputPassUrl,
              passwordShareUrl: ''
            })
          },
          cancel: () => {},
          confirmText: 'OK',
          width: 'auto',
          kind: 'akaire',
        }
      )
      return
    }
    this.setState({
      isShowInputPassUrl: !isShowInputPassUrl,
      passwordShareUrl: ''
    })
  }

  setPasswordForShareUrl = (password, isShowPopup = true) => {
    const {currentAnimationId} = this.state
    this.apiClient.post(
      `animations/change_password_share_url`,
      { password: password, id: currentAnimationId }
    )
    .then(res => {
      if (isShowPopup) {
        Popup.alert('パスワードを保存しました。', '', '', {kind: 'akaire'})
      }
      this.setState({ currentPasswordShareUrl: password })
      this.fetchAnimations(currentAnimationId)
    })
    .catch((e) => {
      Popup.alert(`An error occurred`, '', '', {kind: 'akaire'})
      this.endLoading();
    })
  }

  changeFocus = () => {
    const {isShowInputDemo} = this.state
    this.setState({isShowInputDemo: !isShowInputDemo});
  }

  changeFocusUrl = () => {
    const {isShowInputDemoUrl} = this.state
    this.setState({isShowInputDemoUrl: !isShowInputDemoUrl});
  }

  closeModalShareVideo = () => {
    const {currentPasswordShareVideo, passwordShareVideo} = this.state
    if (currentPasswordShareVideo !== passwordShareVideo) {
      Popup.confirm(`パスワードが保存されていません。<br>閉じてよろしいですか？`, {
        success: () => {
          this.setState({
            [`shouldShowPublicUrlModal`]: false, passwordShareVideo: currentPasswordShareVideo,
            isShowInputDemo: true,
          })
        },
        confirmText: 'OK',
        kind: 'akaire',
      })
      return
    }
    this.setState({ [`shouldShowPublicUrlModal`]: false })
  }

  closeModalShareUrl = () => {
    const {currentPasswordShareUrl, passwordShareUrl} = this.state
    if (currentPasswordShareUrl !== passwordShareUrl) {
      Popup.confirm(`パスワードが保存されていません。<br>閉じてよろしいですか？`, {
        success: () => {
          this.setState({
            [`shouldShowProjectShareModal`]: false, passwordShareUrl: currentPasswordShareUrl,
            isShowInputDemoUrl: true
          })
        },
        confirmText: 'OK',
        kind: 'akaire'
      })
      return
    }
    this.setState({ [`shouldShowProjectShareModal`]: false })
  }

  OpenModalUploadFirstAkaire = () => {
    this.setState({isOpenModalUploadFirstAkaire: !this.state.isOpenModalUploadFirstAkaire});
  }

  settingMember = (event) => {
    this.OpenModalUploadFirstAkaire();
    $('.text-akaire-member-list').click();
  }

  updateSelectedMarker = (marker) => {
    this.setState({ selectedMarker: marker });
  }

  updateIsOpenNouislider = () => {
    const { isInputInfoMationOfNotLogin } = this.state;

    if (gon.is_login || isInputInfoMationOfNotLogin) {
      this.setState({ isOpenNouislider: !this.state.isOpenNouislider })
    } else {
      this.toggleModalInputInfoOfNotLogin()
    }
  }

  closeNouislider = () => {
    this.setState({ isOpenNouislider: false })
  }

  togglePopup = () => {
    this.setState({ modalChangeCommentListIsOpenIsOpen: !this.state.modalChangeCommentListIsOpenIsOpen });
  }

  togleModalConfirmSendMailNotLogin = () => {
    this.setState({ modalConfirmSendMailLogin: !this.state.modalConfirmSendMailLogin });
  }

  showPopupCreateNoAnimation = () => {
    const {page_last_animation} = this.state
    if (!page_last_animation) {
      $('#uploadVersionPopup').modal('show')
    }
  }

  toggleModalCreateCommentNotLogin = () => {
    this.setState({ isShowModalCreateCommentNotLogin: !this.state.isShowModalCreateCommentNotLogin })
  }

  onClickToggleMarkersFromCollaborator = (e) => {
    e.preventDefault();
    const { currentAnimationId } = this.state;
    
    // Check if we have a current animation
    const currentAnimation = this.currentAnimation();
    if (!currentAnimation) return;
    
    this.startLoading();
    
    return this.apiClient.post(`animations/${currentAnimationId}/toggle_markers_display`)
      .then((response) => {
        toastr.success('設定を更新しました');
        this.fetchAnimations();
      })
      .catch(() => {
        toastr.error('設定の更新に失敗しました');
      })
      .finally(this.endLoading);
  }

  setEmailTepAndUserTempNotLogin = () => {
    Popup.alert('メディア画面より、コメント追加ができます。')
    const { emailTempOfNotLogin, userNameTempOfNotLogin} = this.state;
    localStorage.setItem('email_temp_of_not_login', emailTempOfNotLogin)
    localStorage.setItem('user_temp_of_not_login', userNameTempOfNotLogin)
    this.setState({isInputInfoMationOfNotLogin: true, showModalInputInfoOfNotLogin: false})
  }

  changeEmailTempOfNotLogin = (event) => {
    this.setState({emailTempOfNotLogin: event.target.value})
  }

  changeUserNameTempOfNotLogin = (event) => {
    this.setState({userNameTempOfNotLogin: event.target.value})
  }
  changeNotShowModalCreateCommentNotLogin = (event) => {
    localStorage.setItem('isNotShowCreateCommentNotLogin', !this.state.isNotShowCreateCommentNotLogin)
    this.setState({isNotShowCreateCommentNotLogin: !this.state.isNotShowCreateCommentNotLogin})
  }

  renderNormal() {
    const { current_user_id: currentUserId, role, id, root_url: rootUrl, statuses, env, port,
      adsence_config: adsenceConfig, is_free_plan: isFreePlan, windowWidth, owner, extensions, project_type,
      current_limit_video: currentLimitVideo, project_id,
      current_subscription_type: currentSubscriptionType, can_use_page_management: canUsePageManagement,
      token_public_url: token_public_url, baseURL, category, is_akapon_member: isAkaponMember, is_project_owner_member,
      isAkaponProject
    } = this.props;
    const {
      loadingText, isLoading, animations, markers, comments, assignments, membersInComment,
      shouldShowGetUrlModal, shouldShowProjectShareModal, shouldShowProjectFormModal,
      shouldShowAnimationUploaderModal, shouldShowPublicUrlModal, convert_file, completed,
      shouldShowCompletionModal, currentAnimationId, user, project, currentAnimationExtension, heightAniamtion,
      percentComplete, pages, currentPageId, readMoreText, readMoreStyle, uploadedDataInfo, tickets, scaleImage, currentTicketId, shouldShowAd, shouldShowGetUrlYoutube, is_show_draw_component, animationSelectCurrent,
      is_create_video_embed, isShowDrawComponent, is_capture_video_youtube, isShowFullscreenAnimation,
      is_create_pass_video, isShowInputPassVideo,
      passwordShareVideo, isDisabledButtonSavePass, isDisabledButtonSavePassUrl, passwordShareUrl,
      isShowInputPassUrl, isShowInputDemo, isShowInputDemoUrl, page_last_animation, selectTypeDownloadVideo,isOpenModalUploadFirstAkaire, isShowModalDownloadImage, selectTypeDownload, isShowModalDownloadVideo,
      modalChangeCommentListIsOpenIsOpen, modalChangeCommentListText, modalChangeCommentListCurrentAnimation, modalChangeCommentListKind,
      isDisabledButtonSendMailNotLogin, modalConfirmSendMailLogin,
      showModalInputInfoOfNotLogin, userNameTempOfNotLogin, emailTempOfNotLogin, isInputInfoMationOfNotLogin,
      isShowModalCreateCommentNotLogin, isNotShowCreateCommentNotLogin
    } = this.state;
    const { name, description, url_token: publicToken } = project;
    const currentAnimation = this.currentAnimation();
    const src = currentAnimation && currentAnimation.is_video_embed ? currentAnimation.url : currentAnimation && currentAnimation.file_object.url;
    const downloadUrl = this.currentDownloadExtensionUrl();
    const token = token_public_url;
    const public_video_token = currentAnimation && currentAnimation.url_token;
    // const shouldShowAd = env === 'production' && (currentUserId == null || isFreePlan);
    var darkGray = 'l-container u-bg--darkGray py-3 show-media-container';
    if (!currentAnimation) {
      darkGray = darkGray.concat(" darkGray-animation")
    }
    let classFlexBasisFoAnimation = isShowFullscreenAnimation ? 'top-pane flex-basis-100' : 'top-pane'

    let heightTopToAnimation = fetchHeightHeader();
    const pageCurrent = this.currentPage();
    return (
      <div className="animation-inspector">
        <Loadable
          className="loading-overlay"
          active={isLoading}
          text={loadingText || 'Now loading...'}
          zIndex={10000}
        >
          {/* TODO: Loadableがうまくうごかなくなったので暫定対応 あとでなんとかする */}
          <div className={darkGray}>
            <div className="l-main">
              <div className="pane-wrapper">
                {/* アニメーション */}
                {
                  ['動画', 'video'].includes(project_type) ? (
                  //start-block-1
                  currentAnimation ? (
                    !currentAnimation.is_video_embed && !!currentAnimation.file_object.url ? (
                    <div className={classFlexBasisFoAnimation}>
                      <div className={'d-flex page-control mb-2 justify-content-space-between'}>
                        { canUsePageManagement ? (
                          <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                            currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                            pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                            onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={false}
                            onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                            currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                            role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                            animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                        ) : ''}

                        <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                          toggleModeDrawComponent={this.toggleModeDrawComponent}
                          isShowDrawComponent={isShowDrawComponent}
                          assignments={assignments} markers={markers}
                          onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                          rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                          scaleImage={scaleImage}
                          handleSetDraw={this.handleSetDraw}
                          onClickDraw={this.handleDraw}
                          onClickEraser={this.handleEraser}
                          onClickReset={this.handleReset}
                          onClickCapture={this.handleCapture}
                          onClickEraserAll={this.handleEraserAll}
                          role={role}
                          changeShowDrawComponent={this.changeShowDrawComponent}
                          mobile={false} category={category}
                          currentAnimation={currentAnimation}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                        />
                      </div>
                      {<AnimationPlayer
                      src={src} onClickDownloadButton={this.onClickDownloadButton} downloadUrl={downloadUrl} onSubmitMarkerForm={this.onSubmitMarkerForm} markers={markers} ref={ref => this.animationPlayer = ref} markerCreatable={canCreateAnimationMarker(role)} currentUserId={currentUserId} canCreateAnimation={!canCreateAnimation(role, animations)} onMarkerUpdationCalled={this.updateMarker.bind(this)} updateMakerShow={this.updateMakerShow.bind(this)} getHeightAnimaiton={this.getHeightAnimaiton.bind(this)} mobile={false} role={role} owner={owner} user={user}
                      settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}}
                      currentAnimation={currentAnimation}
                      scaleImage={scaleImage}
                      currentAnimationId={currentAnimationId}
                      animationSelectCurrent={animationSelectCurrent}
                      setCurrentAnimation={this.setCurrentAnimation}
                      selectedMarker={this.state.selectedMarker}
                      updateSelectedMarker={this.updateSelectedMarker}
                      onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                      reloadMarkers={this.fetchMarkers.bind(this)}
                      apiClient={this.apiClient}
                      deleteReplyMarker={this.deleteReplyMarker}
                      avatarUrl={this.state.avatarUrl}
                      toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                      isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                      isShowDrawComponent={isShowDrawComponent}
                      updateIsOpenNouislider={this.updateIsOpenNouislider}
                      closeNouislider={this.closeNouislider}
                      isOpenNouislider={this.state.isOpenNouislider}
                      showPeriodRangeProp={this.state.showPeriodRange}
                      /> }
                      {/* 動画情報 */}
                      <div className="project-meta">
                        {/* 動画バージョン */}
                        {/* Dont display it anymore */}
                        {/* {!currentUserId && this.renderPromotionTooltip()} */}
                        {/* 動画バージョン */}
                      </div>
                      {/* 動画情報 */}
                      {
                          shouldShowAd && (
                          <div className="adsence top">
                              <AdSense.Google
                                client={adsenceConfig.client}
                                slot={adsenceConfig.slot.big_banner}
                                style={{ display: "block", width: "728px", height: "90px" }}
                                layout="in-article"
                                format="auto"
                              />
                          </div>
                        )
                      }
                    </div>
                    ) : ( currentAnimation && currentAnimation.url ? (
                      <div className={classFlexBasisFoAnimation}>
                        <div className={'d-flex page-control mb-2 justify-content-space-between'}>
                          { canUsePageManagement ? (
                            <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                              currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                              pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                              onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={false}
                              onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                              currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                              role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                              animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                          ) : ''}

                          <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                            toggleModeDrawComponent={this.toggleModeDrawComponent}
                            isShowDrawComponent={isShowDrawComponent}
                            assignments={assignments} markers={markers}
                            onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                            rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                            scaleImage={scaleImage}
                            handleSetDraw={this.handleSetDraw}
                            onClickDraw={this.handleDraw}
                            onClickEraser={this.handleEraser}
                            onClickReset={this.handleReset}
                            onClickCapture={this.handleCapture}
                            onClickEraserAll={this.handleEraserAll}
                            role={role}
                            changeShowDrawComponent={this.changeShowDrawComponent}
                            mobile={false} category={category}
                            currentAnimation={currentAnimation}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          />
                        </div>
                        {<ShowEmbedVideo
                          src={src} onClickDownloadButton={this.onClickDownloadButton} downloadUrl={downloadUrl} onSubmitMarkerForm={this.onSubmitMarkerForm} markers={markers} ref={ref => this.animationPlayer = ref} markerCreatable={canCreateAnimationMarker(role)} currentUserId={currentUserId} canCreateAnimation={!canCreateAnimation(role, animations)} onMarkerUpdationCalled={this.updateMarker.bind(this)} updateMakerShow={this.updateMakerShow.bind(this)} getHeightAnimaiton={this.getHeightAnimaiton.bind(this)} mobile={false} role={role} owner={owner} user={user}
                          settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}}
                          currentAnimation={currentAnimation}
                          scaleImage={scaleImage}
                          baseURL={this.baseURL}
                          is_show_draw_component={is_show_draw_component}
                          currentAnimationId={currentAnimationId}
                          animationSelectCurrent={animationSelectCurrent}
                          setCurrentAnimation={this.setCurrentAnimation}
                          heightTopToAnimation={heightTopToAnimation}
                          changeIsCaptureVideoYoutube={this.changeIsCaptureVideoYoutube}
                          is_capture_video_youtube={is_capture_video_youtube}
                          selectedMarker={this.state.selectedMarker}
                          updateSelectedMarker={this.updateSelectedMarker}
                          onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                          reloadMarkers={this.fetchMarkers.bind(this)}
                          apiClient={this.apiClient}
                          deleteReplyMarker={this.deleteReplyMarker}
                          avatarUrl={this.state.avatarUrl}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          isShowDrawComponent={isShowDrawComponent}
                          updateIsOpenNouislider={this.updateIsOpenNouislider}
                          closeNouislider={this.closeNouislider}
                          isOpenNouislider={this.state.isOpenNouislider}
                          showPeriodRangeProp={this.state.showPeriodRange}
                          /> }
                      </div> ) : (
                        <div className={classFlexBasisFoAnimation}>
                          <div className={'d-flex page-control mb-2 justify-content-space-between'}>
                            { canUsePageManagement ? (
                              <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                                currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                                pages={pages} onChangePageSelector={this.onChangePageSelector} selectAnimation={this.selectAnimation}
                                onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={false}
                                onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                                currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                                role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                                animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                            ) : ''}

                            <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                              toggleModeDrawComponent={this.toggleModeDrawComponent}
                              isShowDrawComponent={isShowDrawComponent}
                              assignments={assignments} markers={markers}
                              onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                              rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                              scaleImage={scaleImage}
                              handleSetDraw={this.handleSetDraw}
                              onClickDraw={this.handleDraw}
                              onClickEraser={this.handleEraser}
                              onClickReset={this.handleReset}
                              onClickCapture={this.handleCapture}
                              onClickEraserAll={this.handleEraserAll}
                              role={role}
                              changeShowDrawComponent={this.changeShowDrawComponent}
                              mobile={false} category={category}
                              currentAnimation={currentAnimation}
                              toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                              isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                            />
                          </div>
                          <div className="no-animations">
                            <div className="content d-flex flex-column justify-content-center align-items-center">
                              <div>
                                新しいバージョンが更新された為、
                                <br />
                                {currentAnimation.title}バージョンのURLは削除されました。
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className={classFlexBasisFoAnimation + ' padding-right-15px'}>
                      <div className={'d-flex mb-2 justify-content-space-between'}>
                        { canUsePageManagement ? (
                          <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                            currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                            pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                            onClickFinalizeAnimation={this.onClickFinalizeAnimation}
                            onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                            mobile={false}
                            currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                            role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                            animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                        ) : ''}

                        <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                          toggleModeDrawComponent={this.toggleModeDrawComponent}
                          isShowDrawComponent={isShowDrawComponent}
                          assignments={assignments} markers={markers}
                          onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                          rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                          scaleImage={scaleImage}
                          handleSetDraw={this.handleSetDraw}
                          onClickDraw={this.handleDraw}
                          onClickEraser={this.handleEraser}
                          onClickReset={this.handleReset}
                          onClickCapture={this.handleCapture}
                          onClickEraserAll={this.handleEraserAll}
                          role={role}
                          changeShowDrawComponent={this.changeShowDrawComponent}
                          mobile={false} category={category}
                          currentAnimation={currentAnimation}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                        />
                      </div>
                      <div className="no-animations">
                        <div className="content d-flex flex-column justify-content-center align-items-center">
                          <img className="create-akaire-box-icon-upload"
                              src={NoAnimationPreview} alt="アップロード"/>
                        </div>
                      </div>
                    </div>
                  )
                  //end-block-1
                  ) : (
                  //start-block-2
                  currentAnimation && !!currentAnimation.file_object.url ? (
                    <div className={classFlexBasisFoAnimation}>
                      <div className={'d-flex page-control mb-2 justify-content-space-between'}>
                        { canUsePageManagement ? (
                          <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                            currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                            pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                            onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={false}
                            onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                            currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                            role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                            animations={animations} page_last_animation={page_last_animation} projectType={'Web'} />
                        ) : ''}

                        <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                          toggleModeDrawComponent={this.toggleModeDrawComponent}
                          isShowDrawComponent={isShowDrawComponent}
                          assignments={assignments} markers={markers}
                          onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                          rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                          scaleImage={scaleImage}
                          handleSetDraw={this.handleSetDraw}
                          onClickDraw={this.handleDraw}
                          onClickEraser={this.handleEraser}
                          onClickReset={this.handleReset}
                          onClickCapture={this.handleCapture}
                          onClickEraserAll={this.handleEraserAll}
                          role={role}
                          changeShowDrawComponent={this.changeShowDrawComponent}
                          mobile={false} category={category}
                          currentAnimation={currentAnimation}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                        />
                      </div>
                      {<AnimationImage
                        scaleImage={scaleImage} changeCss={this.state.changeCss} src={src}
                        onClickDownloadButton={this.onClickDownloadButton} downloadUrl={downloadUrl}
                        onSubmitMarkerForm={this.onSubmitMarkerForm} markers={markers}
                        ref={ref => this.animationImage = ref}
                        markerCreatable={canCreateAnimationMarker(role)}
                        onMarkerUpdationCalled={this.updateMarker.bind(this)}
                        updateMakerShow={this.updateMakerShow.bind(this)}
                        getHeightAnimaiton={this.getHeightAnimaiton.bind(this)}
                        mobile={false} currentUserId={currentUserId} role={role}
                        owner={owner} user={user}
                        settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}}
                        currentAnimationId={currentAnimationId}
                        animationSelectCurrent={animationSelectCurrent}
                        currentAnimation={currentAnimation}
                        setCurrentAnimation={this.setCurrentAnimation}
                        onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                        selectedMarker={this.state.selectedMarker}
                        updateSelectedMarker={this.updateSelectedMarker}
                        reloadMarkers={this.fetchMarkers.bind(this)}
                        apiClient={this.apiClient}
                        deleteReplyMarker={this.deleteReplyMarker}
                        avatarUrl={this.state.avatarUrl}
                        toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                        isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                        /> }
                      {/* image infor */}
                      <div className="project-meta">
                        {/* {!currentUserId && this.renderPromotionTooltip()} */}
                      </div>
                      {/* image infor*/}
                      {
                        shouldShowAd && (
                          <div className="adsence top">
                            <AdSense.Google
                              client={adsenceConfig.client}
                              slot='2963284461'
                              style={{ display: "block"}}
                              format="auto"
                              responsive='true'
                            />
                          </div>
                        )
                      }

                    </div>
                  ) : (
                    <div className={classFlexBasisFoAnimation + ' padding-right-15px'}>
                      <div className={'d-flex page-control mb-2 justify-content-space-between'}>
                        { canUsePageManagement ? (
                          <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                            currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                            pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                            onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={false}
                            onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                            currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                            role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                            animations={animations} page_last_animation={page_last_animation} projectType={'Web'} />
                        ) : ''}

                        <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                          toggleModeDrawComponent={this.toggleModeDrawComponent}
                          isShowDrawComponent={isShowDrawComponent}
                          assignments={assignments} markers={markers}
                          onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                          rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                          scaleImage={scaleImage}
                          handleSetDraw={this.handleSetDraw}
                          onClickDraw={this.handleDraw}
                          onClickEraser={this.handleEraser}
                          onClickReset={this.handleReset}
                          onClickCapture={this.handleCapture}
                          onClickEraserAll={this.handleEraserAll}
                          role={role}
                          changeShowDrawComponent={this.changeShowDrawComponent}
                          mobile={false} category={category}
                          currentAnimation={currentAnimation}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                        />
                      </div>
                      <div className="no-animations">
                        <div className="content d-flex flex-column justify-content-center align-items-center">
                          <img className="create-akaire-box-icon-upload"
                            src={NoAnimationPreview} alt="アップロード"/>
                        </div>
                      </div>
                    </div>
                  )
                  //end-block-2
                  )
                }
                {
                  <div className="d-none align-items-center justify-content-center show-popup-text-new-comment">
                    <span className='show-text-of-new-comment padding-10px'></span>
                  </div>
                }
                {
                  currentAnimation ? (isShowFullscreenAnimation ? (
                    <i className='fas fa-caret-left cursor-pointer' onClick={isShowDrawComponent ? null : this.changeIsShowFullAnimation}
                       style={{fontSize: '40px', paddingRight : '3px', marginTop: '-6px', color: 'white', paddingTop: '5px'}}></i>
                  ) : (
                    <i className={`fas fa-caret-right cursor-pointer ${this.showMode == 'compare' && 'd-none'} `} onClick={isShowDrawComponent ? null : this.changeIsShowFullAnimation}
                       style={{fontSize: '40px', paddingRight : '3px', marginTop: '-6px', color: 'white', paddingTop: '5px'}}></i>
                  )) : ''
                }

                {/* 赤入れコメント */}
                <AnimationMarkersPane isShowFullscreenAnimation={isShowFullscreenAnimation} page_last_animation={page_last_animation} onClickDownloadImageButton={this.onClickDownloadImageButton} isAkaponMember={isAkaponMember} membersInComment={membersInComment} env={env} taskId={id} projectId={project_id} rootUrl={rootUrl} port={port} deleteReplyMarker={this.deleteReplyMarker.bind(this)} isStillAvailableStorage={this.isStillAvailableStorage.bind(this)} assignments={assignments} reloadMarkers={this.fetchMarkers.bind(this)} onClickFinalizeAnimation={this.onClickFinalizeAnimation} onClickCancelFinalAnimation={this.onClickCancelFinalAnimation} onChangeTicket={this.onChangeTicket} currentTicketId={currentTicketId} scaleImage={scaleImage} tickets={tickets} markers={markers} onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)} onMarkerUpdationCalled={this.onMarkerUpdationCalled.bind(this)} onClickMarker={this.onClickMarker.bind(this)}
                  currentUserId={currentUserId} role={role} shouldShowAd={shouldShowAd} currentAnimation={currentAnimation} downloadUrl={downloadUrl} animations={animations} onChange={this.onChangeAnimationSelect} currentSubscriptionType={currentSubscriptionType} apiClient={this.apiClient}
                  disabled={!canCreateAnimation(role, animations)} currentAnimationId={currentAnimationId} projectFormModal={this.open.bind(this, 'ProjectFormModal')} name={name} deleteAnimation={this.deleteAnimation}
                  uploadAnimation={this.open.bind(this, 'AnimationUploaderModal')} publicUrlModal={this.open.bind(this, 'PublicUrlModal')} onChangeScaleImage={(flag)=>this.onChangeScaleImage(flag)} autoComment={this.onClickAutoCommentButton} getUrlModal={this.open.bind(this, 'GetUrlModal')} completionModal={this.open.bind(this, 'CompletionModal')}
                  project_type={project_type} changeExtension={this.onChangeAnimationExtension} selectAnimationExtension={this.selectedAnimationExtension()}
                  extensions={extensions} mobile={false} onClickDownloadButton={this.onClickDownloadButton} owner={owner} heightAniamtion={heightAniamtion} canUsePageManagement={canUsePageManagement} pages={pages}
                  onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                  onChangePage={this.onChangePage} currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)} currentAnimationTotalSize={this.getTotalSizeAnimation()} deletePage={this.deletePage}
                  project={this.state.project}
                  is_video_embed={currentAnimation && currentAnimation.is_video_embed}
                  getUrlYoutube={this.open.bind(this, 'GetUrlYoutube')}
                  onChangeCommentVersion={this.onChangeCommentVersion}
                  animationSelectCurrent={animationSelectCurrent}
                  setCurrentAnimation={this.setCurrentAnimation}
                  selectedMarker={this.state.selectedMarker}
                  updateSelectedMarker={this.updateSelectedMarker}
                  avatarUrl={this.state.avatarUrl}
                  isDisabledButtonSendMailNotLogin={isDisabledButtonSendMailNotLogin}
                  showPopupConfirmSendMailNotLogin={this.showPopupConfirmSendMailNotLogin}
                  isShowDrawComponent={isShowDrawComponent} showMode={this.showMode}
                  onStart={this.startLoading.bind(this, 'Now Processing...')}
                  onFinish={this.endLoading}
                  onClickToggleMarkersFromCollaborator={this.onClickToggleMarkersFromCollaborator}
                   />
                {/* /赤入れコメント */}
              </div>
              {/* / pane-wrapper */}
            </div>
            {/* / l-main */}
          </div>
          {/* / l-container */}

          <div className="l-container">
            <div className="l-main">
              {/* 案件概要 */}
              <div className="project-description">
                {/* <div className={classnames('body')} id='body-description-project' style={readMoreStyle}>
                  {
                    description ? nl2br(description) : <span className="text-muted">(概要なし)</span>
                  }
                </div>
                <div className='read-more d-flex justify-content-center'>
                  {
                    this.readMore() ?
                      <Button type='button' class='btn btn-link' onClick={this.onClickReadMore}>{readMoreText}</Button>
                      : ''
                  }
                </div> */}
              </div>
              {/* 案件概要 */}

              {/* コメント */}
              {
                (
                  ['動画', 'video'].includes(this.props.project_type) ? ([
                    <AnimationCommentForm onSubmit={this.onSubmitCommentForm.bind(this)} placeholder={'動画をアップロードしたらコメントを投稿できるようになります'} disabled={!currentAnimation} currentUserId={currentUserId} role={role} owner={owner} user={user}/>
                  ]) : ([
                      <AnimationCommentForm onSubmit={this.onSubmitCommentForm.bind(this)} placeholder={'画像をアップロードしたらコメントを投稿できるようになります'} disabled={!currentAnimation} currentUserId={currentUserId} role={role} owner={owner} user={user}/>,
                      <FabMenu method="click" position="bl" className="float-btn-scroll-lock">
                      {
                        (platform.product === 'iPad' || /iPad/.test(navigator.platform) ||
                        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || windowWidth <= 1024) ?
                        <FabChildButton icon="far fa-arrow-alt-circle-down" className={this.state.changeCss ? 'change-css-scroll' : 'change-css-scroll-off'} onClick={this.onChangeCssScroll}></FabChildButton>  : ''
                      }
                    </FabMenu>
                  ])

                )
              }
              {/* <AnimationCommentsPane comments={comments} onCommentDeletionCalled={this.onCommentDeletionCalled.bind(this)} currentUserId={currentUserId} role={role} owner={owner}/> */}
            </div>
          </div>

          {
            shouldShowAd && (
              <div className="adsence bottom">
                <AdSense.Google
                  client={adsenceConfig.client}
                  slot={adsenceConfig.slot.large_big_banner}
                  style={{ display: "block" }}
                  layout="in-article"
                  format="auto"
                />
              </div>
            )
          }
          <AnimationNotify/>
          {
            ['動画', 'video'].includes(this.props.project_type) ? (
            currentAnimation && !currentAnimation.is_video_embed && !!currentAnimation.file_object.url || currentAnimation && currentAnimation.is_video_embed ? (
              <Button className="d-none" id="button-public-url" onClick={this.open.bind(this, 'PublicUrlModal')}>
              </Button>
            )
             : (
              <></>
            )
          ) : ''}
          <AnimationGetUrlModal isOpen={shouldShowGetUrlModal} onClickClose={this.close.bind(this, 'GetUrlModal')} token={token} rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id} onStart={this.startLoading.bind(this, 'Now uploading...')} baseURL={this.baseURL} checkAnimationLastPage={this.checkAnimationLastPage} taskID={id} firstUploadAkaire={this.OpenModalUploadFirstAkaire} isAkaponProject={isAkaponProject}/>
          <ProjectShareModal changeIsShowShareInputPassUrl={this.changeIsShowShareInputPassUrl} isTaskType={currentTicketId == 'task'} mobile={false} isOpen={shouldShowProjectShareModal} publicToken={publicToken} assignments={assignments} onClickClose={this.closeModalShareUrl} projectId={id} currentUserId={currentUserId} role={role} onInvite={this.onChangeAssignments} onChangeRole={this.onChangeAssignments} onRemove={this.onChangeAssignments} user={user} onGenerateLink={this.onGenerateLink} onDeleteLink={this.onDeleteLink} rootUrl={rootUrl} apiClient={this.apiClient} owner={owner}
          onChangeSuperInviter={this.onChangeAssignments} token={token} currentPageId={currentPageId}
          isDisabledButtonSavePassUrl={isDisabledButtonSavePassUrl}
          passwordShareUrl={passwordShareUrl} checkPassWordUrl={this.checkPassWordUrl}
          isAkaponMember={isAkaponMember} is_project_owner_member={is_project_owner_member}
          is_create_pass_video={is_create_pass_video} isShowInputPassUrl={isShowInputPassUrl}
          setPasswordForShareUrl={this.setPasswordForShareUrl}
          changeFocusUrl={this.changeFocusUrl} isShowInputDemoUrl={isShowInputDemoUrl} />

          <GetUrlYoutube isOpen={shouldShowGetUrlYoutube} checkAnimationLastPage={this.checkAnimationLastPage}
            onClickClose={this.close.bind(this, 'GetUrlYoutube')} token={token}
            rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id}
            onStart={this.startLoading.bind(this, 'Now uploading...')}
            baseURL={this.baseURL}
            fetchProject={this.fetchProject.bind(this)}
            getAnimationAdded={this.getAnimationAdded.bind(this)}
            is_create_video_embed={is_create_video_embed}
            firstUploadAkaire={this.OpenModalUploadFirstAkaire}
          />
          <ProjectFormModal isOpen={shouldShowProjectFormModal} onClickClose={this.close.bind(this, 'ProjectFormModal')} role={role} project={project} onSubmit={this.onSubmitProjectForm} />
          <AnimationUploaderModal convertVideo={this.convertVideo} isOpen={shouldShowAnimationUploaderModal} onClickClose={this.close.bind(this, 'AnimationUploaderModal')} onStart={this.startUploadVideo} onFinish={['動画', 'video'].includes(this.props.project_type) ? (this.onFinishUpload) : (this.onFinishUploadImage)} projectId={id} baseURL={this.baseURL} completed={completed} convertFile={this.convertFile} statusConvert={this.finishConvert} finishConvert={this.state.finishConvert} endLoading={this.endLoading} type={this.props.project_type} convertPdf={this.convertPdf} user={user}
          oneGb={ONE_GB_TO_BYTES} currentLimitVideo={currentLimitVideo} uploadedDataInfo={uploadedDataInfo}
          onClickInputFile={this.onClickInputFile} is_mobile={false} isUploadMultipleFiles={false} />
          <CompletionModal isOpen={shouldShowCompletionModal} onClickClose={this.close.bind(this, 'CompletionModal')} onSubmit={this.onSubmitCompletionModal} ad={isFreePlan} currentAnimation={currentAnimation} type={this.props.project_type} />
          <PublicUrlModal isOpen={shouldShowPublicUrlModal} onClickClose={this.closeModalShareVideo} ad={isFreePlan} currentAnimation={currentAnimation} type={this.props.project_type} token={token} public_video_token={public_video_token} is_create_pass_video={is_create_pass_video} isShowInputPassVideo={isShowInputPassVideo}
          changeIsShowInputVideo={this.changeIsShowInputVideo} setPasswordForShareVideo={this.setPasswordForShareVideo}
          passwordShareVideo={passwordShareVideo} checkPassWord={this.checkPassWord}
          isDisabledButtonSavePass={isDisabledButtonSavePass} isAkaponMember={isAkaponMember}
          is_project_owner_member={is_project_owner_member}
          isShowInputDemo={isShowInputDemo} changeFocus={this.changeFocus} currentPage={pageCurrent} project={project} />
          { pages.length > 0 && animations.length > 0 && <ModalDownloadImage isOpen={isShowModalDownloadImage}
            changeTypeDownLoad={this.changeTypeDownLoad} selectTypeDownload={selectTypeDownload}
            downloadImage={this.downloadImage}
            animations={animations} pages={pages} currentPageId={currentPageId} currentAnimationId={currentAnimationId}
            role={role} baseURL={baseURL} fetchListAnimationsDownload={this.fetchListAnimationsDownload}
           />
          }
          { pages.length > 0 && animations.length > 0 &&
            <ModalDownloadVideo isOpen={isShowModalDownloadVideo}
              changeTypeDownLoad={this.changeTypeDownLoadVideo} selectTypeDownload={selectTypeDownloadVideo}
              downloadVideo={this.downloadVideo}
              animations={animations} pages={pages} currentPageId={currentPageId} currentAnimationId={currentAnimationId}
              role={role} baseURL={baseURL} fetchListAnimationsDownload={this.fetchListAnimationsDownload}
            />
          }
        </Loadable>
        <ModalFirstUploadAkaire isOpen={isOpenModalUploadFirstAkaire} OpenModalUploadFirstAkaire={this.OpenModalUploadFirstAkaire} settingMember={this.settingMember} />
        <ModalChangeCommentList
          isOpen={modalChangeCommentListIsOpenIsOpen}
          togglePopup={this.togglePopup}
          disabledNotiCompareVersion={this.disabledNotiCompareVersion}
          text={modalChangeCommentListText}
          currentAnimation={modalChangeCommentListCurrentAnimation}
          disabledNotiComparePage={this.disabledNotiComparePage}
          kind={modalChangeCommentListKind}
        >
        </ModalChangeCommentList>
        <ModalConfirmSendMailNotLogin
          isOpen={modalConfirmSendMailLogin}
          togglePopup={this.togleModalConfirmSendMailNotLogin}
          sendMailNotLogin={this.sendMailNotLogin}
        ></ModalConfirmSendMailNotLogin>
        <ModalInputInfoOfNotLogin
          saveInfoOfNotLogin={this.setEmailTepAndUserTempNotLogin}
          isOpen={showModalInputInfoOfNotLogin}
          togglePopup={this.toggleModalInputInfoOfNotLogin}
          userNameTempOfNotLogin={userNameTempOfNotLogin}
          emailTempOfNotLogin={emailTempOfNotLogin}
          changeEmailTempOfNotLogin={this.changeEmailTempOfNotLogin}
          changeUserNameTempOfNotLogin={this.changeUserNameTempOfNotLogin}
        ></ModalInputInfoOfNotLogin>
        <ModalShowCreateCommentNotLogin
          isOpen={isShowModalCreateCommentNotLogin}
          isNotShowCreateCommentNotLogin={isNotShowCreateCommentNotLogin}
          changeNotShowModalCreateCommentNotLogin={this.changeNotShowModalCreateCommentNotLogin}
          togglePopup={this.toggleModalCreateCommentNotLogin}
        ></ModalShowCreateCommentNotLogin>
      </div>
    )
  }

  ticketsSection(){
    const { tickets, currentTicketId } = this.state;
    const { role } = this.props;

    return (
      <Input type="select" onChange={this.onChangeTicket} value={currentTicketId} className="mb-3" disabled={canUserNotPermission(role)}>
        { <option value='task'>タスク管理</option> }
        {
          tickets.map((ticket) => {
            return (
              <option value={ticket[0]}>
                {ticket[1]}
              </option>
            );
          })
        }
      </Input>
    )
  }

  downloadCSV = (currentAnimationId, projectId, taskId, currentTicketId) => {
    let url = `/akaire_feature/akaire_files/${taskId}/animations/${currentAnimationId}/animation_markers.csv`;
    window.open(url, '_blank');
  }

  setPeriodRangeWhenClickTimeLine = (periodRange) => {
    this.setState({ showPeriodRange: periodRange })
  }

  renderMobile() {
    const { current_user_id: currentUserId, role, id, root_url: rootUrl, statuses, env, adsence_config: adsenceConfig, is_free_plan: isFreePlan, windowWidth, owner, project_type, current_subscription_type: currentSubscriptionType,
    currentLimitVideo: currentLimitVideo, can_use_page_management: canUsePageManagement, extensions, project_id, port,
    token_public_url, category, is_akapon_member: isAkaponMember, is_project_owner_member, isAkaponProject, baseURL
    } = this.props;
    const {
      loadingText, isLoading, animations, markers, comments, assignments,
      shouldShowGetUrlModal, shouldShowProjectShareModal, shouldShowProjectFormModal,
      shouldShowAnimationUploaderModal, completed, membersInComment,
      shouldShowCompletionModal, shouldShowPublicUrlModal, currentAnimationId, user, project, currentPageId,
      activeTab, pages, readMoreText, readMoreStyle, uploadedDataInfo, tickets, scaleImage, currentTicketId, onChangeScaleImage, shouldShowAd, shouldShowGetUrlYoutube, is_show_draw_component, animationSelectCurrent,
      is_create_video_embed, isShowDrawComponent, is_capture_video_youtube, isShowModalDownloadImage,
      selectTypeDownload, isShowModalDownloadVideo, selectTypeDownloadVideo, is_create_pass_video, isShowInputPassVideo,
      passwordShareVideo, isDisabledButtonSavePass, isDisabledButtonSavePassUrl, passwordShareUrl,
      isShowInputPassUrl, isShowInputDemo, isShowInputDemoUrl, page_last_animation, isOpenModalUploadFirstAkaire, modalChangeCommentListIsOpenIsOpen, modalChangeCommentListText, modalChangeCommentListCurrentAnimation, modalChangeCommentListKind,
      activeTabMarkerMobileImage, isShowMobileMarkerImage, isDisabledButtonSendMailNotLogin, modalConfirmSendMailLogin,
      showModalInputInfoOfNotLogin, userNameTempOfNotLogin, emailTempOfNotLogin, isInputInfoMationOfNotLogin,
      isShowModalCreateCommentNotLogin, isNotShowCreateCommentNotLogin, periodRange
    } = this.state;
    const { name, description, url_token: publicToken } = project;
    const currentAnimation = this.currentAnimation();
    const src = currentAnimation && currentAnimation.is_video_embed ? currentAnimation.url : currentAnimation && currentAnimation.file_object.url;
    const downloadUrl = this.currentDownloadExtensionUrl()
    const token = token_public_url;
    const public_video_token = currentAnimation && currentAnimation.url_token;
    // const shouldShowAd = env === 'production' && (currentUserId == null || isFreePlan);
    let heightTopToAnimation = fetchHeightHeader();
    const pageCurrent = this.currentPage();
    const classOfChangeVersion = currentAnimation && currentAnimation.is_video_embed && currentUserId ? 'col-4' : 'col-8'
    const currentUrlFile = currentAnimation?.file_object?.url?.split('?')[0]?.split('.')
    const extensionFile = currentAnimation && !currentAnimation.is_video_embed &&
                            currentUrlFile[currentUrlFile.length - 1]?.toUpperCase();

    const isVideo = ['動画', 'video'].includes(project_type)
    return (
      <div className="animation-inspector mobile">
        <Loadable
          className="loading-overlay"
          active={isLoading}
          text={loadingText || 'Now loading...'}
          zIndex={10000}
        >
          {/* TODO: Loadableがうまくうごかなくなったので暫定対応 あとでなんとかする */}
          <div></div>

          <div className="l-container u-bg--darkGray py-3 mt-2 mt-sm-0">
            <div className="l-main">
              <div className="pane-wrapper">
                {
                  ['動画', 'video'].includes(this.props.project_type) ? (
                  //block-start-1
                    currentAnimation ? (
                      <div className="top-pane">
                        <div className={`d-flex page-control page-control-mobile justify-content-center ${gon.is_login ? '' : 'not-login'}`}>
                          { canUsePageManagement ? (
                            <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                              currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                              pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                              onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={true}
                              onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                              currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                              role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                              animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                          ) : ''}

                          <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                            toggleModeDrawComponent={this.toggleModeDrawComponent}
                            isShowDrawComponent={isShowDrawComponent}
                            assignments={assignments} markers={markers}
                            onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                            rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                            scaleImage={scaleImage}
                            handleSetDraw={this.handleSetDraw}
                            onClickDraw={this.handleDraw}
                            onClickEraser={this.handleEraser}
                            onClickReset={this.handleReset}
                            onClickCapture={this.handleCapture}
                            onClickEraserAll={this.handleEraserAll}
                            role={role}
                            changeShowDrawComponent={this.changeShowDrawComponent}
                            mobile={true} category={category}
                            currentAnimation={currentAnimation}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          />
                        </div>

                        {
                          !currentAnimation.is_video_embed && !!currentAnimation.file_object.url ? (
                          <AnimationPlayer role={role} settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}} src={src} onClickDownloadButton={this.onClickDownloadButton} downloadUrl={downloadUrl} onSubmitMarkerForm={this.onSubmitMarkerForm} markers={markers} ref={ref => this.animationPlayer = ref} currentUserId={currentUserId} markerCreatable={canCreateAnimationMarker(role)} onMarkerUpdationCalled={this.updateMarker.bind(this)} updateMakerShow={this.updateMakerShow.bind(this)} mobile={true}
                          scaleImage={scaleImage} currentAnimationId={currentAnimationId}
                          animationSelectCurrent={animationSelectCurrent}
                          setCurrentAnimation={this.setCurrentAnimation} heightTopToAnimation={heightTopToAnimation}
                          currentAnimation={currentAnimation}
                          onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                          selectedMarker={this.state.selectedMarker}
                          updateSelectedMarker={this.updateSelectedMarker}
                          reloadMarkers={this.fetchMarkers.bind(this)}
                          apiClient={this.apiClient}
                          deleteReplyMarker={this.deleteReplyMarker}
                          avatarUrl={this.state.avatarUrl}
                          toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                          isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          isShowDrawComponent={isShowDrawComponent}
                          updateIsOpenNouislider={this.updateIsOpenNouislider}
                          closeNouislider={this.closeNouislider}
                          isOpenNouislider={this.state.isOpenNouislider}
                          showPeriodRangeProp={this.state.showPeriodRange}
                          />
                        ) : currentAnimation && currentAnimation.url ? (
                          <ShowEmbedVideo
                            src={src} onClickDownloadButton={this.onClickDownloadButton} downloadUrl={downloadUrl} onSubmitMarkerForm={this.onSubmitMarkerForm} markers={markers} ref={ref => this.animationPlayer = ref} markerCreatable={canCreateAnimationMarker(role)} currentUserId={currentUserId} canCreateAnimation={!canCreateAnimation(role, animations)} onMarkerUpdationCalled={this.updateMarker.bind(this)} updateMakerShow={this.updateMakerShow.bind(this)} getHeightAnimaiton={this.getHeightAnimaiton.bind(this)} mobile={true} role={role} owner={owner} user={user}
                            settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}}
                            currentAnimation={currentAnimation}
                            scaleImage={scaleImage}
                            baseURL={this.baseURL}
                            is_show_draw_component={is_show_draw_component}
                            currentAnimationId={currentAnimationId}
                            animationSelectCurrent={animationSelectCurrent}
                            setCurrentAnimation={this.setCurrentAnimation}
                            heightTopToAnimation={heightTopToAnimation}
                            changeIsCaptureVideoYoutube={this.changeIsCaptureVideoYoutube}
                            is_capture_video_youtube={is_capture_video_youtube}
                            onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                            selectedMarker={this.state.selectedMarker}
                            updateSelectedMarker={this.updateSelectedMarker}
                            reloadMarkers={this.fetchMarkers.bind(this)}
                            apiClient={this.apiClient}
                            deleteReplyMarker={this.deleteReplyMarker}
                            avatarUrl={this.state.avatarUrl}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                            isShowDrawComponent={isShowDrawComponent}
                            updateIsOpenNouislider={this.updateIsOpenNouislider}
                            closeNouislider={this.closeNouislider}
                            isOpenNouislider={this.state.isOpenNouislider}
                            showPeriodRangeProp={this.state.showPeriodRange}
                          />
                        ) : (
                          <div className="top-pane">
                          <div className="no-animations">
                            <div className="content d-flex flex-column justify-content-center align-items-center">
                              <div>
                                新しいバージョンが更新された為、
                                <br />
                                {currentAnimation.title}バージョンのURLは削除されました。
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                        }
                        {
                          platform.product === 'iPhone' ?
                            <p className="mobile-warning d-none my-1">iPhoneの場合は再生中に赤入れできません。</p> :
                            ''
                        }
                        <div className={`bottom-pane for-mobile-akaire-marker-pane ${ isVideo ? 'for-video' : 'for-image' }`}>
                          <Nav tabs className={ isVideo ? '' : 'd-none' }>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === 'comments-timeline' })}
                                onClick={this.toggleTab.bind(this, 'comments-timeline')}
                              >
                                タイムライン
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === 'markers' })}
                                onClick={this.toggleTab.bind(this, 'markers')}
                              >
                                コメントツール
                              </NavLink>
                            </NavItem>
                            <NavItem className='text-cancel-animation'>
                              {
                                !canUserNotPermission(role) && currentAnimation && role !== 'reader' ? (
                                  currentAnimation.final ? (
                                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickCancelFinalAnimation} href="javascript:void(0);">最終を解除</a>
                                  ) : role != 'public_visitor' ? (
                                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickFinalizeAnimation} href="javascript:void(0);">最終稿にする</a>
                                  ) : ''
                                ) : ''
                              }

                              {
                                canHideCommentsFromCollaborators(role) && currentAnimation ? (
                                  currentAnimation?.additional_info['hide_marker_to_collaborators'] ? (
                                    <a className="text-light ml-2" style={{ textDecoration: 'underline' }} onClick={this.onClickToggleMarkersFromCollaborator} href="javascript:void(0);">全員表示へ切替</a>
                                  ) : role != 'public_visitor' ? (
                                    <a className="text-light ml-2" style={{ textDecoration: 'underline' }} onClick={this.onClickToggleMarkersFromCollaborator} href="javascript:void(0);">チームのみ表示</a>
                                  ) : ''
                                ) : ''
                              }
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="comments-timeline" className={"comments-timeline-container"}>
                              <AnimationMarkerLine markers={markers}
                                player={this.animationPlayer}
                                isShowMobile={true}
                                isEmbedVideo={true}
                                order={10}
                                isShowDrawComponent={isShowDrawComponent}
                                setPeriodRangeWhenClickTimeLine={this.setPeriodRangeWhenClickTimeLine}
                              />
                              <AnimationMarkerPeriod
                                player={this.animationPlayer}
                                isEmbedVideo={true}
                                isOpenNouislider={this.state.isOpenNouislider}
                                updateIsOpenNouislider={this.updateIsOpenNouislider}
                                closeNouislider={this.closeNouislider}
                                openPeriodCommentBox={this.animationPlayer?.openPeriod}
                                showPeriodRange={this.state.showPeriodRange}
                              ></AnimationMarkerPeriod>
                              <div id="slider-scale-box" className="container-scale-box">
                                <p className="title-scale-line">拡大縮小</p>
                                <div className='slider-scale-box' style={{position: "relative"}}>
                                  <Range
                                    containerClass={"ml-3"}
                                    className={"ml-1 col-12"}
                                    value={scaleImage}
                                    step={5}
                                    handleChange={(flag)=>this.onChangeScaleImage(flag)}
                                    isShowDrawComponent={isShowDrawComponent}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="markers">
                              <AnimationMarkersPane page_last_animation={page_last_animation} onClickDownloadImageButton={this.onClickDownloadImageButton} isAkaponMember={isAkaponMember} projectId={project_id} membersInComment={membersInComment} env={env} taskId={id} reloadMarkers={this.fetchMarkers.bind(this)} isStillAvailableStorage={this.isStillAvailableStorage.bind(this)} assignments={assignments} onChangeTicket={this.onChangeTicket} currentTicketId={currentTicketId} tickets={tickets} markers={markers} onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)} onMarkerUpdationCalled={this.onMarkerUpdationCalled.bind(this)} onClickMarker={this.onClickMarker.bind(this)} currentUserId={currentUserId} role={role} shouldShowAd={shouldShowAd} disabled={!currentAnimation} mobile={true} project_type={project_type} currentSubscriptionType={currentSubscriptionType} apiClient={this.apiClient} currentAnimationId={currentAnimationId} canUsePageManagement={canUsePageManagement} pages={pages}
                              onChangePage={this.onChangePage} currentPageId={currentPageId} currentAnimationTotalSize={this.getTotalSizeAnimation()} onSubmit={this.updatePage.bind(this)} deletePage={this.deletePage} onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                              project={this.state.project}
                              is_video_embed={currentAnimation && currentAnimation.is_video_embed}
                              getUrlYoutube={this.open.bind(this, 'GetUrlYoutube')}
                              animations={this.state.animations}
                              onChangeCommentVersion={this.onChangeCommentVersion}
                              animationSelectCurrent={animationSelectCurrent}
                              setCurrentAnimation={this.setCurrentAnimation}
                              selectedMarker={this.state.selectedMarker}
                              updateSelectedMarker={this.updateSelectedMarker}
                              deleteReplyMarker={this.deleteReplyMarker}
                              avatarUrl={this.state.avatarUrl}
                              isDisabledButtonSendMailNotLogin={isDisabledButtonSendMailNotLogin}
                              showPopupConfirmSendMailNotLogin={this.showPopupConfirmSendMailNotLogin}
                              isShowDrawComponent={isShowDrawComponent}
                              currentAnimation={currentAnimation}
                              onStart={this.startLoading.bind(this, 'Now Processing...')}
                              onFinish={this.endLoading}
                              />
                            </TabPane>
                            <TabPane tabId="comments">
                              {
                                (!currentUserId || !isAkaponMember) &&
                                <div className="ticket-name w-100">
                                  <p className="text-white mt-2 mb-1">
                                    赤入れ保存場所&nbsp;&nbsp;&nbsp;
                                    {
                                      currentTicketId != 'task' &&
                                      <a href={canUserNotPermission(role) ? 'javascript:void(0)' : btnLoadTicketPage(env, rootUrl, port, currentTicketId != 'task', project_id, id, currentTicketId)}
                                        className="link-load-ticket-page">(このページに移動)
                                      </a>
                                    }
                                  </p>
                                  { this.ticketsSection() }
                                </div>
                              }

                              {/* {renderingAnimationPage} */}
                              <div className="project-meta clearfix">
                                <div>
                                  {
                                    canUseUrlFeature(role,currentSubscriptionType) && currentAnimation && currentAnimation.url &&
                                    <AnimationUrl currentAnimationUrl={currentAnimation.url} isMobile={true}/>
                                  }
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                  <div className={!extensionFile ? 'd-flex justify-content-center align-items-center' : 'col-3'}>
                                    <span className="text-white form-title mr-3">Ver.</span>
                                    <Input type="select" id="animation-selector" value={currentAnimationId || ''} onChange={this.onChangeAnimationSelect} className={!extensionFile ? classOfChangeVersion + ' mr-2' : 'mr-2'} disabled={!canCreateAnimation(role, animations)}>
                                      {
                                        animations.map((animation, i) => {
                                          return (
                                            <option value={animation.id} key={i}>
                                              {version(animation)}
                                            </option>
                                          );
                                        })
                                      }
                                    </Input>
                                    { currentAnimation && currentAnimation.is_video_embed && currentUserId &&  <Range
                                      className='col-6 ml-auto text-right'
                                      value={scaleImage}
                                      step={5}
                                      handleChange={(flag)=>this.onChangeScaleImage(flag)}
                                      isShowDrawComponent={isShowDrawComponent}
                                    />
                                    }
                                  </div>

                                  <div className={`col-3 text-white ${!extensionFile && 'd-none'}`}>
                                    <span className='ml-2'></span>
                                    <Input type="text" value={extensionFile} disabled={true} id="animation-selector" className="text-center ml-xl-2">
                                    </Input>
                                  </div>

                                  {/* { currentAnimation && !currentAnimation.is_video_embed && currentUserId && <div className="selector-extension m-0 float-none d-flex text-left justify-content-center align-items-center" data-tip data-for="sign-up-promotion-tooltip">
                                    <span className="text-nowrap mr-2">形式</span>
                                    <Input type="select" id="animation-selector-extension" disabled={canUserNotPermission(role)} onChange={this.onChangeAnimationExtension} value={this.selectedAnimationExtension()}>
                                      {
                                        extensions.map((ext) => {
                                          return (
                                            <option value={ext} key={ext}>
                                              {ext}
                                            </option>
                                          );
                                        })
                                      }
                                    </Input>
                                  </div> } */}
                                  {currentAnimation && !currentAnimation.is_video_embed && currentUserId && <div className="row mx-2 mt-auto">
                                    <Range
                                      className='col ml-auto text-right'
                                      value={scaleImage}
                                      step={5}
                                      handleChange={(flag)=>this.onChangeScaleImage(flag)}
                                      isShowDrawComponent={isShowDrawComponent}
                                    />
                                  </div>}
                                </div>

                                <div className="animation-inspector mt-3">
                                  <div className="animation-buttons">

                                    <div className="item-akapon">
                                      <Button className="btn-download-for-mobile" onClick={this.onClickDownloadButton} disabled={canUserNotPermission(role)}>
                                        <p>動画</p>
                                        <span className="app-icon icon-download"></span>
                                      </Button>
                                    </div>

                                    <div className="item-akapon">
                                      <Button className="btn-download-csv" onClick={() => this.downloadCSV(currentAnimationId, project_id, id, currentTicketId)} download disabled={canUserNotPermission(role)}>
                                        <p>コメント</p>
                                        <span className="app-icon icon-download"></span>
                                      </Button>
                                    </div>

                                    <div className="item-akapon" data-tip data-for="sign-up-promotion-tooltip">
                                      <Button className="btn-delete-animation" onClick={this.deleteAnimation} disabled={!canDeleteAnimation(role)} >
                                        <span className="u-fontColor--red">
                                          <p>バージョン</p>
                                        </span>
                                        <span style={{fontSize: '14px'}} className="fas fa-trash-alt"></span>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="detail d-none">
                              <div className="animation-inspector mt-3">
                                <div className="top-pane">
                                  <div className="control-button">
                                    <div className="control-button-top">

                                      <div className="btn-upload button" data-tip data-for="sign-up-promotion-tooltip">
                                        <Button className="button-upload" onClick={this.open.bind(this, 'AnimationUploaderModal')} disabled={!canCreateAnimation(role, animations)}>
                                          <img src={iconUpload} className='icon-btn-upload' />
                                          <p className='white-p break-work-button'>動画アップロード</p>
                                        </Button>
                                      </div>
                                      { gon.is_login ? (
                                        <div className="btn-donwload button" data-tip data-for="sign-up-promotion-tooltip" style={{display: "none"}}>
                                          <Button className="button-auto-comment" onClick={this.onClickAutoCommentButton} disabled={!canCreateComment(role)}>
                                            <img src={iconPen} style={{transform: 'scale(1.2)'}} />
                                            <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                                          </Button>
                                        </div>
                                      ) : (
                                        <div className="btn-donwload button" data-tip data-for="sign-up-promotion-tooltip" style={{display: "none"}}>
                                          <Button disabled={isDisabledButtonSendMailNotLogin || isShowDrawComponent}
                                                  onClick={this.showPopupConfirmSendMailNotLogin} className="button-auto-comment">
                                            <img src={iconPen} style={{transform: 'scale(2.2)'}} />
                                            <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="control-button-bottom">
                                      <div className="btn-url button" data-tip data-for="sign-up-promotion-tooltip">
                                        <Button className="button-url" disabled={!canCreateAnimation(role, animations)} onClick={this.open.bind(this, 'GetUrlYoutube')}>
                                          <img src={iconGlobal} />
                                          <p className='white-p break-work-button'>URL入力</p>
                                        </Button>
                                      </div>

                                      <div className="btn-share-url button" data-tip data-for="">
                                        <Button className="btn-share-url" disabled={canUserNotPermission(role)} onClick={this.open.bind(this, 'ProjectShareModal')}>
                                          <img src={iconLink} />
                                          <p className='white-p break-work-button'>赤入れ共有URL</p>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>

                      </div>
                    ) : (
                      <div className="top-pane">
                        <div className={`d-flex page-control page-control-mobile justify-content-center ${gon.is_login ? '' : 'not-login'}`}>
                          { canUsePageManagement ? (
                            <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                              currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                              pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                              onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={true}
                              onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                              currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                              role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                              animations={animations} page_last_animation={page_last_animation} projectType={'Video'} />
                          ) : ''}

                          <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                            toggleModeDrawComponent={this.toggleModeDrawComponent}
                            isShowDrawComponent={isShowDrawComponent}
                            assignments={assignments} markers={markers}
                            onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                            rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                            scaleImage={scaleImage}
                            handleSetDraw={this.handleSetDraw}
                            onClickDraw={this.handleDraw}
                            onClickEraser={this.handleEraser}
                            onClickReset={this.handleReset}
                            onClickCapture={this.handleCapture}
                            onClickEraserAll={this.handleEraserAll}
                            role={role}
                            changeShowDrawComponent={this.changeShowDrawComponent}
                            mobile={true} category={category}
                            currentAnimation={currentAnimation}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          />
                        </div>
                        <div className="no-animations">
                          <div className="content d-flex flex-column justify-content-center align-items-center">
                            <img className="create-akaire-box-icon-upload"
                              src={NoAnimationPreview} alt="アップロード"/>
                          </div>
                        </div>
                        <div className={`animation-file-size ${ isVideo ? '' : 'd-none'} `}>
                          <div className="dt position-text-top-1">
                            タスク名：
                            <span className='text-light text-truncate position-text-top'>
                              { name }
                            </span>
                          </div>
                          <div className="dt">
                            ファイル容量：
                            <span className='text-light'>
                              {bytes(this.getTotalSizeAnimation())}
                            </span>
                          </div>
                        </div>
                        {
                          (!currentUserId || !isAkaponMember) &&
                          <div className="ticket-name w-100">
                            <p className="text-white mt-2 mb-1">
                              赤入れ保存場所&nbsp;&nbsp;&nbsp;
                              {
                                currentTicketId != 'task' &&
                                <a href={canUserNotPermission(role) ? 'javascript:void(0)' : btnLoadTicketPage(env, rootUrl, port, currentTicketId != 'task', project_id, id, currentTicketId)}
                                  className="link-load-ticket-page">(このページに移動)
                                </a>
                              }
                            </p>
                            { this.ticketsSection() }
                          </div>
                        }
                        {/* {renderingAnimationPage} */}
                      </div>
                    )
                    //end-block-1
                  ) : (
                    //start-block-2
                    currentAnimation && !!currentAnimation.file_object.url ? (
                      <div className="top-pane">
                        <div className={`d-flex page-control page-control-mobile justify-content-center ${gon.is_login ? '' : 'not-login'}`}>
                          { canUsePageManagement ? (
                            <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                              currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                              pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                              onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={true}
                              onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                              currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                              role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                              animations={animations} page_last_animation={page_last_animation} projectType={'Web'} />
                          ) : ''}

                          <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                            toggleModeDrawComponent={this.toggleModeDrawComponent}
                            isShowDrawComponent={isShowDrawComponent}
                            assignments={assignments} markers={markers}
                            onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                            rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                            scaleImage={scaleImage}
                            handleSetDraw={this.handleSetDraw}
                            onClickDraw={this.handleDraw}
                            onClickEraser={this.handleEraser}
                            onClickReset={this.handleReset}
                            onClickCapture={this.handleCapture}
                            onClickEraserAll={this.handleEraserAll}
                            role={role}
                            changeShowDrawComponent={this.changeShowDrawComponent}
                            mobile={true} category={category}
                            currentAnimation={currentAnimation}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          />
                        </div>
                        {<AnimationImage scaleImage={scaleImage} changeCss={this.state.changeCss}
                            src={src} onClickDownloadButton={this.onClickDownloadButton}
                            downloadUrl={downloadUrl} onSubmitMarkerForm={this.onSubmitMarkerForm}
                            markers={markers} ref={ref => this.animationImage = ref}
                            markerCreatable={canCreateAnimationMarker(role)}
                            role={role}
                            onMarkerUpdationCalled={this.updateMarker.bind(this)}
                            updateMakerShow={this.updateMakerShow.bind(this)} mobile={true}
                            getHeightAnimaiton={this.getHeightAnimaiton.bind(this)}
                            settingDraw={{color: this.state.penColor , lineWidth: this.state.lineWidth}}
                            currentAnimationId={currentAnimationId}
                            animationSelectCurrent={animationSelectCurrent}
                            currentAnimation={currentAnimation}
                            setCurrentAnimation={this.setCurrentAnimation}
                            heightTopToAnimation={heightTopToAnimation}
                            selectedMarker={this.state.selectedMarker}
                            updateSelectedMarker={this.updateSelectedMarker}
                            onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)}
                            reloadMarkers={this.fetchMarkers.bind(this)}
                            apiClient={this.apiClient}
                            deleteReplyMarker={this.deleteReplyMarker}
                            avatarUrl={this.state.avatarUrl}
                            currentUserId={currentUserId}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                           />}
                        <div className={`bottom-pane for-mobile-akaire-marker-pane ${ isVideo ? 'for-video' : 'for-image' }`}>
                          {
                            !isVideo &&
                            <TabContent activeTab={activeTabMarkerMobileImage}>
                              <TabPane tabId="markers-image-mobile" className='markers-image-mobile'>
                                <AnimationMarkersPane page_last_animation={page_last_animation} onClickDownloadImageButton={this.onClickDownloadImageButton} isAkaponMember={isAkaponMember} projectId={project_id} membersInComment={membersInComment} env={env} taskId={id} reloadMarkers={this.fetchMarkers.bind(this)} isStillAvailableStorage={this.isStillAvailableStorage.bind(this)} assignments={assignments} onChangeTicket={this.onChangeTicket} currentTicketId={currentTicketId} tickets={tickets} markers={markers} onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)} onMarkerUpdationCalled={this.onMarkerUpdationCalled.bind(this)} onClickMarker={this.onClickMarker.bind(this)} currentUserId={currentUserId} role={role} shouldShowAd={shouldShowAd} disabled={!currentAnimation} mobile={true} project_type={project_type} currentSubscriptionType={currentSubscriptionType} apiClient={this.apiClient} currentAnimationId={currentAnimationId} canUsePageManagement={canUsePageManagement} pages={pages}
                                onChangePage={this.onChangePage} currentPageId={currentPageId} currentAnimationTotalSize={this.getTotalSizeAnimation()} onSubmit={this.updatePage.bind(this)} deletePage={this.deletePage} onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                                project={this.state.project}
                                is_video_embed={currentAnimation && currentAnimation.is_video_embed}
                                getUrlYoutube={this.open.bind(this, 'GetUrlYoutube')}
                                animations={this.state.animations}
                                onChangeCommentVersion={this.onChangeCommentVersion}
                                animationSelectCurrent={animationSelectCurrent}
                                setCurrentAnimation={this.setCurrentAnimation}
                                selectedMarker={this.state.selectedMarker}
                                updateSelectedMarker={this.updateSelectedMarker}
                                deleteReplyMarker={this.deleteReplyMarker}
                                avatarUrl={this.state.avatarUrl}
                                isDisabledButtonSendMailNotLogin={isDisabledButtonSendMailNotLogin}
                                showPopupConfirmSendMailNotLogin={this.showPopupConfirmSendMailNotLogin}
                                isShowDrawComponent={isShowDrawComponent}
                                currentAnimation={currentAnimation}
                                onStart={this.startLoading.bind(this, 'Now Processing...')}
                                onFinish={this.endLoading}
                                />
                              </TabPane>
                            </TabContent>
                          }
                          <div className={`animation-file-size ${ isVideo ? '' : 'd-none'} `}>
                            <div className="dt position-text-top-1">
                              タスク名：
                              <span className='text-light text-truncate position-text-top'>
                                { name }
                              </span>
                            </div>
                            <div className="dt">
                              ファイル容量：
                              <span className='text-light'>
                                {bytes(this.getTotalSizeAnimation())}
                              </span>
                            </div>
                          </div>
                          <Nav tabs className={ isVideo ? '' : 'd-none' }>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === 'comments-timeline' })}
                                onClick={this.toggleTab.bind(this, 'comments-timeline')}
                              >
                                タイムライン
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === 'markers' })}
                                onClick={this.toggleTab.bind(this, 'markers')}
                              >
                                コメントツール
                              </NavLink>
                            </NavItem>
                            <NavItem className='text-cancel-animation'>
                              {
                                !canUserNotPermission(role) && currentAnimation ? (
                                  currentAnimation.final ? (
                                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickCancelFinalAnimation} href="javascript:void(0);">バージョンの最終稿を解除する</a>
                                  ) : role != 'public_visitor' ? (
                                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickFinalizeAnimation} href="javascript:void(0);">表示中のバージョンを最終稿にする</a>
                                  ) : ''
                                ) : ''
                              }
                            </NavItem>
                          </Nav>
                          {
                            !isVideo &&
                            (
                              <div className='show-for-image-markers-sp'>
                                <div className='text-right btn-toggle-collaborator-comment-sp-image'>
                                  {
                                      canHideCommentsFromCollaborators(role) && currentAnimation ? (
                                        currentAnimation?.additional_info['hide_marker_to_collaborators'] ? (
                                          <a className="text-light ml-2" style={{ textDecoration: 'underline' }} onClick={this.onClickToggleMarkersFromCollaborator} href="javascript:void(0);">全員表示へ切替</a>
                                        ) : role != 'public_visitor' ? (
                                          <a className="text-light ml-2" style={{ textDecoration: 'underline' }} onClick={this.onClickToggleMarkersFromCollaborator} href="javascript:void(0);">チームのみ表示</a>
                                        ) : ''
                                      ) : ''
                                  }
                                </div>
                                <div className='text-center show-hide-markers-image-mobile d-flex justify-content-center'
                                     onClick={this.showHideTabMobileMarker}>
                                  <span>コメントツール</span>
                                  {
                                    isShowMobileMarkerImage ? (
                                      (<span className='ml-2'><i className='fa fa-chevron-down'></i></span>)
                                    ) : (<span className='ml-2'><i className='fa fa-chevron-up'></i></span>)
                                  }
                                </div>
                                <div className='text-right btn-set-final-version-sp-image'>
                                  {
                                    !canUserNotPermission(role) && currentAnimation ? (
                                      currentAnimation.final ? (
                                        <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickCancelFinalAnimation} href="javascript:void(0);">最終稿を解除する</a>
                                      ) : role != 'public_visitor' ? (
                                        <a className="text-light" style={{ textDecoration: 'underline' }} onClick={this.onClickFinalizeAnimation} href="javascript:void(0);">最終稿にする</a>
                                      ) : ''
                                    ) : ''
                                  }
                                </div>
                              </div>
                            )
                          }
                          <TabContent activeTab={activeTab} className={ isVideo ? '' : 'tab-content-makerer-for-image' }>
                            <TabPane tabId="comments-timeline">
                              <div id="slider-scale-box" className="container-scale-box">
                                <p className="title-scale-line">拡大縮小</p>
                                <div className='slider-scale-box' style={{position: "relative"}}>
                                  <Range
                                    containerClass={"ml-3"}
                                    className={"ml-1 col-12"}
                                    value={scaleImage}
                                    step={5}
                                    handleChange={(flag)=>this.onChangeScaleImage(flag)}
                                    isShowDrawComponent={isShowDrawComponent}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="markers">
                              <AnimationMarkersPane page_last_animation={page_last_animation} onClickDownloadImageButton={this.onClickDownloadImageButton} isAkaponMember={isAkaponMember} projectId={project_id} membersInComment={membersInComment} env={env} taskId={id} reloadMarkers={this.fetchMarkers.bind(this)} isStillAvailableStorage={this.isStillAvailableStorage.bind(this)} assignments={assignments} onChangeTicket={this.onChangeTicket} currentTicketId={currentTicketId} tickets={tickets} markers={markers} onMarkerDeletionCalled={this.onMarkerDeletionCalled.bind(this)} onMarkerUpdationCalled={this.onMarkerUpdationCalled.bind(this)} onClickMarker={this.onClickMarker.bind(this)} currentUserId={currentUserId} role={role} shouldShowAd={shouldShowAd} disabled={!currentAnimation} mobile={true} project_type={project_type} currentSubscriptionType={currentSubscriptionType} apiClient={this.apiClient} currentAnimationId={currentAnimationId} canUsePageManagement={canUsePageManagement} pages={pages}
                              onChangePage={this.onChangePage} currentPageId={currentPageId} currentAnimationTotalSize={this.getTotalSizeAnimation()} onSubmit={this.updatePage.bind(this)} deletePage={this.deletePage} onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                              project={this.state.project}
                              is_video_embed={currentAnimation && currentAnimation.is_video_embed}
                              getUrlYoutube={this.open.bind(this, 'GetUrlYoutube')}
                              animations={this.state.animations}
                              onChangeCommentVersion={this.onChangeCommentVersion}
                              animationSelectCurrent={animationSelectCurrent}
                              setCurrentAnimation={this.setCurrentAnimation}
                              selectedMarker={this.state.selectedMarker}
                              updateSelectedMarker={this.updateSelectedMarker}
                              deleteReplyMarker={this.deleteReplyMarker}
                              avatarUrl={this.state.avatarUrl}
                              isDisabledButtonSendMailNotLogin={isDisabledButtonSendMailNotLogin}
                              showPopupConfirmSendMailNotLogin={this.showPopupConfirmSendMailNotLogin}
                              isShowDrawComponent={isShowDrawComponent}
                              currentAnimation={currentAnimation}
                              onStart={this.startLoading.bind(this, 'Now Processing...')}
                              onFinish={this.endLoading}
                              />
                            </TabPane>
                            <TabPane tabId="comments">
                              {
                                (!currentUserId || !isAkaponMember) &&
                                <div className="ticket-name w-100">
                                  <p className="text-white mt-2 mb-1">
                                    赤入れ保存場所&nbsp;&nbsp;&nbsp;
                                    {
                                      currentTicketId != 'task' &&
                                      <a href={canUserNotPermission(role) ? 'javascript:void(0)' : btnLoadTicketPage(env, rootUrl, port, currentTicketId != 'task', project_id, id, currentTicketId)}
                                        className="link-load-ticket-page">(このページに移動)
                                      </a>
                                    }
                                  </p>
                                  { this.ticketsSection() }
                                </div>
                              }

                              {/* {renderingAnimationPage} */}
                              <div className="project-meta clearfix">
                                <div>
                                  {
                                    canUseUrlFeature(role,currentSubscriptionType) && currentAnimation && currentAnimation.url &&
                                    <AnimationUrl currentAnimationUrl={currentAnimation.url} isMobile={true}/>
                                  }
                                </div>

                                <div className="d-flex align-items-center">
                                  <div className='col-3'>
                                    <span className="text-white form-title mr-3">Ver</span>
                                    <Input type="select" id="animation-selector" value={currentAnimationId || ''} onChange={this.onChangeAnimationSelect} className="mr-2" disabled={!canCreateAnimation(role, animations)}>
                                      {
                                        animations.map((animation, i) => {
                                          return (
                                            <option value={animation.id} key={i}>
                                              {version(animation)}
                                            </option>
                                          );
                                        })
                                      }
                                    </Input>
                                  </div>
                                  <div className='col-3 text-white'>
                                    <span className='ml-2'></span>
                                    <Input type="text" value={extensionFile} disabled={true} id="animation-selector" className="text-center ml-xl-2">
                                    </Input>
                                  </div>

                                  <Range
                                    className='col-6 ml-auto text-right mt-auto'
                                    value={scaleImage}
                                    step={5}
                                    handleChange={(flag)=>this.onChangeScaleImage(flag)}
                                    isShowDrawComponent={isShowDrawComponent}
                                  />
                                </div>

                                <div className="animation-inspector mt-3">
                                  <div className="animation-buttons">

                                    <div className="item-akapon">
                                      <Button className="btn-download-for-mobile"  onClick={() => this.onClickDownloadImageButton(downloadUrl, !canUserNotPermission(role))} disabled={canUserNotPermission(role)}>
                                        <p>画像</p>
                                        <span className="app-icon icon-download"></span>
                                      </Button>
                                    </div>

                                    <div className="item-akapon">
                                      <Button className="btn-download-csv" onClick={() => this.downloadCSV(currentAnimationId, project_id, id, currentTicketId)} download disabled={canUserNotPermission(role)}>
                                        <p>コメント</p>
                                        <span className="app-icon icon-download"></span>
                                      </Button>
                                    </div>

                                    <div className="item-akapon" data-tip data-for="sign-up-promotion-tooltip">
                                      <Button className='btn-delete-animation' onClick={this.deleteAnimation} disabled={!canDeleteAnimation(role)} >
                                        <span className="u-fontColor--red">
                                          <p>バージョン</p>
                                        </span>
                                        <span style={{fontSize: '14px'}} className="fas fa-trash-alt"></span>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="detail">
                              <div className="animation-inspector mt-3 d-none">
                                <div className="top-pane">
                                  <div className="control-button">
                                    <div className="control-button-top">

                                      <div className="btn-upload button" data-tip data-for="sign-up-promotion-tooltip">
                                        <Button className="button-upload" onClick={this.open.bind(this, 'AnimationUploaderModal')} disabled={!canCreateAnimation(role, animations)}>
                                          <img src={iconUpload} className='icon-btn-upload' />
                                          <p className='white-p break-work-button'>画像アップロード</p>
                                        </Button>
                                      </div>

                                      { gon.is_login ? (
                                        <div className="btn-donwload button" data-tip data-for="sign-up-promotion-tooltip" style={{display: "none"}}>
                                          <Button className="button-auto-comment" onClick={this.onClickAutoCommentButton} disabled={!canCreateComment(role)}>
                                            <img src={iconPen} style={{transform: 'scale(1.2)'}} />
                                            <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                                          </Button>
                                        </div>
                                      ) : (
                                        <div className="btn-donwload button" data-tip data-for="sign-up-promotion-tooltip" style={{display: "none"}}>
                                          <Button disabled={isDisabledButtonSendMailNotLogin || isShowDrawComponent}
                                                  onClick={this.showPopupConfirmSendMailNotLogin} className="button-auto-comment">
                                            <img src={iconPen} style={{transform: 'scale(2.2)'}} />
                                            <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="control-button-bottom">
                                      <div className="btn-url button" data-tip data-for="sign-up-promotion-tooltip">
                                        <Button className="button-url" disabled={!canCreateAnimation(role, animations)} onClick={this.open.bind(this, 'GetUrlModal')}>
                                          <img src={iconGlobal} />
                                          <p className='white-p break-work-button'>URL入力</p>
                                        </Button>
                                      </div>

                                      <div className="btn-share-url button" data-tip data-for="">
                                        <Button className="btn-share-url" disabled={canUserNotPermission(role)} onClick={this.open.bind(this, 'ProjectShareModal')}>
                                          <img src={iconLink} />
                                          <p className='white-p break-work-button'>赤入れ共有URL</p>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                    ) : (
                      <div className="top-pane">
                        <div className={`d-flex page-control page-control-mobile justify-content-center ${gon.is_login ? '' : 'not-login'}`}>
                          { canUsePageManagement ? (
                            <AnimationPages akaireFileName={this.akaireFileName} akaireFileId={this.akaireFileId} showMode={this.showMode}
                              currentAnimationTotalSize={this.getTotalSizeAnimation()} name={project.name}
                              pages={pages} onChangePage={this.onChangePage} selectAnimation={this.selectAnimation}
                              onClickFinalizeAnimation={this.onClickFinalizeAnimation} mobile={true}
                              onClickCancelFinalAnimation={this.onClickCancelFinalAnimation}
                              currentPageId={currentPageId} onSubmit={this.updatePage.bind(this)}
                              role={role} deletePage={this.deletePage} currentAnimation={currentAnimation} deleteVersion={this.deleteVersion}
                              animations={animations} page_last_animation={page_last_animation} projectType={'Web'} />
                          ) : ''}

                          <ProjectInfoSection showMode={this.showMode} statuses={statuses} project={project} onClickMembers={this.open.bind(this, 'ProjectShareModal')}
                            toggleModeDrawComponent={this.toggleModeDrawComponent}
                            isShowDrawComponent={isShowDrawComponent}
                            assignments={assignments} markers={markers}
                            onClickShareButton={this.open.bind(this, 'ProjectShareModal')}
                            rootUrl={rootUrl} currentUserId={currentUserId} isFreePlan={isFreePlan}
                            scaleImage={scaleImage}
                            handleSetDraw={this.handleSetDraw}
                            onClickDraw={this.handleDraw}
                            onClickEraser={this.handleEraser}
                            onClickReset={this.handleReset}
                            onClickCapture={this.handleCapture}
                            onClickEraserAll={this.handleEraserAll}
                            role={role}
                            changeShowDrawComponent={this.changeShowDrawComponent}
                            mobile={true} category={category}
                            currentAnimation={currentAnimation}
                            toggleShowModalInputInfoOfNotLogin={this.toggleModalInputInfoOfNotLogin}
                            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
                          />
                        </div>
                        <div className="no-animations">
                          <div className="content d-flex flex-column justify-content-center align-items-center">
                            <img className="create-akaire-box-icon-upload"
                              src={NoAnimationPreview} alt="アップロード"/>
                          </div>
                        </div>
                        <div className={`animation-file-size ${ isVideo ? '' : 'd-none'} `}>
                          <div className="dt position-text-top-1">
                            タスク名：
                            <span className='text-light text-truncate position-text-top'>
                              { name }
                            </span>
                          </div>
                          <div className="dt">
                            ファイル容量：
                            <span className='text-light'>
                              {bytes(this.getTotalSizeAnimation())}
                            </span>
                          </div>
                        </div>
                        {
                          (!currentUserId || !isAkaponMember) &&
                          <div className="ticket-name w-100">
                            <p className="text-white mt-2 mb-1">
                              赤入れ保存場所&nbsp;&nbsp;&nbsp;
                              {
                                currentTicketId != 'task' &&
                                <a href={canUserNotPermission(role) ? 'javascript:void(0)' : btnLoadTicketPage(env, rootUrl, port, currentTicketId != 'task', project_id, id, currentTicketId)}
                                  className="link-load-ticket-page">(このページに移動)
                                </a>
                              }
                            </p>

                            { this.ticketsSection() }
                          </div>
                        }
                        {/* {renderingAnimationPage} */}
                      </div>
                    )
                  )
                  //end-block-2
                }
              </div>
            </div>
          </div>
          { ['動画', 'video'].includes(this.props.project_type) ? ([
          currentAnimation && !currentAnimation.is_video_embed && !!currentAnimation.file_object.url || currentAnimation && currentAnimation.is_video_embed ? (
            <Button className="d-none" id="button-public-url" onClick={this.open.bind(this, 'PublicUrlModal')}>
            </Button>
          ) : (
            <></>
          )
          ]) : ([
          <FabMenu method="click" position="bl" className="float-btn-scroll-lock">
            {
              // (platform.product === 'iPhone' && Number(platform.os.version.split('.', 1)) >= 13 ) ?
              <FabChildButton icon="far fa-arrow-alt-circle-down" className={this.state.changeCss ? 'change-css-scroll' : 'change-css-scroll-off'} onClick={this.onChangeCssScroll}></FabChildButton> //: ''
            }
          </FabMenu>
          ])}
          { localStorage.getItem('displayed') ? '' : <AnimationNotify currentUserId={currentUserId}/> }

          <AnimationGetUrlModal isOpen={shouldShowGetUrlModal} onClickClose={this.close.bind(this, 'GetUrlModal')} token={token} rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id} onStart={this.startLoading.bind(this, 'Now uploading...')} baseURL={this.baseURL} checkAnimationLastPage={this.checkAnimationLastPage} taskID={id} firstUploadAkaire={this.OpenModalUploadFirstAkaire} isAkaponProject={isAkaponProject}/>
          <GetUrlYoutube isOpen={shouldShowGetUrlYoutube} checkAnimationLastPage={this.checkAnimationLastPage}
            onClickClose={this.close.bind(this, 'GetUrlYoutube')} token={token}
            rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id}
            onStart={this.startLoading.bind(this, 'Now uploading...')}
            baseURL={this.baseURL}
            fetchProject={this.fetchProject.bind(this)}
            getAnimationAdded={this.getAnimationAdded.bind(this)}
            is_create_video_embed={is_create_video_embed}
            firstUploadAkaire={this.OpenModalUploadFirstAkaire}
          />
          <ProjectShareModal changeIsShowShareInputPassUrl={this.changeIsShowShareInputPassUrl} isTaskType={currentTicketId == 'task'} isOpen={shouldShowProjectShareModal} publicToken={publicToken} assignments={assignments} onClickClose={this.closeModalShareUrl} projectId={id} currentUserId={currentUserId} role={role} onInvite={this.onChangeAssignments} onChangeRole={this.onChangeAssignments} onRemove={this.onChangeAssignments} user={user} mobile={true} onGenerateLink={this.onGenerateLink} onDeleteLink={this.onDeleteLink} rootUrl={rootUrl} apiClient={this.apiClient} onChangeSuperInviter={this.onChangeAssignments} owner={owner}  token={token} currentPageId={currentPageId}
          isDisabledButtonSavePassUrl={isDisabledButtonSavePassUrl}
          passwordShareUrl={passwordShareUrl} checkPassWordUrl={this.checkPassWordUrl}
          isAkaponMember={isAkaponMember} is_project_owner_member={is_project_owner_member}
          is_create_pass_video={is_create_pass_video} isShowInputPassUrl={isShowInputPassUrl}
          setPasswordForShareUrl={this.setPasswordForShareUrl}
          changeFocusUrl={this.changeFocusUrl} isShowInputDemoUrl={isShowInputDemoUrl} />

          <ProjectFormModal isOpen={shouldShowProjectFormModal} onClickClose={this.close.bind(this, 'ProjectFormModal')} role={role} project={project} onSubmit={this.onSubmitProjectForm} />
          <AnimationUploaderModal user={user} type={this.props.project_type} convertVideo={this.convertVideo} isOpen={shouldShowAnimationUploaderModal} onClickClose={this.close.bind(this, 'AnimationUploaderModal')} onStart={this.startUploadVideo} onFinish={['動画', 'video'].includes(this.props.project_type) ? (this.onFinishUpload) : (this.onFinishUploadImage)} projectId={id} baseURL={this.baseURL} completed={completed} convertFile={this.convertFile} statusConvert={this.finishConvert} finishConvert={this.state.finishConvert} endLoading={this.endLoading} convertPdf={this.convertPdf} currentLimitVideo={currentLimitVideo} oneGb={ONE_GB_TO_BYTES}
          uploadedDataInfo={uploadedDataInfo} onClickInputFile={this.onClickInputFile}
          is_mobile={true} isUploadMultipleFiles={false}/>
          <CompletionModal isOpen={shouldShowCompletionModal} onClickClose={this.close.bind(this, 'CompletionModal')} onSubmit={this.onSubmitCompletionModal} ad={isFreePlan} currentAnimation={currentAnimation} type={this.props.project_type} />
          <PublicUrlModal isOpen={shouldShowPublicUrlModal} onClickClose={this.closeModalShareVideo} ad={isFreePlan} currentAnimation={currentAnimation} type={this.props.project_type} token={token} public_video_token={public_video_token} is_create_pass_video={is_create_pass_video} isShowInputPassVideo={isShowInputPassVideo}
          changeIsShowInputVideo={this.changeIsShowInputVideo} setPasswordForShareVideo={this.setPasswordForShareVideo}
          passwordShareVideo={passwordShareVideo} checkPassWord={this.checkPassWord}
          isDisabledButtonSavePass={isDisabledButtonSavePass} isAkaponMember={isAkaponMember}
          is_project_owner_member={is_project_owner_member} isShowInputDemo={isShowInputDemo}
          changeFocus={this.changeFocus} currentPage={pageCurrent} project={project} />
          { pages.length > 0 && animations.length > 0 && <ModalDownloadImage isOpen={isShowModalDownloadImage}
            changeTypeDownLoad={this.changeTypeDownLoad} selectTypeDownload={selectTypeDownload}
            downloadImage={this.downloadImage}
            animations={animations} pages={pages} currentPageId={currentPageId} currentAnimationId={currentAnimationId}
            role={role} baseURL={baseURL} fetchListAnimationsDownload={this.fetchListAnimationsDownload}
           />
          }
          { pages.length > 0 && animations.length > 0 &&
            <ModalDownloadVideo isOpen={isShowModalDownloadVideo}
              changeTypeDownLoad={this.changeTypeDownLoadVideo} selectTypeDownload={selectTypeDownloadVideo}
              downloadVideo={this.downloadVideo}
              animations={animations} pages={pages} currentPageId={currentPageId} currentAnimationId={currentAnimationId}
              role={role} baseURL={baseURL} fetchListAnimationsDownload={this.fetchListAnimationsDownload}
            />
          }
        </Loadable>
        <ModalFirstUploadAkaire isOpen={isOpenModalUploadFirstAkaire} OpenModalUploadFirstAkaire={this.OpenModalUploadFirstAkaire} settingMember={this.settingMember} />
        <ModalChangeCommentList
          isOpen={modalChangeCommentListIsOpenIsOpen}
          togglePopup={this.togglePopup}
          disabledNotiCompareVersion={this.disabledNotiCompareVersion}
          text={modalChangeCommentListText}
          currentAnimation={modalChangeCommentListCurrentAnimation}
          disabledNotiComparePage={this.disabledNotiComparePage}
          kind={modalChangeCommentListKind}
        ></ModalChangeCommentList>
        <ModalConfirmSendMailNotLogin
          isOpen={modalConfirmSendMailLogin}
          togglePopup={this.togleModalConfirmSendMailNotLogin}
          sendMailNotLogin={this.sendMailNotLogin}
        ></ModalConfirmSendMailNotLogin>
        <ModalInputInfoOfNotLogin
          saveInfoOfNotLogin={this.setEmailTepAndUserTempNotLogin}
          isOpen={showModalInputInfoOfNotLogin}
          togglePopup={this.toggleModalInputInfoOfNotLogin}
          userNameTempOfNotLogin={userNameTempOfNotLogin}
          emailTempOfNotLogin={emailTempOfNotLogin}
          changeEmailTempOfNotLogin={this.changeEmailTempOfNotLogin}
          changeUserNameTempOfNotLogin={this.changeUserNameTempOfNotLogin}
        ></ModalInputInfoOfNotLogin>
        <ModalShowCreateCommentNotLogin
          isOpen={isShowModalCreateCommentNotLogin}
          isNotShowCreateCommentNotLogin={isNotShowCreateCommentNotLogin}
          changeNotShowModalCreateCommentNotLogin={this.changeNotShowModalCreateCommentNotLogin}
          togglePopup={this.toggleModalCreateCommentNotLogin}
        ></ModalShowCreateCommentNotLogin>
      </div>
    )
  }
  render() {
    const { windowWidth } = this.props;
    return windowWidth > 720 ? this.renderNormal() : this.renderMobile();
  }
}

export default reactWindowSize(AnimationInspector);

export { AnimationInspector };
