export default class ListTempFileJs {
  selectProjectAssign(element) {
    this.checkKindProject(element)
    const listTempFileJs = this;
    var project_id = $(element).val();
    var animation_type = $('#animation_type').val()
    var option = $('.select-task-akaire')
    let createFromProject = $(element).attr('data-from-project-id')
    var createFromTaskId = option.attr('data-from-task-id')
    var existOptionFrom = false
    $('.select-task-akaire').attr('data-project-id', project_id)
    option.empty().append('<option value="">タスクに紐づけない</option>');
    if(project_id == 'new') {
      $('button#btnSubmitAssignAkaire').attr('is_max_page_login_number', $('input#inputCheckMaxAkairePageLogin').val())
      $('button#btnSubmitAssignAkaire').attr('is_max_number_youtube', $('input#inputCheckMaxAkaireYoutube').val())
      listTempFileJs._toggleBtnUpgradePlan(true)
      listTempFileJs._setInfoOfModalWarningLimit()
      option.append(`<option value="new">タスク1（新規作成）</option>`)
      option.val('').trigger('change')
      listTempFileJs.setOptionAkaireFile([], animation_type);
      this.checkDisableSelectBox($('.select-file-akaire'))
      this.checkDisableSelectBox($('.select-page-akaire'))
    } else {
      const url = Routes.get_list_task_of_project_owner_project_path(project_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        var tasks = data.tasks;
        $('button#btnSubmitAssignAkaire').attr('is_max_page_login_number', data.check_max_page_login_number)
        $('button#btnSubmitAssignAkaire').attr('is_max_number_youtube', data.check_max_youtube_number)
        listTempFileJs._toggleBtnUpgradePlan(data.is_project_owner)
        option.append(`<option value="new">タスク${data.count_task + 1}（新規作成）</option>`)
        $(element).attr('data-check-max-akaire-files-quantity', data.check_max_akaire_files_quantity)
        listTempFileJs.setOptionAkaireFile(data.akaire_files, animation_type);
        for(let i = 0; i < tasks.length; i++){
          if(createFromTaskId == tasks[i].id) {
            existOptionFrom = true
            option.append(`<option value="${tasks[i].id}" selected>【作成元】${tasks[i].name}</option>`);
          } else {
            option.append(`<option value="${tasks[i].id}">${tasks[i].name}</option>`);
          }
        }
        if (!!createFromTaskId && existOptionFrom) {
          $('#assignAkairePopup').find(`.select-task-akaire`).val(createFromTaskId)
        }
        if ($(element), !!createFromProject && !createFromTaskId) option.val('')
        option.trigger('change')

        if (data.project_owner_membership_plan) {
          $("span.project_owner_plan_name").text(data.project_owner_membership_plan.name)
          $("span.project_owner_plan_limit_youtube").text(data.project_owner_membership_plan.page_youtube_number)
          $("span.project_owner_plan_limit_login_page").text(data.project_owner_membership_plan.page_login_number)
        }
      })
    }
  }

  selectTaskAssign(element) {
    var animation_type = $('#animation_type').val()
    const listTempFileJs = this;
    var task_id = $(element).val();
    var project_id = $(element).attr('data-project-id')
    var option = $('.select-ticket-akaire')
    let createFromTaskId = $(element).attr('data-from-task-id')
    var createFromWorkId = option.attr('data-from-work-id')
    var existOptionFrom = false
    option.empty().append('<option value="">チケットに紐づけない</option>')
    $('.select-ticket-akaire').attr('data-task-id', task_id)
    if(task_id == 'new') {
      option.append(`<option value="new">チケット1（新規作成）</option>`)
      option.val('new').trigger('change')
      listTempFileJs.setOptionAkaireFile([], animation_type);
    } else if(task_id == '') {
      const url = Routes.get_list_task_of_project_owner_project_path(project_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        $(element).attr('data-check-max-akaire-files-quantity', data.check_max_akaire_files_quantity)
        listTempFileJs.setOptionAkaireFile(data.akaire_files, animation_type);
      })
    } else {
      const url = Routes.owner_get_list_ticket_of_task_path(project_id, task_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        var works = data.works;
        option.append(`<option value="new">チケット${data.count_ticket + 1}（新規作成）</option>`)
        listTempFileJs.setOptionAkaireFile(data.akaire_files, animation_type);
        for(let i = 0; i < works.length; i++){
          if(createFromWorkId == works[i].id) {
            existOptionFrom = true
            option.append(`<option value="${works[i].id}" selected>【作成元】${works[i].description}</option>`);
          } else {
            option.append(`<option value="${works[i].id}">${works[i].description}</option>`);
          }
        }
        if (!!createFromTaskId && !!createFromWorkId && existOptionFrom) {
          option.val(createFromWorkId)
        }
        if (!!createFromTaskId && !createFromWorkId) option.val('')
        option.trigger('change')
      })
      .fail(function(data) {
        $('.select-project-akaire').trigger('change')
      })
    }
  }

  selectTicketAssign(element) {
    var animation_type = $('#animation_type').val()
    const listTempFileJs = this;
    var work_id = $(element).val();
    var task_id = $(element).attr('data-task-id')
    var project_id = $('.select-task-akaire').attr('data-project-id')
    if(work_id == 'new') {
      listTempFileJs.setOptionAkaireFile([], animation_type);
    } else if(work_id == '') {
      if(task_id == '') return

      const url = Routes.owner_get_list_ticket_of_task_path(project_id, task_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        listTempFileJs.setOptionAkaireFile(data.akaire_files, animation_type);
      })
    } else {
      const url = Routes.owner_list_akaire_files_from_ticket_path(project_id, task_id, work_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        listTempFileJs.setOptionAkaireFile(data.akaire_files, animation_type);
      })
    }
  }

  selectFileAkaireAssign(element) {
    const listTempFileJs = this;
    var file_id = $(element).val();
    var option = $('.select-page-akaire')
    const createFromPageId = option.attr('data-from-page-id')
    option.addClass('disabled-and-hidden-arrow')
    if( ['new', 'cannot-new'].includes(file_id)) {
      option.empty().append('<option value="new" is-use-page-login="false">ページ1（新規作成）</option>')
      option.val('new')
    } else {
      const url = Routes.list_akaire_page_of_file_akaire_file_path(file_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        var akaire_pages = data.akaire_pages;
        option.empty().append(`<option value="new" is-use-page-login="false">ページ${akaire_pages.length + 1}（新規作成）</option>`)
        for(let i = 0; i < akaire_pages.length; i++){
          option.removeClass('disabled-and-hidden-arrow')
          if(createFromPageId == akaire_pages[i].id) {
            option.append(`<option value="${akaire_pages[i].id}" is-use-page-login="${akaire_pages[i].use_account_login}" selected>【作成元】${akaire_pages[i].title}</option>`);
          } else {
            option.append(`<option value="${akaire_pages[i].id}" is-use-page-login="${akaire_pages[i].use_account_login}">${akaire_pages[i].title}</option>`);
          }
        }
      }).fail(function(err){
        Popup.alert(I18n.t('akaire_file.not_record'))
      })
    }
  }

  setOptionAkaireFile(akaire_files, animation_type){
    var option = $('.select-file-akaire')
    var selectStatus = true
    var check_max_akaire_files_quantity = $('.select-project-akaire').attr('data-check-max-akaire-files-quantity')
    const createFromProjectId = $('.select-project-akaire').attr('data-from-project-id')
    const createFromTaskId = $('.select-task-akaire').attr('data-from-task-id')
    const createFromWorkId = $('.select-ticket-akaire').attr('data-from-work-id')
    const createFromFileId = option.attr('data-from-file-id')
    if(check_max_akaire_files_quantity != 'false'){
      option.empty().append(`<option value="new">ファイル${akaire_files.length + 1}（新規作成）</option>`)
      option.val('new').trigger('change')
    } else {
      option.empty().append(`<option value="cannot-new">ファイル${akaire_files.length + 1}（新規作成）</option>`)
      option.val('cannot-new').trigger('change')
    }
    let isCreateFromFileId = false
    for(let i = 0; i < akaire_files.length; i++){
      var html_option = `<option value="${akaire_files[i].id}">${akaire_files[i].name}</option>`
      if(createFromFileId == akaire_files[i].id) {
        isCreateFromFileId = true
        html_option = `<option value="${akaire_files[i].id}" selected>【作成元】${akaire_files[i].name}</option>`
      }
      if(animation_type == 'video' || animation_type == 'youtube'){
        if(akaire_files[i].category == 'video') {
          option.append(html_option);
        }
      } else {
        if(akaire_files[i].category == 'web') {
          option.append(html_option);
        }
      }
    }
    if((!!createFromFileId && !!createFromProjectId && !createFromTaskId) && isCreateFromFileId) {
      const valueOption = option.find(`option[value="${createFromFileId}"]`).length > 0 ? createFromFileId : 'new'
      option.val(valueOption).trigger('change')
    }
    this.checkDisableSelectBox($('.select-file-akaire'))
  }

  submitAssign(ele) {
    var isSubmit = true;
    const form = $('#animation-assign-form');
    const animationType = form.find('#animation_type').val()
    const isMaxVideoYouTube = $(ele).attr('is_max_number_youtube')
    if (animationType === 'youtube' && isMaxVideoYouTube === 'true' && $('.select-page-akaire').val() == 'new') {
      $('#assignAkairePopup').modal('hide')
      $('#errorModalForMaxYoutube').modal('show');
      isSubmit = false;
      return
    }
    if (
      animationType === 'image' &&
      form.find('#animation_type').attr('is-capture-url') == 'true' &&
      form.find('.select-page-akaire option:selected').attr('is-use-page-login') == 'false' &&
      $(ele).attr('is_max_page_login_number') === 'true'
    ) {
      $("#overPageLoginURLModal").modal('show');
      isSubmit = false;
      return
    }
    if($('.select-project-akaire').val() == 'new' && $('.select-project-akaire').attr('data-can-create-project') == 'false') {
      $('#assignAkairePopup').modal('hide')
      $('#errorModalForProjectAssign').modal('show')
      isSubmit = false
    }

    if($('.select-file-akaire').val() == 'cannot-new') {
      Popup.confirm('データを保管できるファイル数が不足しています。', {
        cancelBtn: 'false',
        confirmText: 'プランを変更する',
        success: () => { window.open(Routes.owner_total_info_path()) },
        cancel: () => {},
        kind: 'akaire'
      })
      isSubmit = false
    }

    if(isSubmit) {
      $(ele).attr('disabled', true)
      $('#animation-assign-form').submit()
    }
  }

  checkDisableSelectBox(element){
    if(element.find('option').length == 1) {
      element.addClass('disabled-and-hidden-arrow')
    } else {
      element.removeClass('disabled-and-hidden-arrow')
    }
  }

  autoSelectProjectDefault(element) {
    var select_default = $('#assignAkairePopup').find('.select-project-akaire').attr('data-select-default')
    const createFromProjectID = $(element).attr('data-from-project-id');
    const createFromTaskID = $(element).attr('data-from-task-id');
    const createFromWorkID = $(element).attr('data-from-work-id');
    const createFromAkaireFileID = $(element).attr('data-from-akaire-file-id');
    const createFromPageID = $(element).attr('data-from-page-id');
    $('#assignAkairePopup').find('.select-project-akaire option').toArray().forEach(element => {
      $(element).text($(element).text().replace('【作成元】', ''))
    });
    if (!!createFromProjectID) {
      let optionDefault = $('#assignAkairePopup').find(`.select-project-akaire option[value="${createFromProjectID}"]`)
      if(optionDefault.length > 0) {
        optionDefault.text(`【作成元】${optionDefault.text()}`)
        select_default = createFromProjectID
      }
    }
    $('#assignAkairePopup').find('.select-project-akaire').attr('data-from-project-id', createFromProjectID)
    $('#assignAkairePopup').find(`.select-task-akaire`).attr('data-from-task-id', createFromTaskID)
    $('#assignAkairePopup').find(`.select-ticket-akaire`).attr('data-from-work-id', createFromWorkID)
    $('#assignAkairePopup').find(`.select-file-akaire`).attr('data-from-file-id', createFromAkaireFileID)
    $('#assignAkairePopup').find(`.select-page-akaire`).attr('data-from-page-id', createFromPageID)
    $('#assignAkairePopup').find('.select-project-akaire').val(select_default).trigger('change')
    this.checkBlankProject()
  }

  checkBlankProject() {
    if($('#assignAkairePopup').attr('data-project-blank') == 'true') {
      $('#noticeWithFirstProject').modal('show')
    } else {
      $('#assignAkairePopup').modal('show')
    }
  }

  checkKindProject(element) {
    var optionSelect = $(element).find(`option[value=${$(element).val()}]`);
    var isProjectAkapon = optionSelect.attr('data-project-akapon')
    if(isProjectAkapon == 'true') {
      $('.just-cc-project').addClass('d-none')
    } else {
      $('.just-cc-project').removeClass('d-none')
    }
  }

  confirmDeleteAnimation() {
    Popup.confirm('一時保管データを削除します。<br> よろしいですか？', {
      cancelBtn: 'false',
      confirmText: 'OK',
      success: () => {
        $('.btn-delete-animation-hidden')[0].click()
      },
      cancel: () => {},
      kind: 'akaire'
    })
  }

  _toggleBtnUpgradePlan(isProjectOwner) {
    $("#overPageLoginURLModal").find('div#btnForProjectOwnerModalOverPageLogin').toggleClass('d-none', !isProjectOwner)
    $("#errorModalForMaxYoutube").find('div#btnForProjectOwnerModalOverPageLogin').toggleClass('d-none', !isProjectOwner)
  }

  _setInfoOfModalWarningLimit() {
    $("span.project_owner_plan_name").text($('input#inputCurrentPlanName').val())
    $("span.project_owner_plan_limit_youtube").text($('input#inputCurrentPlanLimitYoutube').val())
    $("span.project_owner_plan_limit_login_page").text($('input#inputCurrentPlanLimitPageLogin').val())
}
}
