import React, { Component } from "react";
import { styles } from "../util/styleDrawComponent";
import "../css/draw-component.scss";
import MarkerIcon from "../../../assets/images/pen_icon.png";
import DrawIcon from "../../../assets/images/draw_icon.svg";
import EraserIcon from "../../../assets/images/eraser_icon.svg";
import CaptureIcon from "../../../assets/images/capture_icon.svg";
import { canCreateCommentPublicUser, canUserNotPermission } from '../authorizations'

import Alert from "../ui/Alert"
class DrawComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      penCoords: [],
      canvasWidth: 0,
      canvasHeight: 0,
      mode: "draw",
      pen: "up",
      lineWidth: 3,
      penColor: "black",
      isShowPenOption: false,
      showAlert:false,
      isShowEraseAll: false,
    };
  }
  renderPenOption = () => {
    return (
      <div>
        <p className="draw_label text-white">Setting</p>
        <div
          className="pen-color"
          style={{ background: this.state.penColor, marginTop: 10 }}
        ></div>
      </div>
    );
  };
  setColor(color) {
    this.setState(
      {
        penColor: color,
      },
      () => {
        const dataDrawSetting = {
          color: this.state.penColor,
          lineWidth: this.state.lineWidth,
        };
        this.props.settingDraw(dataDrawSetting);
      }
    );
  }

  setPenSize(lineWidth) {
    this.setState(
      {
        lineWidth: lineWidth,
      },
      () => {
        const dataDrawSetting = {
          color: this.state.penColor,
          lineWidth: this.state.lineWidth,
        };
        this.props.settingDraw(dataDrawSetting);
      }
    );
  }
  draw(e) {
    this.setState(
      {
        isShowEraseAll: false,
      },
      () => {
        this.props.onClickDraw();
      }
    );
    this.setOption()
  }

  eraser(e) {
    if (!this.state.isShowEraseAll) {
      document.addEventListener("click", this.handleClickOutSideCloseEraseAll, false);
    } else {
      document.removeEventListener("click", this.handleClickOutSideCloseEraseAll, false);
    }
    this.setState(
      {
        isShowPenOption: false,
        isShowEraseAll: !this.state.isShowEraseAll,
      },
      () => {
        this.props.onClickEraser();
      }
    );
  }

  handleClickOutSideCloseEraseAll = (e) => {
    if (e.target.classList.contains('show-erase-all')) {
      return
    }
    this.setState({ isShowEraseAll: false });
  }

  setOption() {
    if (!this.state.isShowPenOption) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      isShowPenOption: !prevState.isShowPenOption,
      isShowEraseAll: false
    }));
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setOption();
  };

  reset() {
    this.setState(
      {
        isShowPenOption: false,
        isShowEraseAll: false,
      },
      () => {
        this.props.onClickReset();
      }
    );
  }

  closeSetting = () => {
    this.setState({
      isShowPenOption: false,
      isShowEraseAll: false,
    });
  };

  capture() {
    this.setState(
      {
        isShowPenOption: false,
        isShowEraseAll: false,
      },
      () => {
        this.props.onClickCapture();
      }
    );
  }

  handleCloseAlert=()=>{
    this.setState({showAlert:false})
  }
  handleEraseAll() {
    this.setState({
      isShowPenOption: false,
      isShowEraseAll: false,
    })
    this.props.onClickEraserAll()
  }
  toggleModeDrawComponentOfDraw = async (isDraw) => {
    const { isShowDrawComponent, toggleShowModalInputInfoOfNotLogin, isInputInfoMationOfNotLogin } = this.props;
    if (isShowDrawComponent) {
      Popup.confirm(
        `ペンツールの作業途中です。<br>画面上部 <span class="img-of-popup-capture"> <img src=${CaptureIcon} width="18" style="margin-bottom: 4px;"></img></span> からキャプチャ　＞　コメントを保存<br>すると、ペン入力のデータが保存されます。<br>このままペンツールを終了しますか？`,
        {
          success: () => {
            this.handleToggleDrawComponent(isDraw)
          },
          confirmText: 'OK',
        }
      )
    } else {
      if (gon.is_login || isInputInfoMationOfNotLogin) {
        this.handleToggleDrawComponent(isDraw)
      } else {
        toggleShowModalInputInfoOfNotLogin()
      }
    }
  }
  handleToggleDrawComponent = async (isDraw) => {
    const { toggleModeDrawComponent, isShowDrawComponent } = this.props;
    await toggleModeDrawComponent(isDraw)
    if (isDraw) {
      document.addEventListener("click", this.handleClickOutSideClosePopupDraw, false);
    } else {
      document.removeEventListener("click", this.handleClickOutSideClosePopupDraw, false);
    }
    this.disabledOrEnabledAllTagA(isDraw)
  }
  handleClickOutSideClosePopupDraw = (e) => {
    if (this.node.contains(e.target) || document.getElementsByClassName('swal2-container ')[0]?.contains(e.target) ||
        document.getElementById('wrap-center').contains(e.target) ||
        !document.getElementsByTagName('body')[0]?.contains(e.target)) {
      return
    }
    Popup.confirm(
      `ペンツールの作業途中です。<br>画面上部 <span class="img-of-popup-capture"> <img src=${CaptureIcon} width="18" style="margin-bottom: 4px;"></img></span> からキャプチャ　＞　コメントを保存<br>すると、ペン入力のデータが保存されます。<br>このままペンツールを終了しますか？`,
      {
        success: () => { this.handleToggleDrawComponent(false) },
        confirmText: 'OK',
      }
    )
  }
  disabledOrEnabledAllTagA = (isDisabled) => {
    $(document).find('a').toArray().forEach(a => {
      if (isDisabled) {
        $(a).attr('disabled', true);
        $(a).css('pointer-events', 'none');
      } else {
        $(a).removeAttr('disabled')
        $(a).css('pointer-events', 'auto');
      }
    })
  }
  render() {
    const { mobile, toggleModeDrawComponent, isShowDrawComponent, currentAnimation, role } = this.props;
    const {showAlert} = this.state
    return (
      <div className={isShowDrawComponent ? 'right-component' : ''} style={{ display: "flex", marginBottom: '0px', alignItems: "center", position: 'relative',}}>
        <Alert handleChange={this.handleCloseAlert} content="拡大・縮小が100%以外の時はご利用できません" open={showAlert}/>
          {/* <div className="div_marker text-center d-flex" onClick={() => toggleModeDrawComponent(!isShowDrawComponent)} style={mobile ? {height: 45} : {}}>
            <div className="text-center">
              <img src={MarkerlIcon}></img>
            </div>
          </div> */}
          <div
            ref={(node) => {
              this.node = node;
            }}
            style={!mobile ? { position: "absolute", left: 145, display: 'contents'} : {height: 35, width: '100%'}}
          >
            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}
              className={`ml-2 size-text-sp show-draw-component-new-style`}
            >

              <div 
                onClick={() => this.toggleModeDrawComponentOfDraw(!isShowDrawComponent)}
                style={mobile ? 
                  {height: 35, width: 35, cursor: 'pointer', opacity: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px'} : 
                  {height: 35, width: 35, cursor: 'pointer', opacity: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px'} 
                }
                className="text-center d-flex"
              >
                <img src={MarkerIcon} style={{width: '100%', height: '100%', objectFit: 'contain'}}></img>
              </div>
              <button
                onClick={(e) => this.draw(e)}
                disabled={!isShowDrawComponent}
                style={mobile ? {...styles.buttonSquareDraw, ...{height: 35, width: 35, minWidth: 35, padding: 0}} : (styles.buttonSquareDraw) }
                className={mobile ? 'pentool-button' : 'pentool-button mr-2'}
              >
                <img src={DrawIcon} style={{width: 24, height: 24}}></img>
                {/* <i style={{fontSize: 16, color: "#ffffff"}} className="fas fa-light fa-pen"></i> */}
              </button>
              <button
                onClick={(e) => this.capture(e)}
                disabled={!isShowDrawComponent}
                style={mobile ? {...styles.buttonSquareCapture, ...{height: 35, width: 35, minWidth: 35, padding: 0}} : (styles.buttonSquareCapture)}
                className={mobile ? 'pentool-button' : 'pentool-button mr-5'}
              >
                <img src={CaptureIcon} style={{width: 24, height: 24}}></img>
                {/* <i style={{fontSize: 16, color: "#ffffff"}} className="fa-solid fas fa-camera"></i> */}
              </button>
              <button
                className={mobile ? 'pentool-button show-erase-all' : 'pentool-button show-erase-all mr-2'}
                disabled={!isShowDrawComponent}
                onClick={(e) => this.eraser(e)}
                style={mobile ? {...styles.buttonSquareEraser, ...{height: 35, width: 35, minWidth: 35, padding: 0}} : (styles.buttonSquareEraser)}
              >
                <img src={EraserIcon} style={{width: 24, height: 24}} className="show-erase-all"></img>
                {/* <i style={{fontSize: 16, color: "#ffffff"}} className="fa-solid fas fa-eraser show-erase-all"></i> */}
              </button>
              {/* <button
                className={mobile ? 'mr-1' : 'mr-2'}
                onClick={() => this.setOption()}
                style={mobile ? {...styles.buttonSquareSetting, ...{height: 45, width: 45}} : (styles.buttonSquareSetting)}
              >
                <p className="draw_label text-white">Setting</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-gear" viewBox="0 0 16 16">
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                </svg>
              </button> */}
            </div>
            {this.state.isShowPenOption && (
              <div className={!mobile ? 'pen-option' : 'pen-option-mobile'} ref={(el) => (this.penOption = el)}
                   onClick={(event) => {event.stopPropagation()}}>
                <p className="text-black">サイズ</p>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => this.setPenSize(3)}
                    className="pen-size"
                    style={{
                      width: 7, height: 7,
                      background: this.state.lineWidth === 3 ? 'black': '',
                      border: this.state.lineWidth === 3 ? 'solid 1.5px white': ''
                     }}
                  ></div>
                  <div
                    onClick={() => this.setPenSize(6)}
                    className="pen-size"
                    style={{
                      width: 10, height: 10,
                      background: this.state.lineWidth === 6 ? 'black': '',
                      border: this.state.lineWidth === 6 ? 'solid 1.5px white': ''
                     }}
                  ></div>
                  <div
                    onClick={() => this.setPenSize(9)}
                    className="pen-size"
                    style={{
                      width: 13, height: 13,
                      background: this.state.lineWidth === 9 ? 'black': '',
                      border: this.state.lineWidth === 9 ? 'solid 1.5px white': ''
                    }}
                  ></div>
                  <div
                    onClick={() => this.setPenSize(12)}
                    className="pen-size"
                    style={{
                      width: 16, height: 16,
                      background: this.state.lineWidth === 12 ? 'black': '',
                      border: this.state.lineWidth === 12 ? 'solid 1.5px white': ''  }}
                  ></div>
                  <div
                    onClick={() => this.setPenSize(15)}
                    className="pen-size"
                    style={{
                      width: 19, height: 19,
                      background: this.state.lineWidth === 15 ? 'black': '',
                      border: this.state.lineWidth === 15 ? 'solid 1.5px white': ''
                     }}
                  ></div>
                </div>
                <div className="line-width">
                  <div
                    id="line-review"
                    style={{
                      background: this.state.penColor,
                      height: this.state.lineWidth,
                      width: "80%",
                      margin: "auto",
                      borderRadius: 10,
                    }}
                  ></div>
                </div>
                <p className="text-black">色</p>
                <div style={{ display: "flex" }}>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.black,
                      styles.button
                    )}
                    onClick={() => this.setColor("black")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.white,
                      styles.button
                    )}
                    onClick={() => this.setColor("white")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.lightgrey,
                      styles.button
                    )}
                    onClick={() => this.setColor("lightgrey")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.darkgray,
                      styles.button
                    )}
                    onClick={() => this.setColor("darkgray")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.gray,
                      styles.button
                    )}
                    onClick={() => this.setColor("gray")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.dimgrey,
                      styles.button
                    )}
                    onClick={() => this.setColor("dimgrey")}
                  ></button>
                </div>
                <div style={{ display: "flex" }} className="mt-2">
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.mediumvioletred,
                      styles.button
                    )}
                    onClick={() => this.setColor("mediumvioletred")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.red,
                      styles.button
                    )}
                    onClick={() => this.setColor("red")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.orangered,
                      styles.button
                    )}
                    onClick={() => this.setColor("orangered")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.orange,
                      styles.button
                    )}
                    onClick={() => this.setColor("orange")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.gold,
                      styles.button
                    )}
                    onClick={() => this.setColor("gold")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.yellow,
                      styles.button
                    )}
                    onClick={() => this.setColor("yellow")}
                  ></button>
                </div>
                <div style={{ display: "flex" }} className="mt-2">
                <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.greenyellow,
                      styles.button
                    )}
                    onClick={() => this.setColor("greenyellow")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.limegreen,
                      styles.button
                    )}
                    onClick={() => this.setColor("limegreen")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.green,
                      styles.button
                    )}
                    onClick={() => this.setColor("green")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.cadetblue,
                      styles.button
                    )}
                    onClick={() => this.setColor("cadetblue")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.blue,
                      styles.button
                    )}
                    onClick={() => this.setColor("blue")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.midnightblue,
                      styles.button
                    )}
                    onClick={() => this.setColor("midnightblue")}
                  ></button>
                </div>
                <div style={{ display: "flex" }} className="mt-2">
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.rebeccapurple,
                      styles.button
                    )}
                    onClick={() => this.setColor("rebeccapurple")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.purple,
                      styles.button
                    )}
                    onClick={() => this.setColor("purple")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.antiquewhite,
                      styles.button
                    )}
                    onClick={() => this.setColor("antiquewhite")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.peru,
                      styles.button
                    )}
                    onClick={() => this.setColor("peru")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.sienna,
                      styles.button
                    )}
                    onClick={() => this.setColor("sienna")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.saddlebrown,
                      styles.button
                    )}
                    onClick={() => this.setColor("saddlebrown")}
                  ></button>
                </div>
                <div style={{ display: "flex" }} className="mt-2">
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.hotpink,
                      styles.button
                    )}
                    onClick={() => this.setColor("hotpink")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.peachpuff,
                      styles.button
                    )}
                    onClick={() => this.setColor("peachpuff")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.lightyellow,
                      styles.button
                    )}
                    onClick={() => this.setColor("lightyellow")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.lightgreen,
                      styles.button
                    )}
                    onClick={() => this.setColor("lightgreen")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.skyblue,
                      styles.button
                    )}
                    onClick={() => this.setColor("skyblue")}
                  ></button>
                  <button
                    style={Object.assign(
                      {},
                      styles.colorSwatches.mediumslateblue,
                      styles.button
                    )}
                    onClick={() => this.setColor("mediumslateblue")}
                  ></button>
                </div>
              </div>
            )}
            {
              this.state.isShowEraseAll && (
                <div className="erase-all-option" ref={(el) => (this.penOption = el)}>
                  <button
                    className="d-flex mt-2"
                    onClick={(e) => this.handleEraseAll()}
                    style={(styles.buttonSquare)}
                  >
                    <i
                      style={{fontSize: 17, color: 'red'}}
                      className="fas fa-trash-alt mt-1 mr-2">
                    </i>
                    <p className="draw_label text-white" style={{background: 'red'}}>すべてのインクを消法</p>
                  </button>
                </div>
              )
            }
          </div>
      </div>
    );
  }
}

export default DrawComponent;
