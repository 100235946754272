import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form } from 'reactstrap';
import { canCreateAndUpdatePage, canDeletePage, canUserNotPermission, canCreateAnimation, canCreateCommentPublicUser } from '../authorizations';
import { version } from '../util/animation';
import iconBin from '../ui/images/iconBinRed.svg';
import iconBinWhite from '../ui/images/iconBinWhite.svg';
import iconPen from '../ui/images/iconPen.png';
import iconWeb from '/app/assets/images/icon-www.png';
import iconVideo from '/app/assets/images/video_akaire.png';
import iconPDF from '/app/assets/images/upload_image.png';
import compareVersion from '/app/assets/images/compare_version.png';
import iconYoutube from '/app/assets/images/youTube.png';
import imgDefaultThumbnail from '/app/assets/images/default_loading_thumbnail.png';
import bytes from 'bytes';
import "../css/project-info-header.scss";
import Select, { components } from "react-select";
import { detectIpad } from '../detect-device'

export default class AnimationPlayer extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowEditForm: false,
      shouldShowAddPageSuccess: false,
      valueForm: '',
      valuePageId: '',
    };
    this.url = new URL(location.href);
    this.mainUrl = this.url.href.split('?')[0];
    this.showPopupAddPage = true
  }

  componentDidUpdate() {
    if (this.props.currentAnimationTotalSize != NaN) {
      let el_size_current_animation = document.getElementsByClassName('animation_size');

      if (el_size_current_animation.length > 0) {
        el_size_current_animation[0].innerHTML = "表示中のバージョン容量： " + bytes(this.props.currentAnimationTotalSize);
      }
      this.toggleElementList("nav-bar-animation_size", true);
      if(window.innerWidth <= 720) this.appendFileSize()
    }
    this.showPopupWhenAddNewPage();
  }

  showPopupWhenAddNewPage = () => {
    const addNewpage = this.url.searchParams.get("new_page")
    if(this.props && this.showPopupAddPage && addNewpage == 'true') {
      const { pages, animations } = this.props;
      if(pages.length > 0 && animations.length > 0) {
        this.showPopupAddPage = false
        this.setState({shouldShowAddPageSuccess: true})
      }
    }
  }

  linkOpenEditPage(page) {
    return (
      <a href='javascript:void(0)' className='text-white text-underline'
        onClick={(event) => {
          this.open(event, {label: page.title, value: page.id}); this.closePopupAddPageSuccess()}
        }>ページ名を変更する</a>
    )
  }

  appendFileSize = () => {
    let height = document.querySelector('.bottom-pane')?.offsetTop || 0;

    const elementAppend = $('.video-react-control-bar').find('.video-react-progress-control')
    if (!!document.querySelector('.video-react-control-bar')) {
      document.querySelector('.video-react-control-bar').style.top = `${height - 48}px`;
    }
    if (!!elementAppend) {
      $('.video-react-control.video-react-file-size').remove()
      elementAppend.after(`<div class='video-react-control video-react-file-size'>${bytes(this.props.currentAnimationTotalSize)}</div>`)
    }
  }

  toggleElementList(className, show = true) {
    const elementList = document.getElementsByClassName(className);
    for (let i = 0; i < elementList.length; i++) {
      elementList[i].style.visibility = show ? "visible" : "hidden";
    }
  }

  toggle = bool => name => {
    this.setState({ [`shouldShow${name}`]: bool, valueForm: this.currentPage() && this.currentPage().title })
  }
  open = (event, page) => {
    event.stopPropagation();
    this.setState({
      shouldShowEditForm: true,
      valueForm: page.label,
      valuePageId: page.value
    })
  }
  close = this.toggle(false)
  submitForm = (event) => {
    const { onSubmit } = this.props;
    const { valueForm, valuePageId } = this.state;
    event.preventDefault();
    onSubmit({ title: valueForm, pageId: valuePageId })
      .then(
        this.setState({ shouldShowEditForm: false })
      )
  }
  changeName = (event) => {
    this.setState({
      valueForm: event.target.value
    });
  }
  currentPage = () => {
    const { pages, currentPageId } = this.props;
    return (pages && pages.filter(page => page.id == currentPageId)[0])
  }
  confirmDeletePage = (data) => {
    const { deletePage } = this.props;
    Popup.confirm(
      `<span class="font-weight-bold">${data.label}を削除します。<br>よろしいですか？</span>`,
      {
        success: () => {
          deletePage(data.value, data.label)
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        kind: 'akaire'
      }
    )
  }

  confirmDeleteVersion = (value) => {
    const { deleteVersion } = this.props;
    Popup.confirm(
      '<span class="font-weight-bold">このバージョンを削除します。<br>よろしいですか？</span>',
      {
        success: () => {
          deleteVersion(value)
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        kind: 'akaire',
      }
    )
  }
  onChangeAnimationSelect = (value) => {
    this.props.selectAnimation(value)
  }

  pagesToOptions = () => {
    const { pages } = this.props;
    return pages.map(({ id, title }) => ({ value: id, label: title }))
  }

  animationsToOptions = () => {
    const { animations } = this.props;
    var dataAnimations = animations
    if(this.checkFinalVersion() === undefined) {
      dataAnimations = animations.sort((a, b) => b.id - a.id)
    }
    return dataAnimations.map(({ id, title, formatted_created_at, creator_name, final, official_name, additional_info, is_video_embed, url, set_thumbnail}) => ({ value: id, label: final ? '最終稿' : title, createdAt: formatted_created_at, creatorName: creator_name, fileName: official_name || additional_info.title || '', duration: additional_info.duration, video_embed: is_video_embed, url_capture: url, set_thumbnail: set_thumbnail }))
  }

  lastAnimationToOptions = () => {
    var option = this.animationsToOptions();
    return option.slice(-1)[0].value
  }

  sortAnimation = (animations) => {
    const finalObjects = animations.filter(obj => obj.final).sort((a, b) => b.id - a.id);
    const nonFinalObjects = animations.filter(obj => !obj.final).sort((a, b) => b.id - a.id);
    return [...finalObjects, ...nonFinalObjects];
  }

  defaultPage = () => {
    const { pages, currentPageId } = this.props;
    if (currentPageId == undefined) return {}
    const page = pages.find((item) => item.id == currentPageId)
    return { value: page.id, label: page.title }
  }

  defaultVersion = () => {
    const { animations, currentAnimation } = this.props;
    if (currentAnimation == undefined) return {}
    const animation = animations.find((item) => item.id == currentAnimation.id)
    return { value: animation.id, label: animation.final ? '最終稿' : animation.title }
  }

  checkFinalVersion = () => {
    const { animations } = this.props;
    const animationFinal = animations.find((animation) => animation.final === true)
    return animationFinal
  }

  customSelectStyle = (isPage) => {
    let borderStyle = {}
    const { mobile } = this.props
    if (isPage) {
      borderStyle = {
        borderRight: '0px',
        borderRadius: '5px 0px 0px 5px',
        borderColor: '#737373'
      }
    } else {
      borderStyle = {
        borderLeft: '0px',
        borderRadius: '0px 5px 5px 0px',
        borderColor: '#737373'
      }
    }
    const chevronColor = mobile ? 'black' : 'white'
    const mobilePosition = mobile ? {
      left: 0,
      position: 'fixed',
      top: 'auto',
    } : {}
    return {
      singleValue: (provided) => ({
        ...provided,
        color: mobile ? 'black' : 'white',
        backgroundColor: mobile ? '#DBDBDB' : '#737373',
        fontSize: mobile ? '11px' : ''
      }),
      control: (provided, state) => ({
        ...provided,
        ...borderStyle,
        color: 'white',
        backgroundColor: mobile ? '#DBDBDB' : '#737373',
        border: state.isFocused ? 0 : 0,
        height: mobile ? '36px' : '',
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
          border: state.isFocused ? 0 : 0
        }
      }),
      input: (provided) => ({
        ...provided,
        color: 'transparent'
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? chevronColor : chevronColor,
        '&:hover': {
          color: state.isFocused ? chevronColor : chevronColor,
        }
      }),
      option: (provided) => ({
        ...provided,
        backgroundColor: isPage ? '#707070' : 'white',
        backgroundClip: 'padding-box',
        padding: '0',
        borderBottom: '1px solid white',
        fontWeight: 'normal',
        '&:last-child': {
          border: 'none'
        }
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0,
        borderRadius: '6px',
      }),
      menu: (provided) => ({
        ...provided,
        ...mobilePosition,
        borderRadius: '8px',
        border: `2px solid ${isPage ? 'white' : 'black'}`,
        marginTop: 0,
        width: mobile ? 'auto' : (isPage ? '360px' : '380px'),
        zIndex: '999999',
        right: mobile ? '0' : (isPage ? '' : (detectIpad() ? 'unset' : '-20%')),
        top: mobile ? (gon.is_login ? '42px' : '75px') : 'auto',
        marginLeft: mobile ? '14px' : '',
        marginRight: mobile ? '14px' : ''
      }),
    }
  }

  handleOutSideClick = (e) => {
    this.setState({ shouldShowEditForm: false })
  }

  closePopupAddPageSuccess = () => {
    const { akaireFileId } = this.props;
    history.pushState({}, '', akaireFileId);
    this.setState({ shouldShowAddPageSuccess: false })
  }

  onChangePageSelf = (event) => {
    const { onChangePage, role } = this.props;
    onChangePage(event)
    if (canUserNotPermission(role) || role === 'reader') return
    if (this.selectAnimationRef) {
      setTimeout(() => {
        this.selectAnimationRef.select.focus()
        this.selectAnimationRef.onMenuOpen(() => true)
      }, 1200);
    }
  }

  onCheckCompareVersion = (version_id) => {
    var checkedOption = $('.checkbox-compare:checked')
    if(checkedOption.length == 0) {
      $('.button-compare-version').addClass('disabled')
    } else if(checkedOption.length > 0 && checkedOption.length <= 2) {
      $('.button-compare-version').removeClass('disabled')
    } else if(checkedOption.length > 2) {
      $(`.checkbox-compare[value=${version_id}]`).prop('checked', false)
      $('.alert-more-than-two-option').removeClass('d-none')
      setTimeout(() => {
        $('.alert-more-than-two-option').addClass('d-none')
      }, 3000);
    }
  }

  submitCompareVersion = () => {
    const { akaireFileId, currentPageId } = this.props;
    const checkedOption = $('.checkbox-compare:checked')
    const animations = this.animationsToOptions()
    if(checkedOption.length == 1) {
      if(this.checkIsCompareScreen()) {
        window.location.href = Routes.akaire_feature_akaire_file_path(akaireFileId, { page_id: currentPageId, animation_id: checkedOption.attr('value')})
      } else {
        const value = animations.find((item) => item.value.toString() == checkedOption.attr('value'))
        this.props.selectAnimation(value)
        this.selectAnimationRef.setState({menuIsOpen: false,})
      }
    } else if(checkedOption.length == 2) {
      window.location.href = Routes.compare_version_akaire_feature_akaire_file_path(
        akaireFileId,
        { page_id: currentPageId, animation_1: checkedOption[0].value, animation_2: checkedOption[1].value }
      )
    }
  }

  checkIsCompareScreen = () => {
    const { showMode } = this.props;
    return showMode == 'compare'
  }

  onError = (element) => {
    element.onerror = null;
    element.src = gon.assets.default_loading_thumbnail;
  }

  render() {
    const { pages, currentPageId, role, deletePage,
      currentAnimation, onClickFinalizeAnimation, onClickCancelFinalAnimation, akaireFileName,
      name, currentAnimationTotalSize, animations, page_last_animation, projectType, mobile } = this.props;
    const { shouldShowEditForm, shouldShowAddPageSuccess, valueForm } = this.state;
    const colorPTag = canCreateAndUpdatePage(role) ? '#fff' : '#878d92';
    const message_confirm = '指定ページの全てのバージョンが削除されます。宜しいですか？';
    const stylePTag = {
      marginBottom: '0',
      color: `${colorPTag}`
    }
    const dFlexItemsCenter = {
      display: 'flex',
      alignItems: 'center'
    }
    const iconDeleteStyle = {
      color: 'white',
      marginLeft: '10px',
      cursor: 'pointer',
      width: '14px',
      height: '18px',
      marginBottom: '-2px',
    }
    const iconYoutubeStyle = {
      color: 'white',
      marginLeft: '0px',
      width: 'fit-width',
      height: 'auto',
      marginBottom: '-2px',
      marginRight: '4px',
      width: '36px'
    }
    const iconVideoStyle = {
      color: 'white',
      marginLeft: '0px',
      width: 'fit-width',
      height: '18px',
      marginBottom: '0px',
      marginRight: '4px'
    }
    const iconCompareVersionStyle = {
      color: 'white',
      marginLeft: '0px',
      width: '24px',
      height: 'auto',
      marginBottom: '3px',
      marginRight: '4px',
    }
    const labelPageNameStyle = {
      color: 'white',
      whiteSpace: 'nowrap',
      marginRight: '5px'
    }
    const styleIconEditWhenCantUpdate = {
      marginLeft: '10px',
      color: 'white',
      width: '19px',
      height: '20px'
    }
    const styleImgThumbnailVersion = {
      height: '34px',
      width: '50px',
      borderRadius: '5px',
      border: '1px solid #707070'
    }
    const { Option } = components;
    const pageOption = props => (
      <Option {...props}>
        <div className={`d-flex justify-content-space-between align-items-center pd-page ${props.data.value == this.defaultPage().value ? 'high-light-page' : ''}`}>
          <p className='react-select-option page-option option_white'> {props.data.label} </p>
          {canCreateCommentPublicUser(role) && (
            <div className='d-flex'>
              <img src={iconPen} style={styleIconEdit} onClick={canCreateAndUpdatePage(role) ? (event) => this.open(event, props.data) : null} />
              {canDeletePage(role) && pages.length > 1 && !this.checkIsCompareScreen() ? <img onClick={(event) => { this.confirmDeletePage(props.data); event.stopPropagation(); }} src={iconBinWhite} style={iconDeleteStyle} /> : ''}
            </div>
          )}
        </div>
      </Option>
    );

    const versionMenu = props => (
      <Fragment>
        <components.Menu {...props}>
          {props.children}
          <div className='d-none d-xx-block'>
            <button className="button-compare-version font-weight-bold disabled" onClick={() => {this.submitCompareVersion(); }} onTouchStart={(event) => {this.submitCompareVersion(); event.stopPropagation();}}>
              <img src={compareVersion} style={iconCompareVersionStyle} /> Show version
            </button>
            <div className='alert-more-than-two-option d-none'>2個まで選択できます。</div>
          </div>
        </components.Menu>
      </Fragment>
    )

    const versionOption = props => (
      <Fragment>
        <components.Option {...props}>
          <div className={`d-flex justify-content-space-between align-items-center pd-version ${props.data.value === this.defaultVersion().value ? 'high-light-version' : ''}`}>
            <div className={'d-flex justify-content-space-between align-items-center w-100'}>
              <input type="checkbox" className="checkbox-compare d-none d-xx-block" value={props.data.value} onClick={(event) => {this.onCheckCompareVersion(props.data.value); event.stopPropagation(); }} />
              <p className={`react-select-option version-title ${this.checkFinalVersion() ? 'version-final' : '' }`}>{props.data.label == '最終稿' ? '最終稿' : `${props.data.label}`}</p>
              <img className='mx-1' src={props.data.set_thumbnail ? props.data.set_thumbnail : imgDefaultThumbnail} alt='thumbnail' style={styleImgThumbnailVersion} onError={({ currentTarget }) => this.onError(currentTarget)} />
              <div className='version-info'>
                <p className={`react-select-option align-items-center ${this.checkFinalVersion() ? 'w-200px' : 'w-230px'} ${projectType !== 'Video' ? 'text-ellipsis' : 'd-flex'}`}>
                  {projectType == 'Video'
                    ? <img src={props.data.video_embed ? iconYoutube : iconVideo} style={iconYoutubeStyle} />
                    : <img src={props.data.url_capture !== null ? iconWeb : iconPDF} style={iconVideoStyle} />
                  }
                  {projectType == 'Video' ?
                    ( props.data.video_embed
                      ? (<><a className={`text-underline text-primary text-ellipsis ${this.checkFinalVersion() ? 'max-width-90px' : 'max-width-100px'} d-inline-block`} target='_blank' href={props.data.url_capture}>{props.data.fileName}</a>｜{props.data.duration}</>)
                      : (<><span className={`text-primary text-ellipsis ${this.checkFinalVersion() ? 'max-width-90px' : 'max-width-100px'} d-inline-block`}>{props.data.fileName !== undefined ? props.data.fileName : ''}</span>｜{props.data.duration}</>)
                    )
                    : (props.data.url_capture !== null
                      ? (<a className='text-underline text-primary' target='_blank' href={props.data.url_capture}>{props.data.url_capture}</a>)
                      : (props.data.fileName)
                      )
                  }
                </p>
                <p className='react-select-option d-flex align-items-center'> <span className={`text-ellipsis ${this.checkFinalVersion() ? 'max-width-80px' : 'max-width-95px'}`}>{props.data.creatorName}</span>：<span>{props.data.createdAt}</span> </p>
              </div>
            </div>
            {
              !this.checkIsCompareScreen() && <img className='delete-version'
                onClick={(event) => { this.confirmDeleteVersion(props.data.value); event.stopPropagation(); }}
                src={iconBin} style={iconDeleteStyle} />
            }
          </div>
          <div className='border'> </div>
        </components.Option>
      </Fragment>
    );
    const pagesToOptions = this.pagesToOptions();
    const animationsToOptions = this.animationsToOptions();
    const styleIconEdit = canCreateAndUpdatePage(role) ? Object.assign({ cursor: 'pointer' }, styleIconEditWhenCantUpdate) : styleIconEditWhenCantUpdate
    const page = pages.find(_ => _.id == currentPageId)
    return (
      <div className={`d-flex page-control ${mobile ? 'mobile' : 'justify-content-space-between'} `}>
        {
          !mobile ? (
            <p className="text-header set-final">
              {
                !canUserNotPermission(role) && currentAnimation && !mobile && role !== 'reader' ? (
                  currentAnimation.final ? (
                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={onClickCancelFinalAnimation} href="javascript:void(0);">
                      { this.checkIsCompareScreen()
                        ? 'Verの最終稿を解除する'
                        : 'バージョンの最終稿を解除する'
                      }
                    </a>
                  ) : role != 'public_visitor' ? (
                    <a className="text-light" style={{ textDecoration: 'underline' }} onClick={onClickFinalizeAnimation} href="javascript:void(0);">
                      { this.checkIsCompareScreen()
                        ? '表示中のVerを最終稿にする'
                        : '表示中のバージョンを最終稿にする'
                      }
                    </a>
                  ) : ''
                ) : ''
              }
            </p>
          ) : ''
        }
        <div id="page-selector" style={dFlexItemsCenter} className='page-select'>
          {/* <span style={labelPageNameStyle}>ページ名</span> */}
          <Select
            isSearchable={ false }
            id="select-current-page-animation"
            className={`page-control-select ${mobile ? 'mobile' : ''}`}
            options={pagesToOptions}
            onChange={(event) => this.onChangePageSelf(event)}
            styles={this.customSelectStyle(true)}
            components={{
              IndicatorSeparator: () => null,
              Option: pageOption,
            }}
            value={this.defaultPage()}
            noOptionsMessage={() => null}
          />

          <Select
            isSearchable={ false }
            id="animation-selector"
            className={'version-control-select'}
            isDisabled={!canCreateAnimation(role, animations)}
            options={animationsToOptions}
            onChange={this.onChangeAnimationSelect}
            styles={this.customSelectStyle(false)}
            hideSelectedOptions={false}
            components={{
              IndicatorSeparator: () => null,
              Menu: versionMenu,
              Option: versionOption
            }}
            ref={(ref) => this.selectAnimationRef = ref}
            value={this.defaultVersion()}
            noOptionsMessage={() => null}
          />

          {/* <img src={iconPen} style={styleIconEdit} onClick={canCreateAndUpdatePage(role) ? this.open.bind(this, 'EditForm') : null} />
          { canDeletePage(role) ? <img onClick={() => {this.confirmDeletePage() }} src={iconBin} style={iconDeleteStyle} /> : '' } */}
        </div>
        <Modal isOpen={shouldShowEditForm} animation={false} className={'modal-dialog-centered modal-style confirm-submit-modal'} contentClassName={'text-center'}
          toggle={this.handleOutSideClick}>
          <Form onSubmit={this.submitForm}>
            <ModalBody>
              <h5 className="modal-title white-text">ページ名を変更する</h5>
              <Input type="text" className="mt-2 mb-3 max-width-400px m-0-auto" value={valueForm} autoFocus onChange={event => this.changeName(event)} />
              <Button type='submit' className="btn btn-primary ml-2" color='primary'>変更する</Button>
            </ModalBody>
          </Form>
        </Modal>

        <Modal isOpen={shouldShowAddPageSuccess} animation={false} className={'modal-dialog-centered modal-style confirm-submit-modal'} contentClassName={'text-center'}
          toggle={this.closePopupAddPageSuccess}>
          <ModalBody>
            <p className='mb-0'>{`${akaireFileName} の`}</p>
            <p className='mb-0'>{`${page?.title}にVer.${animations[0]?.title}を保存しました。`}</p>
            {this.linkOpenEditPage(page)} <br />
            <Button type='button' onClick={this.closePopupAddPageSuccess} className="btn btn-primary mt-2" color='primary'>OK</Button>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
