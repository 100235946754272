import Notify from "./shared/notify";

export default class Project {
  changeStatus(element, projectId, status) {
    if ($(element).hasClass('active')) return

    $('.modal-status-project.show').modal('hide')
    Popup.confirm('', {
      description: `プロジェクトのStatusを変更します。<br/>よろしいですか？`,
      success: () => {
        this._requestChangeStatus(projectId, status)
        $(`#modalStatus-${projectId}`).find('.status_popup_sp').find('li').removeClass('active')
      },
      cancel: () => {}
    })
  }

  showSearchProject() {
    if (gon.project_feature.is_search) {
      $('body').find('.show-hide-search-project').trigger('click');
    } else {
      $('body').find('.show-hide-search-project').prop('checked', false)
    }
  }

  openPopupUpdateNotifyStatus(project_id, current_status, bypass_confirm) {
    $('#offNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    $('#onNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    const bypass_after_setting = $('#tbody-list-project').attr('data-show-confirm-again')
    if((bypass_confirm == 'false' || bypass_confirm == '') && bypass_after_setting == 'false') {
      if(current_status) {
        $('#offNoticeStatus').modal('show')
      } else {
        $('#onNoticeStatus').modal('show')
      }
    } else {
      this.updateNotifyStatus($('#offNoticeStatus').find('.submit-update-status'), !current_status)
    }
  }

  updateNotifyStatus(element, status) {
    let project_id = $(element).attr('data-project-id')
    let url = Routes.update_notify_status_owner_project_path(project_id)
    Shared.callAjax(url, {status: status}, 'json', 'patch').done(function(data) {
      if(data.status) {
        $('#onNoticeStatus').modal('hide')
        $(`#status-${project_id}`).find('.accept-notify').removeClass("d-none")
        $(`#status-${project_id}`).find('.reject-notify').addClass("d-none")
      } else {
        $('#offNoticeStatus').modal('hide')
        $(`#status-${project_id}`).find('.accept-notify').addClass("d-none")
        $(`#status-${project_id}`).find('.reject-notify').removeClass("d-none")
      }
    })
  }

  _requestChangeStatus(projectId, status) {
    let project = { id: projectId, status }
    $.ajax({
      method: 'POST',
      url: Routes.owner_project_update_status_path(),
      dataType: 'json',
      data: { project },
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      }
    }).done((data) => {
      if (data['error'] !== undefined) {
        Notify.error(data['error'])
      } else {
        $('#show_project_status').text(data.project.status)
        $(`.td-status-${projectId}`).html(data.status_html)
        $(`#modalSelectStatus-${projectId}`).find('li').removeClass('active')
        $(`#modalSelectStatus-${projectId}`).find(`li[data-status='${status}']`).addClass('active')
        Popup.alert('ステータスは変更されました。')
      }
    }).fail((_) => Notify.error('エラーが発生しました。もう一度確認してください'))
  }

  openSettingAuthorityModal(resetValues = false) {
    if (resetValues) {
      $('#js_select_company_type_task').val('');
      $('#js_select_company_invites_task').empty();
      $('#js-select_users_invites').empty();
      $('#add_new_email_permission').val('');
      $('#add_new_email_permission_type').val('')
    }
    Shared.appendSelect2('#addPermissionProjectAuthor', '#js_select_company_invites_task')
    Shared.appendSelect2('#addPermissionProjectAuthor', '#js-select_users_invites')
    this.closePopupInviteMember()
    $("#addPermissionProjectAuthor").modal("show")
  }

  async deleteProject(projectId, projectName, deleteAtProjectsList = true) {
    const url = Routes.check_ticket_effort_not_release_owner_project_path(projectId)

    await Shared.callAjax(url, {}).done(function(data) {
      if (data.is_not_release) {
        const urlNotdelete = Routes.undelivered_works_efforts_path({ project_id: projectId })
        const urlDelete =
          Routes.deleted_works_efforts_path({search_kind: 'project_delete_id_cont', q: { kind_id: projectId } })
        Popup.confirm(
          `<div class="font-weight-normal">` +
          `<div class="text-center font-weight-bold">未納品作業が存在します。 </div>
          <span> プロジェクトを削除すると、関連書類及び未納品作業データ以外は全て削除されます。
              <a class="text-underline white-color font-weight-bold" target="_blank" href="${urlNotdelete}">未納品作業一覧ページ</a>より未納品作業をご確認の上、削除を行ってください。
              プロジェクト削除後、10日間は<a class="text-underline white-color font-weight-bold" target="_blank" href="${urlDelete}">削除一覧ページ</a>より未納品作業のStatus変更が可能です。
              プロジェクト削除後、10日を超過すると未納品作業のStatus変更は出来ません。
              ※プロジェクトと紐づいている書類は削除されません。書類管理から削除できます。
            </span>
          </div>`, {
          success: () => { deleteProject(projectId, deleteAtProjectsList) },
          cancel: () => {},
          confirmText: I18n.t('shared.delete'),
          cancelText: I18n.t('shared.cancel'),
          width: 'auto'
        })
      } else {
        Popup.confirm(`<div class="font-weight-normal">「${projectName}」を削除します。プロジェクト内のデータが全て削除されます。
          削除後は復元できません。削除してよろしいですか？</div>`, {
          success: () => { deleteProject(projectId, deleteAtProjectsList) },
          cancel: () => {},
          confirmText: 'OK',
          cancelText: I18n.t('shared.cancel'),
        })
      }
    })
    .fail(function(){
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  newProjectWithMaxQuantity(isCreate, isAkapon) {
    if (isCreate === 'true') return

    event.preventDefault();
    if (isAkapon) {
      $( "#errorModalForProject" ).modal('show');
    } else {
      Popup.alert(I18n.t('projects.cannot_create_with_plan'))
    }
  }

  addUserInvite(element) {
    const types = { owners: '自社', suppliers: '仕入先', clients: '販売先', collaborators: '外部' };
    const inviteUser = $('#js-select_users_invites').val();
    const inviteType = $(element).parent().find('#js_select_company_type_task').val();
    const userName = $('#js-select_users_invites option:selected').text();
    const companyId = $('#js_select_company_invites_task :selected').val();
    const companyName = $(element).parent().find("#js_select_company_invites_task option:selected").text();

    if(inviteUser){
      if($("#popupInviteMember").find(".permission-row").find(`[data-user-id="${inviteUser}"]`).length) {
        $("#popupInviteMember").find(".permission-row").find(`[data-user-id="${inviteUser}"][value="${inviteType.slice(0,-1)}"]`).prop("checked", true).trigger("change")
      } else {
        var elementAdd = this.permissionRow(inviteUser, userName, companyName, $('#js_select_company_type_task').val());
        $("#popupInviteMember").find(".permission-row").append(elementAdd);
      }

      $('#popupInviteMember').find('#element-is-change').val('true')
      $('#popupInviteMember').removeClass('d-none')
      $("#addPermissionProjectAuthor").modal("hide")
      $('#js-select_users_invites').find(`option[value="${inviteUser}"]`).remove();
      event.stopPropagation();
    }
  }

  openModalInviteByEmail() {
    $("#inviteByEmail").modal('show')
    $('#inviteByEmail').find('#permission_email').val('')
    if (!!$('#js_select_company_type_task').val()) {
      const permissionType = $('#js_select_company_type_task').val().slice(0, -1);
      const titles = { owner: '自社メンバーを招待する', supplier: '仕入先メンバーを招待する', client: '販売先メンバーを招待する', collaborator: '外部メンバーを招待する' }
      $('#add_new_email_permission_type').val(permissionType)
      $('#add_new_email_permission_type').addClass('d-none');
      $('#permission_type_label').addClass('d-none');
      $('#add_new_member_title').text(titles[permissionType])
    }
  }

  openModalInviteMemberOfPartner() {
    $("#addPermissionProjectAuthor").modal('hide')
    $("#inviteExistUserByEmail").modal('show')
    $("#inviteExistUserByEmail").find('#permission_name').val('')
    $("#inviteExistUserByEmail").find('#permission_email').val('')
  }

  handleCheckToAddPermission(element, userID){
    $('#popupInviteMember').find('#element-is-change').val('true')
    if($(element).is(":checked")) {
      const modalEle = $(element).parents('#popupInviteMember')
      const listMemberCheck = modalEle.find('input.check-box-check-member:checkbox:checked').toArray()
      let companyClientChecked = [];
      listMemberCheck.forEach(input => {
        if ($(input).val() === 'client' && $(input).is(':checked') && $(input).attr('data-invited-by-owner') === 'true') {
          companyClientChecked.push($(input).data('company-id'))
        }
      })
      let uniqueArray = [...new Set(companyClientChecked)];
      if (uniqueArray.length > 1 && element.value == 'client') {
        Popup.alert('複数の販売先が招待できません。')
        $(element).prop('checked', false)
        return
      }
      if ($(element).parents('.checkbox_noborder_intable').hasClass('c-red-check')) {
        $(element).parents('.show_permission_tr').attr('is-new-add', 'true')
        this.showEleFilterNewAdd()
      }
      $(element).parent().parent().parent().find('.label-checkbox').removeClass("disabled")
    } else {
      if ($(element).parents('.checkbox_noborder_intable').hasClass('c-red-check')) {
        $(element).parents('.show_permission_tr').removeAttr('is-new-add')
        this.showEleFilterNewAdd(false)
      }
      $(element).parent().parent().parent().find('.label-checkbox').addClass('disabled')
    }

    const dataUserDelete = $(".list-user-delete");
    if(dataUserDelete.length > 0) {
      if($(element).is(":checked")) {
        $(".list-user-delete").val(dataUserDelete.val().replace(`${userID},`, ''));
      } else {
        $(".list-user-delete").val(`${dataUserDelete.val()}${userID},`)
      }
    }
  }

  handelCheckPermission(element, userID, userName) {
    Popup.confirm(
    `${userName}さんを招待解除すると、
      このプロジェクトに参加できなくなります。
      解除してよろしいですか？`, {
      success: () => {
        const dataUserDelete = $(".list-user-delete").val();
        $(".list-user-delete").val(`${dataUserDelete}${userID},`)
        $(`tr.tr-${userID}`).remove()
      },
      cancel: () => {},
      confirmText: '解除する',
      cancelText: '後で行う'
    })
  }

  handelCheckRequest(element, requestId, userName) {
    Popup.confirm(
    `${userName}さんを招待解除すると、
      このプロジェクトに参加できなくなります。
      解除してよろしいですか？`, {
      success: () => {
        const dataRequestDelete = $(".list-request-delete").val();
        $(".list-request-delete").val(`${dataRequestDelete}${requestId},`)
        $(`tr.tr-request-${requestId}`).remove()
      },
      cancel: () => {},
      confirmText: '解除する',
      cancelText: '後で行う'
    })
  }

  showListNewAdd(ele) {
    const tbody = $('#popupInviteMember').find('tbody.permission-row')
    const listTr = tbody.find('tr.show_permission_tr').toArray()
    const numerCurrentPageMember = $('#popupInviteMember').find('.render_pagy_of_list_member').find('.page-item.active').attr('data-index')
    if ($(ele).attr('is-show') && $(ele).attr('is-show') === 'true') {
      listTr.forEach(tr => {
        if($(tr).attr('data-page') === numerCurrentPageMember || $(tr).hasClass('tr-new-email')) {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'false')
      const text = $(ele).data('text')
      const number = $(ele).attr('data-number')
      $(ele).text(`${number} ${text}`)
      $('#popupInviteMember').find('.new-style-with-js').removeClass('d-none')
    } else {
      listTr.forEach(tr => {
        if ($(tr).attr('is-new-add') && $(tr).attr('is-new-add') === 'true') {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'true')
      $(ele).text($(ele).attr('data-text-hide'))
      $('#popupInviteMember').find('.new-style-with-js').addClass('d-none')
    }
    document.getElementById("show-list-member-author-new").scrollIntoView();
  }

  async submmitListMember(ele) {
    const form = $(ele).parents('form')
    await Shared.reDrawDataTable()
    form.find('#submit-update-project-permissions').trigger('click')
  }

  showEleFilterNewAdd(isPositive = true) {
    const spanShow = $('#popupInviteMember').find('.show-number-member-add')
    const text = spanShow.data('text')
    const number = isPositive ? Number(spanShow.attr('data-number')) + 1 : Number(spanShow.attr('data-number')) - 1
    spanShow.attr('data-number', number)
    if (spanShow.attr('is-show') === 'false') {
      spanShow.text(`${number} ${text}`)
    } else {
      spanShow.text(spanShow.attr('data-text-hide'))
    }
    number === 0 ? spanShow.addClass('d-none') : spanShow.removeClass('d-none')
  }

  changeEachPageNew(ele, idModal ,numberItem) {
    const modal = $(ele).parents('.popup-invite-member')
    $(idModal).find('.option').removeClass('slted')
    $(idModal).find(`div[data-page="${numberItem}"]`).addClass('slted')
    $(idModal).find('.show-number-of-page-js').text(numberItem)
    $('#popupInviteMember').find('.show-number-of-page-js').text(numberItem)
    $(idModal).modal('hide')
    modal.find('.dt-length').find('.dt-input').val(numberItem).trigger('change');
  }

  permissionRow(userId, userName, companyName, type) {
    const types = { owners: '自社メンバー', suppliers: '仕入先メンバー', clients: '販売先メンバー', collaborators: '外部メンバー' }
    return `<tr class="show_permission_tr">
      <td>
        <label class="checkbox_noborder_intable">
          <input type="checkbox" data-user-id="${userId}" value="collaborator" name="permission[${userId}]" checked="">
          <span class="checkbox_text"></span>
        </label>
      </td>
      <td class="py-1 px-1 px-xx-2 text-center">${types[type]}</td>
      <td class="py-1 px-1 px-xx-2 text-ellipsis">${companyName}</td>
      <td class="py-1 px-1 px-xx-2 text-ellipsis">${userName}</td>

      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[project[super]]]]" type="hidden" value="false"><input id="permission_${userId}_project_supper" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" name="user_permissions[${userId}[roles[project[super]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[task[create]]]]" type="hidden" value="false"><input id="permission_${userId}_task_create" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" checked="checked" name="user_permissions[${userId}[roles[task[create]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[chat[admin_project]]]]" type="hidden" value="false"><input id="permission_${userId}_chat_admin_project" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" name="user_permissions[${userId}[roles[chat[admin_project]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[chat[group_project]]]]" type="hidden" value="false"><input id="permission_${userId}_chat_group_project" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" name="user_permissions[${userId}[roles[chat[group_project]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[chat[task]]]]" type="hidden" value="false"><input id="permission_${userId}_chat_task" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" checked="checked" name="user_permissions[${userId}[roles[chat[task]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[chat[ticket]]]]" type="hidden" value="false"><input id="permission_${userId}_chat_ticket" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" name="user_permissions[${userId}[roles[chat[ticket]]]]">
        <span class="checkbox_text"></span>
      </label></td>
      <td class="d-none d-md-table-cell"><label class="checkbox_noborder_intable">
        <input name="user_permissions[${userId}[roles[chat[akaire_file]]]]" type="hidden" value="false"><input id="permission_${userId}_chat_akaire_file" class="mr-2 scale-2 user_permission_checbox" type="checkbox" value="true" name="user_permissions[${userId}[roles[chat[akaire_file]]]]">
        <span class="checkbox_text"></span>
      </label></td>
    </tr>`
  }

  prevPagePermission(ele) {
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    const numberPage = Number(pageSelected.attr('data-index'))
    if (numberPage <= 1) return
    pageSelected.removeClass('active')
    ulEle.find(`li[data-index="${numberPage - 1}"]`).addClass('active')
    const listTr = document.querySelector('tbody.permission-row').querySelectorAll('tr.show_permission_tr_list')
    for (let i = 0; i < listTr.length; i++) {
      listTr[i].style.display = 'none';
    }
    const listTrShow = document.querySelectorAll(`tr[data-page="${numberPage - 1}"]`)
    for (let i = 0; i < listTrShow.length; i++) {
      listTrShow[i].style.display = '';
    }
  }

  nextPagePermission(ele, maxPage) {
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    const numberPage = Number(pageSelected.attr('data-index'))
    if (numberPage === Number(maxPage)) return
    pageSelected.removeClass('active')
    ulEle.find(`li[data-index="${numberPage + 1}"]`).addClass('active')
    const listTr = document.querySelector('tbody.permission-row').querySelectorAll('tr.show_permission_tr_list')
    for (let i = 0; i < listTr.length; i++) {
      listTr[i].style.display = 'none';
    }
    const listTrShow = document.querySelectorAll(`tr[data-page="${numberPage + 1}"]`)
    for (let i = 0; i < listTrShow.length; i++) {
      listTrShow[i].style.display = '';
    }
  }

  selectPagePermission(ele) {
    const page = $(ele).attr('data-index')
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    pageSelected.removeClass('active')
    ulEle.find(`li[data-index="${page}"]`).addClass('active')
    const listTr = document.querySelector('tbody.permission-row').querySelectorAll('tr.show_permission_tr_list')
    for (let i = 0; i < listTr.length; i++) {
      listTr[i].style.display = 'none';
    }
    const listTrShow = document.querySelectorAll(`tr[data-page="${page}"]`)
    for (let i = 0; i < listTrShow.length; i++) {
      listTrShow[i].style.display = '';
    }
  }

  async changeNumberPage(ele, totalObject, numberOfPage, parentElement = '#popupInviteMember') {
    const widthWindow = $(window).width()
    const modalEle = ele.closest(`div${parentElement}`)
    const totalEle = Number(totalObject)
    const pagynate = await Shared.fetchPagynateWithJs(totalEle, numberOfPage)
    let listTr = modalEle.querySelectorAll('tr.show_permission_tr_list')
    $(ele).parent().find('.option').removeClass('slted');
    $(ele).addClass('slted')
    $('.show-number-of-page-js').html(numberOfPage)
    if (widthWindow <= 768) $(ele).closest('div.modal').modal('hide')
    if (numberOfPage > 30 && widthWindow <= 768) {
      Shared.loadingIndicator()
      await this.renderPages(listTr, pagynate, modalEle, numberOfPage)
      return
    }
    for (let i = 1; i <= pagynate; i++) {
      let numberEleOfPage = 0;
      if (numberEleOfPage === numberOfPage) {
        numberEleOfPage = 0
        continue
      }
      for (let x = 0; x < listTr.length; x ++) {
        listTr[x].style.display = 'none'
        const isCalculator = listTr[x].getAttribute('calculator-page')
        if (numberEleOfPage < numberOfPage && !isCalculator) {
          listTr[x].setAttribute('calculator-page', 'true')
          listTr[x].setAttribute('data-page', i)
          numberEleOfPage += 1
        }
      }
    }
    for (let i = 0; i < listTr.length; i++) {
      listTr[i].removeAttribute('calculator-page')
    }
    const listPageOne = modalEle.querySelectorAll('tr[data-page="1"]')
    for (let i = 0; i < listPageOne.length; i++) {
      listPageOne[i].style.display = '';
    }
    const newPagy = await Shared.reRenderPagyNate(
      pagynate, 'Project.prevPagePermission(this)', 'Project.selectPagePermission(this)',
      `Project.nextPagePermission(this, '${pagynate}')`
    )
    document.getElementById('render-list-pagynate-of-member-project-author').innerHTML = newPagy
  }

  async renderPages(listTr, pagynate, modalEle, numberOfPage) {
    for (let i = 1; i <= pagynate; i++) {
      let numberEleOfPage = 0;
      if (numberEleOfPage === numberOfPage) {
        numberEleOfPage = 0
        continue
      }
      for (let x = 0; x < listTr.length; x ++) {
        const isCalculator = listTr[x].getAttribute('calculator-page')
        if (numberEleOfPage < numberOfPage && !isCalculator) {
          listTr[x].setAttribute('calculator-page', 'true')
          listTr[x].setAttribute('data-page', i)
          numberEleOfPage += 1
        }
      }
    }
    let newListTr = await this.chunkArray(Array.from(listTr), 10)
    listTr = null
    await this.removeInTr(newListTr)
    newListTr = null
    const newPagy = await Shared.reRenderPagyNate(
      pagynate, 'Project.prevPagePermission(this)', 'Project.selectPagePermission(this)',
      `Project.nextPagePermission(this, '${pagynate}')`
    )
    document.getElementById('render-list-pagynate-of-member-project-author').innerHTML = newPagy
    $('.preloader').fadeOut();
  }

  async removeInTr(chunkedArrays) {
    for (const array of chunkedArrays) {
      requestAnimationFrame(() => {
        for (let i = 0; i < array.length; i++) {
          array[i].removeAttribute('calculator-page')
          if (array[i].getAttribute('data-page') === '1') {
            if (array[i].style.display !== '') array[i].style.display = ''
          } else {
            if (array[i].style.display !== 'none') array[i].style.display = 'none'
          }
        }
      });
      await new Promise(resolve => {
        setTimeout(resolve, 600)
      })
    }
  }

  async chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  changeDataOfObjectWithChangeNumberPage(pagynate, listTr, numberOfPage) {
    for (let i = 1; i <= pagynate; i++) {
      let numberEleOfPage = 0;
      if (numberEleOfPage === numberOfPage) {
        numberEleOfPage = 0
        continue
      }
      for (let x = 0; x < listTr.length; x ++) {
        const isCalculator = listTr[x].getAttribute('calculator-page')
        if (numberEleOfPage < numberOfPage && !isCalculator) {
          listTr[x].setAttribute('calculator-page', 'true')
          listTr[x].setAttribute('data-page', `${i}`)
          numberEleOfPage += 1
        }
      }
    }
  }

  checkAllOrCanCelCheckAllMember(ele) {
    if($(ele).is(':checked')) {
      this.checkAllMemberOfProject(ele)
    } else {
      this.unCheckAllMemberOfProject(ele)
    }
  }

  checkAllMemberOfProject(ele) {
    const modalEle = $(ele).parents('div#popupInviteMember')
    const isMultipleClient = $(ele).data('is-multiple-client')
    if (isMultipleClient) {
      Popup.alert('複数の販売先が招待できません。')
      $(ele).prop('checked', false)
      return
    }
    const listObjectEle = modalEle.find('tr.show_permission_tr_list').toArray()
    listObjectEle.forEach((tr) => {
      const checkMemberEle = $(tr).find('input.check-box-check-member')
      if (!checkMemberEle.is(':checked')) {
        checkMemberEle.trigger('click')
      }
    })
  }

  unCheckAllMemberOfProject(ele) {
    const modalEle = $(ele).parents('div#popupInviteMember')
    const listObjectEle = modalEle.find('tr.show_permission_tr_list').toArray()
    const notUncheck = $(ele).data('not-uncheck')
    const dataUserDelete = $('.list-user-delete');
    listObjectEle.forEach((tr) => {
      const checkMemberEle = $(tr).find('input.check-box-check-member')
      if (checkMemberEle.is(':checked') && !notUncheck.includes(Number(checkMemberEle.data('user-id')))) {
        dataUserDelete.val(`${dataUserDelete.val()}${Number(checkMemberEle.data('user-id'))},`)
        checkMemberEle.prop('checked', false)
      }
    })
  }

  openModalCreateTask(projectId, count_task, text_task_akaire, project_name) {
    const modalCreate = $("#createTaskProjectModal");
    modalCreate.attr('data-project-id', projectId)
    modalCreate.find(".btn-project-new.task").attr('href', Routes.owner_new_tasks_path(projectId))
    modalCreate.find("#taskName").val(`${text_task_akaire}${count_task + 1}`)
    modalCreate.find('.title-project-name').html(project_name)
    modalCreate.find('form').attr('action', Routes.create_from_project_list_owner_project_tasks_path(projectId))
    modalCreate.modal('show')
  }

  openModalCreateTaskFromTaskList(projectId, count_task, text_task_akaire, isAkapon) {
    let modalCreateId = ''
    if (isAkapon == 'true') {
      modalCreateId = '#selectProjectTaskModalAkapon'
      let modalCreate = $(modalCreateId);
      modalCreate.find('#selectProjectId').val(projectId).change()
      modalCreate.find('.edit-project-name').addClass('disabled-edit-project-name')
      this.switchToCreateTask()
      modalCreate.find('.last-step').attr('onclick', "$('#selectProjectTaskModalAkapon').modal('hide')")
      $('#listTaskModal').modal('hide')
      modalCreate.modal('show')
    } else {
      modalCreateId = '#createTaskFromListTaskModal'
      let modalCreate = $(modalCreateId);
      modalCreate.attr('data-project-id', projectId)
      modalCreate.find(".btn-project-new.task").attr('href', Routes.owner_new_tasks_path(projectId))
      modalCreate.find("#taskName").val(`${text_task_akaire}${count_task + 1}`)
      modalCreate.find('form').attr('action', Routes.create_from_project_list_owner_project_tasks_path(projectId))
      $('#listTaskModal').modal('hide')
      modalCreate.modal('show')
    }

    $(modalCreateId).on('hidden.bs.modal', function () {
      $('#listTaskModal').modal('show')
    })
  }

  openCreateProjectModal(isCreate, isAkapon) {
    if (isCreate === 'true' || isCreate === true) {
      $('#CreateProjectOptionsModal').modal('show');
    } else {
      Shared.showModalForElement($('#errorModalForProject'));
    }
  }

  createProjectWithoutFile() {
    $('#CreateProjectOptionsModal').modal('hide');
    const modal = $('#createProjectModal');
    modal.find('.dismiss-modal').addClass('d-none');
    modal.find('.show-again-area').addClass('d-none');
    modal.modal('show');
  }

  switchToCreateProjectTask(isCreate, isAkapon) {
    if (isCreate === 'true') {
      $('#selectProjectTaskModalAkapon').modal('hide')
      $('#createProjectModalAkapon').modal('show')
      $('#createProjectModalAkapon').find('.show-again-area').addClass('d-none')

      $('#createProjectModalAkapon').find('.step-create-project').html('１，赤入れのプロジェクトを作成してください。')
      $('#createProjectModalAkapon').find('.step-create-task').html('２，新しい赤入れを作成してください。')

      const buttonBack = $('#createProjectModalAkapon').find('.back-to-last-step');
      buttonBack.attr('data-is-switch', 'true')
      buttonBack.attr('onclick', 'Project.switchToCreateProject()')
      buttonBack.removeClass('d-none')
      return
    }

    event.preventDefault();
    if (isAkapon) {
      $( "#errorModalForProject" ).modal('show');
    } else {
      Popup.alert(I18n.t('projects.cannot_create_with_plan'))
    }
  }

  switchToCreateProject() {
    $('#selectProjectTaskModalAkapon').modal('show')
    $('#createProjectModalAkapon').modal('hide')
  }

  submitQuickCreateTask(modal_id){
    const modalCreate = $(modal_id);
    const action = modalCreate.find('form').attr('action')
    modalCreate.find('form').find('#submit-create-task').click()
  }

  showSettingPicProject(projectId) {
    const url = Routes.project_participants_owner_project_path(projectId)
    Shared.callAjax(url, {}, 'script', 'GET')
  }

  showListTaskProject(element, projectId, item) {
    let data = {}
    if( element ) data = { items_each_page: item }
    const url = Routes.get_list_task_of_project_owner_project_path(projectId)
    Shared.callAjax(url, data, 'script', 'get')
  }

  showListAkaireFileProject(element, projectId, item) {
    let data = {}
    if( element ) data = { items_each_page: item }
    const url = Routes.get_list_akaire_file_of_project_owner_project_path(projectId)
    Shared.callAjax(url, data, 'script', 'get')
  }

  enableButtonSubmit(element, id){
    const value = $(element).val()
    if(value.length > 0){
      $(id).find(".submit-create").removeClass('disabled')
    } else {
      $(id).find(".submit-create").addClass('disabled')
    }
  }

  hideSettingMemberModal() {
    if($('#popupInviteMember').find('#element-is-change').val() == 'true') {
      Popup.confirm(
        `<div>選択済みのメンバーはリセットされます。<br>キャンセルしてよろしいですか？</div>`,
        {
          success: () => {
            Visiable.hide('#popupInviteMember')
            $('.modal-backdrop').remove();
          },
          cancel: () => {},
          cancelBtn: 'true',
          confirmText: 'OK',
          cancelText: 'キャンセル',
          width: 'auto'
        }
      )
    } else {
      Visiable.hide('#popupInviteMember')
      $('.modal-backdrop').remove();
    }
  }

  hideSettingMemberModalAfterCreate(project_id) {
    Popup.confirm(
      `<div>選択済みのメンバーはリセットされます。<br>初期設定時のメンバーのみが招待されます。 <br>キャンセルしてよろしいですか？</div>`,
      {
        success: () => {
          const url = Routes.owner_send_mail_to_default_permission_path(project_id)
          Shared.callAjax(url, {}).done(function(data) {
            $('.setting_member_after_create').remove();
            $('.modal-backdrop').hide();
          })
        },
        cancel: () => {
          $('#popupInviteMember').show();
        },
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto'
      }
    )
  }

  settingShowAgainNewProject(element) {
    const url = Routes.setting_show_again_new_project_owner_users_path()
    const check_status = $(element).is(":checked")
    Shared.callAjax(url, { checked: check_status }, 'script', 'POST')
  }

  settingShowAgainConfirmTypeTask(element) {
    const url = Routes.setting_show_again_confirm_type_task_owner_users_path()
    const check_status = $(element).is(":checked")
    Shared.callAjax(url, { checked: check_status }, 'script', 'POST')
  }

  settingNotifyShowAgain(element, type) {
    const url = Routes.setting_show_again_notify_owner_users_path()
    const check_status = $(element).is(":checked")
    $(`#tbody-list-${type}`).attr('data-show-confirm-again', check_status)
    Shared.callAjax(url, { checked: check_status, type: type }, 'script', 'POST')
  }

  getCompanyWithType(element) {
    var type_text = {
      owners: { type: 'owner', text: '自社' },
      suppliers: { type: 'supplier', text: '仕入先' },
      clients: { type: 'client', text: '販売先' },
      collaborators: { type: 'collaborator', text: '外部' }
    }
    let project_id = $(element).attr('project_id')
    const company_type = $(element).val();
    let company_type_text = I18n.t(`shared.company_types.${company_type}`);

    if (company_type !== 'owners') {
      $('#inviteExistUserByEmail').find('#permission_type').val(type_text[company_type].type)
      $('#js_select_company_invites_task').empty();
      $('#js_select_company_invites_task').append('<option value="">会社を選択</option>');
      if (!(company_type == 'clients' && $(element).attr('has_client') == 'true')) {
        $('#js_select_company_invites_task').append(`<option value="invite_company">新規に${company_type_text}メンバーを招待する</option>`);
      }
      if (!$('#select_current_company').hasClass('d-none')) $('#select_current_company').addClass('d-none')
      $('#select_company_by_type').removeClass('d-none')
      $('#button_add_new_member').addClass('d-none').css('width', '100%')
    } else {
      $('#select_current_company').removeClass('d-none')
      $('#select_company_by_type').addClass('d-none')
      $('#button_add_new_member').removeClass('d-none').css('width', 'calc(100% - 30px)')
    }

    if(company_type == 'clients' && $(element).attr('has_client') == 'true') {
      $('.invite-by-email-button').addClass('d-none')
    } else {
      $('.invite-by-email-button').removeClass('d-none')
    }
    $('#js-select_users_invites').empty().append(`<option value="">ユーザーを選択</option>${ company_type !== 'owners' ? '<option value="invite_partner_member">新規に招待する</option>' : ''}`);

    const url = Routes.owner_get_company_by_company_type_path(project_id);
    const data = {
      'company_type': $(element).val()
    };
    this._handleFetchData(url, data, 'company');
    $('#button_add_member_of_partner').addClass('d-none')
    if (company_type === 'owners') {
      setTimeout(function() {
        $('#js_select_company_invites_task').val($("#js_select_company_invites_task option:last").val()).trigger('change')
        $('#js-select_users_invites').children().first().after('<option value="invite_member">新規に自社メンバーを招待する</option>')
      }, 300)
    }
  }

  getUserWithCompany(ele) {
    let project_id = $(ele).attr('project_id');
    let company_id = $(ele).val();
    let company_type = $('#js_select_company_type_task').val();
    let company_name = $(ele).find(`[value=${company_id}]`).text()
    if(company_type == 'owners') {
      $('#button_add_member_of_partner').addClass('d-none')
    } else {
      $('#button_add_member_of_partner').removeClass('d-none')
    }
    if (company_id === 'invite_company') {
      $(ele).closest('#select_company_by_type').find('.btn.btn-mint').trigger('click');
      return;
    }
    $('#inviteExistUserByEmail').find('#company_id').val(company_id)
    $('#inviteExistUserByEmail').find('#add_new_member_title').html(`${company_name} メンバー招待`)
    const url = Routes.owner_list_user_not_user_permission_path(project_id);
    const data = {
      'company_id': company_id,
      'company_type': company_type
    };
    $('#js-select_users_invites').empty().append(`<option value="">ユーザーを選択</option>${company_type !== 'owners' ? '<option value="invite_partner_member">新規に招待する</option>' : ''}`);
    this._handleFetchData(url, data, 'user')
  }

  removeDisabledBtnInvite(element) {
    const modalEle = $(element).parents('div.modal-body');
    const companySelect = modalEle.find('#js_select_company_invites_task').val()
    const userSelect = modalEle.find('#js-select_users_invites').val()
    if (companySelect && companySelect !== '' && userSelect && userSelect !== '') {
      $(element).parents('div.modal-body').find('#inviteAdd').removeClass('disabled');
    } else {
      $(element).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
    }
  }

  _handleFetchData(url, dataAjax, dataType) {
    const project = this
    const idRenderData = dataType === 'company' ? 'js_select_company_invites_task' : 'js-select_users_invites'
    Shared.callAjax(url, dataAjax).done(function(data) {
      if (data.length === 0) return
      let hasSetDefault = false;
      let dataRender = data.map(user => {
        if(user.default_manager && !hasSetDefault) {
          hasSetDefault = true
          return `<option selected="selected" value="${user.id}">${user.name}</option>`
        }
        else return `<option value="${user.id}">${user.name}</option>`
      })
      $(`#${idRenderData}`).append(dataRender.join(""));
      project.removeDisabledBtnInvite($(`#${idRenderData}`))
    }).fail(function(data) {
      Popup.alert(I18n.t('task.fetch_company_error'), 'warning');
    })
  }

  openAddPicModal(resetValues = false) {
    if (resetValues) {
      $('#js_select_company_type_task').val('');
      $('#js_select_company_invites_task').empty();
      $('#js-select_users_invites').empty();
      $('#add_new_email_permission').val('');
      $('#add_new_email_permission_type').val('')
    }

    if($('#js-select_users_invites').val() == 'invite_member') {
      $('#js-select_users_invites').val('');
    }

    if ($('#js_select_company_invites_task').val() == 'invite_company') {
      $('#js_select_company_invites_task').val('');
    }

    $("#personInChargeOfObject").modal("hide")
    Shared.appendSelect2('#addPermissionProjectAuthor', '#js_select_company_invites_task')
    Shared.appendSelect2('#addPermissionProjectAuthor', '#js-select_users_invites')
    $("#addPermissionProjectAuthor").modal("show")
    $("#addPermissionProjectAuthor").modal("show")
  }

  nextStepCreateTask(element, modal_id) {
    const parent = $(element).closest(modal_id);
    const projectName = parent.find('#projectName').val()
    const data = { name: projectName, id: parent.find('#current_project_id').val() }
    const url = Routes.quick_create_owner_projects_path()
    const project = this;
    Shared.callAjax(url, { project: data }, 'json', 'post')
    .done(function(data) {
      parent.find(".btn-project-new.task").attr('href', Routes.owner_new_tasks_path(data.project_id, {transit_akaire: 'true'}))
      parent.find('.submit-create').removeClass('d-none');
      parent.find('.project-area').addClass('d-none');
      parent.find('.project-preview').removeClass('d-none');
      parent.find('.task-area').removeClass('d-none');
      parent.find('.next-step').addClass('d-none');
      parent.find('.back-to-last-step').attr('onclick', "Project.resetProjectName('#createProjectModalAkapon')")
      parent.find('.back-to-last-step').removeClass('d-none')
      parent.find('#current_project_id').val(data.project_id)
      parent.find('.project-name-preview').html(project.truncateProjectName(projectName));
    }).fail(function(response) {
      if (!!response.responseJSON['error_quantity']) {
        $('#errorModalForProject').modal('show')
        $('#createProjectModalAkapon').modal('hide')
      }
    })
  }

  resetProjectName(modal_id) {
    const parent = $(modal_id);
    const buttonBack = parent.find('.back-to-last-step');
    parent.find('.submit-create').addClass('d-none');
    parent.find('.project-area').removeClass('d-none');
    parent.find('.project-preview').addClass('d-none');
    parent.find('.task-area').addClass('d-none');
    parent.find('.next-step').removeClass('d-none')
    if(buttonBack.attr('data-is-switch', 'true')) {
      buttonBack.attr('onclick', 'Project.switchToCreateProject()')
    } else {
      buttonBack.addClass('d-none')
    }
  }

  selectExistProject(element, text_task_akaire) {
    const modal = $('#selectProjectTaskModalAkapon')
    const project_id = $(element).val();
    const project = this;
    if(project_id) {
      const project_name = $(element).find("option:selected").text();
      modal.find('.project-area').addClass('d-none');
      modal.find('.project-preview').removeClass('d-none');
      modal.find('.project-name-preview').html(project.truncateProjectName(project_name))
      modal.find('.show-again-area').addClass('d-none')

      const url = Routes.get_list_task_of_project_owner_project_path(project_id)
      Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
        const option = modal.find('#selectTaskId');
        option.empty().append(`<option value=''>${text_task_akaire}名から検索</option>`)
        modal.find('#taskName').val(`${text_task_akaire}${data.tasks.length + 1}`)
        modal.find('.next-step').removeClass('d-none')
        modal.find('.last-step').removeClass('d-none')
        modal.find(".btn-project-new.task").attr('href', Routes.owner_new_tasks_path(project_id, {transit_akaire: 'true'}))
        if(data.can_add_task) modal.find('.hint-and-transit-create').removeClass('disabled')
        var tasks = data.tasks;
        for(let i = 0; i < tasks.length; i++){
          option.append(`<option value="${tasks[i].id}" data-category="${tasks[i].category}">${tasks[i].name}</option>`);
        }
        Shared.appendSelect2Task('#selectProjectTaskModalAkapon', '#selectTaskId')
      })
    }
  }

  resetSelectExistProject() {
    const modal = $('#selectProjectTaskModalAkapon')
    modal.find('.project-area').removeClass('d-none');
    modal.find('.project-preview').addClass('d-none');
    modal.find('.next-step').addClass('d-none')
    modal.find('.next-step').addClass('disabled');
    modal.find('.last-step').addClass('d-none')
    modal.find('.project-name-preview').html('');
    modal.find('#task-name-select').val('');
    modal.find('#selectProjectId').val('').change();
    modal.find('#selectTaskId').empty().append('<option value="">赤入れ名から検索</option>');
    modal.find('.task-category').empty()
    modal.find('.select-task-area .hint-and-transit-create').addClass('disabled')
  }

  switchToCreateTask() {
    const parent = $('#selectProjectTaskModalAkapon');
    parent.find('#flag_create_task').val('true');
    parent.find('.select-task-area').addClass('d-none');
    parent.find('.create-task-area').removeClass('d-none');
    parent.find('.last-step').attr('onclick', "Project.backToSelectStep()")
    if(parent.find('#taskName').val().length == 0) {
      parent.find('.next-step').addClass('disabled')
    } else {
      parent.find('.next-step').removeClass('disabled')
    }
  }

  backToSelectStep() {
    const parent = $('#selectProjectTaskModalAkapon');
    parent.find('.select-task-area').removeClass('d-none');
    parent.find('.create-task-area').addClass('d-none');
    parent.find('.last-step').attr('onclick', "Project.resetSelectExistProject()")
    parent.find('#flag_create_task').val('false');
    if(parent.find('#selectTaskId').val()) {
      parent.find('.next-step').removeClass('disabled')
    } else {
      parent.find('.next-step').addClass('disabled')
    }
  }

  selectExistTask(element) {
    const modal = $('#selectProjectTaskModalAkapon');
    const category = $(element).find('option:selected').attr('data-category')
    modal.find('.next-step').removeClass('disabled');
    if(category == 'web') {
      modal.find('.task-category').empty().append('<img src=' + gon.svg.web + ' class="icon mr-1" width="35" height="25"></img>')
    } else if(category == 'video') {
      modal.find('.task-category').empty().append('<img src=' + gon.svg.video + ' class="icon mr-1" width="35" height="25"></img>')
    } else {
      modal.find('.next-step').addClass('disabled');
      modal.find('.task-category').empty()
    }
  }

  showTotalFileSize(file_size) {
    const modal = $('#totalFileSizeProjectModal');
    modal.find('.file-size-content').html(`総容量：${file_size}`)
    modal.modal('show')
  }

  changeStatusSp(project_id, status) {
    $('.modal-status-project').modal('hide')
    $('#tbody-list-project').find(`.select-status-${project_id}`).val(status).change();
  }

  truncateProjectName(text) {
    const dots = '...'
    let textCount = 20;
    if(window.innerWidth <= 550) textCount = 7
    const projectName = text.slice(0, textCount)
    if(projectName.length >= textCount) {
      return projectName + dots
    } else {
      return projectName
    }
  }

  submitCreateTask(element, modal_id, hide_confirm_type) {
    const parent = $(modal_id);
    const confirm_modal = $('#confirmCreateTaskAkapon')
    const project = this;
    if(hide_confirm_type == 'true') {
      parent.find('form#new_project').submit()
    } else {
      parent.addClass('d-none')
      confirm_modal.find('.project-name').html(parent.find('.project-name-preview').text())
      confirm_modal.find('.task-name').html(project.truncateProjectName(parent.find('#taskName').val()))
      if(parent.find('#select_category').val() == 'video') {
        confirm_modal.find('.task-type').html('動画')
      } else {
        confirm_modal.find('.task-type').html('WEB')
      }
      confirm_modal.modal('show')
      confirm_modal.find('.submit-transit-akaire').attr('onclick', `$('${modal_id}').find('form#new_project').submit()`)
      confirm_modal.on('hidden.bs.modal', function () {
        parent.removeClass('d-none')
      })
    }
  }

  submitSelectOrCreateTask(element, modal_id, hide_confirm_type) {
    if($(modal_id).find('#flag_create_task').val() != 'true') {
      return $(modal_id).find('form#new_project').submit()
    }
    this.submitCreateTask(element, modal_id, hide_confirm_type)
  }

  closePopupInviteMember() {
    Visiable.hide('#popupInviteMember')
    $('.modal-backdrop').remove();
  }

  async actionChangeNumberPage(ele, totalObject, parentElement = '#popupInviteMember') {
    let numberOfPage = $(ele).val();
    this.changeNumberPage(ele, totalObject, numberOfPage, parentElement)
  }

  acceptRequestAddMember(requestId) {
    const eleContainer = $('#request-add-member-to-admin');
    const name = eleContainer.find('input[name="request[name]"]').val();
    const email = eleContainer.find('input[name="request[email]"]').val();
    const email_regex = /(\b[^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
    if (!!name && !!email) {
      if (!email_regex.test(email)) {
        Popup.alert(`メールアドレスが正しくありません。`);
        return;
      }

      if (Shared.checkValidationUserName(name)) {
        Popup.alert('「お名前」に利用できない文字があります。<br>お名前を変更してください。')
        return;
      }
      const url = Routes.accept_owner_request_admin_to_invite_member_path(requestId)
      Shared.callAjax(url, { email: email, name: name})
      .done(function(data) {
        $("#requestToAdminAddMember").modal("hide");
        Popup.alert(`${name}さんをメンバーに招待しました。`);
      })
      .fail(function(data) {
        if (data.responseJSON.email_exists) {
          Popup.alert(`このメールアドレスは既に存在します。`);
        }
      })
    } else {
      Popup.alert('空白にはできません。');
    }
  }

  enableButtonInput(element) {
    $(element).addClass('d-none')
    $('.password-field').removeClass('d-none')
    $('.password-field').focus()
    $('.password-field').on("focusout", function() {
      if($(this).val() == '') {
        $(element).removeClass('d-none')
        $('.password-field').addClass('d-none')
      }
    })
  }

  checkValidationInputUser(user_name) {
    let submit_flag = true
    let name = $('#user_name').val();
    let password = $('#user_password').val();
    let passwordConfirmation = $('#user_password_confirmation').val();

    let expressionPassword = /(?=.*[a-zA-Z])(?=.*\d).{8,32}/
    let RegexPassword = new RegExp(expressionPassword);
    if (Shared.checkValidationUserName(name)) {
      Popup.alert('「お名前」に利用できない文字があります。<br>お名前を変更してください。')
      $('#user_name').val(user_name)
      submit_flag = false;
    } else {
      if(!password.match(RegexPassword)) {
        Popup.alert('英・数を各1文字以上で最低8文字以上32文字以下入力してください。E.g: abAB1122')
        submit_flag = false;
      }

      if (password != passwordConfirmation) {
        Popup.alert('パスワードが違います。')
        submit_flag = false;
      }
    }

    if(submit_flag) $('button.sign-up-user').parents('form').submit();
  }
}

function deleteProject(projectId, deleteAtProjectsList) {
  const url = Routes.owner_project_path(projectId)
  Shared.callAjax(url, {}, 'json', 'delete').done(function(data) {
    Popup.confirm('', {
      description: '削除されました。',
      success: () => { handleReloadProjectsList(deleteAtProjectsList) },
      cancel: () => { handleReloadProjectsList(deleteAtProjectsList) },
      width: 'auto',
      confirmText: 'OK',
      cancelBtn: 'false'
    })
  })
  .fail(function(data) {
    Popup.alert(I18n.t('shared.one_error_please_try_again'))
  })
}

function handleReloadProjectsList(deleteAtProjectsList) {
  if (deleteAtProjectsList) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('check_data', `${gon.is_plan_akapon}`);
    window.location.search = urlParams;
  } else {
    window.location.href = Routes.owner_projects_path({check_data: `${gon.is_plan_akapon}`})
  }
}
