import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import iconOpenUrl from '../ui/icons/iconOpenURL.svg';
import reactWindowSize from 'react-window-size';
import VideoAkaireIcon from 'images/video_akaire.png';
import WWWAkaireIcon from 'images/icon-www.png';
import IconUploadImage from 'images/upload_image.png';
import IconUploadYoutube from 'images/upload_youtube.png';

class ListTempFile extends Component {
  constructor() {
    super();
    this.state = {
    };
    this.videoRef = React.createRef();
  }

  checkTypeAnimation = (animation) => {
    if(animation.is_video_embed) {
      return 'youtube'
    } else {
      var url = animation.file_object.url.split('.')
      if(url[url.length - 1] == 'mp4') {
        return 'video'
      } else {
        return 'image'
      }
    }
  }

  getThumbnail = () => {
    if (this.videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = this.videoRef.current.videoWidth;
      canvas.height = this.videoRef.current.videoHeight;
      canvas.getContext('2d').drawImage(this.videoRef.current, 0, 0);

      const thumbnailUrl = canvas.toDataURL();
    }
  };

  getDuration = () => {
    if (this.videoRef.current) {
      return this.formatTime(this.videoRef.current.duration)
    }
  }

  formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedTime = [hours, minutes, remainingSeconds]
      .map(unit => String(unit).padStart(2, '0')) // Thêm số 0 phía trước nếu cần
      .join(':');

    return formattedTime;
  }

  assignAnimationId = (event) => {
    const animation_id = event.target.attributes.getNamedItem('data-animation-id').value;
    const animation_type = event.target.attributes.getNamedItem('data-type').value;
    const isCaptureUrl = event.target.attributes.getNamedItem('data-type-capture-url').value;
    const createFromProjectID = event.target.attributes.getNamedItem('data-from-project-id')?.value;
    const createFromTaskID = event.target.attributes.getNamedItem('data-from-task-id')?.value || null;
    const createFromWorkID = event.target.attributes.getNamedItem('data-from-work-id')?.value || null;
    const createFromAkaireFileID = event.target.attributes.getNamedItem('data-from-akaire-file-id')?.value || null;
    const createFromPageID = event.target.attributes.getNamedItem('data-from-page-id')?.value || null;
    const selectProjectElement = $('#assignAkairePopup').find('.select-project-akaire');
    let select_default = selectProjectElement.attr('data-select-default')
    $('#assignAkairePopup').find('.select-project-akaire option').toArray().forEach(element => {
      $(element).text($(element).text().replace('【作成元】', ''))
    });
    if (!!createFromProjectID) {
      let optionDefault = $('#assignAkairePopup').find(`.select-project-akaire option[value="${createFromProjectID}"]`)
      if(optionDefault.length > 0) {
        optionDefault.text(`【作成元】${optionDefault.text()}`)
        select_default = createFromProjectID
      }
    }

    selectProjectElement.attr('data-from-project-id', createFromProjectID || '')
    $('#assignAkairePopup').find(`.select-task-akaire`).attr('data-from-task-id', createFromTaskID || '')
    $('#assignAkairePopup').find(`.select-ticket-akaire`).attr('data-from-work-id', createFromWorkID || '')
    $('#assignAkairePopup').find(`.select-file-akaire`).attr('data-from-file-id', createFromAkaireFileID || '')
    $('#assignAkairePopup').find(`.select-page-akaire`).attr('data-from-page-id', createFromPageID || '')
    $('#assignAkairePopup').find('#animation_id').val(animation_id)
    $('#assignAkairePopup').find('#animation_type').val(animation_type)
    $('#assignAkairePopup').find('#animation_type').attr('is-capture-url', isCaptureUrl)
    selectProjectElement.val(select_default).trigger('change')
    $('.js-select-save-akaire').select2({
      width: '100%',
      "language": {
        "noResults": function(){
          return "検索に該当する結果はありません。";
        }
      }
    });
    $('.selection').attr("style", 'width: 100%');
    $('.select2-selection--single').attr("style", 'border: none; display: flex');
    ListTempFileJs.checkBlankProject()
  }

  transitPreviewAnimation = (animation_id) => {
    window.location.href = Routes.preview_animation_akaire_feature_akaires_path(animation_id);
  }
  onError = (element) => {
    element.onerror = null;
    element.src = gon.assets.default_loading_thumbnail;
  }

  renderNormal() {
    const { animations } = this.props;
    return (
      <div className='d-flex my-3 col-12 container-list-temp-file inner_1600'>
        {animations.length == 0 &&
          <div class='container-file-box-no-data'>現在、一時保管ファイルはありません。</div>
        }
        {animations.map((animation, index) => (
          <div className='container-file-box col-4'>
            <div className='file-box' key={index} onClick={() => this.transitPreviewAnimation(animation.id)}>
              <div className='animation-display cursor-pointer'>
                {this.checkTypeAnimation(animation) == 'video' &&
                  <div className="display-image-file">
                    <img src={animation.thumbnail} className="image-thumbnail" alt="Your Image" onError={({ currentTarget }) => this.onError(currentTarget)} />
                    <div className='akaire-name'>
                      <span className="text-ellipsis">
                        <img className="create-akaire-box-icon-upload filter-white-icon mr-2"src={VideoAkaireIcon} alt="アップロード" width="45" />
                        {animation.official_name}
                      </span>
                      <span>{animation.additional_info.duration}</span>
                    </div>
                  </div>}

                {this.checkTypeAnimation(animation) == 'image' &&
                  <div className="display-image-file">
                    <img src={animation.thumbnail} className="image-thumbnail" alt="Your Image" onError={({ currentTarget }) => this.onError(currentTarget)} />
                    <div className='akaire-image-name text-ellipsis'>
                      <img className="create-akaire-box-icon-upload filter-white-icon mr-2"src={ animation.official_name ? IconUploadImage : WWWAkaireIcon} alt="アップロード" width="35" />
                      {animation.official_name || animation.url}
                    </div>
                  </div>
                }

                { this.checkTypeAnimation(animation) == 'youtube' &&
                  <div className="display-image-file">
                    <img src={animation.thumbnail} className="image-thumbnail" alt="Thumbnail Youtube" onError={({ currentTarget }) => this.onError(currentTarget)} />
                    <div className='akaire-name'>
                      <span className="text-ellipsis">
                        <img className="create-akaire-box-icon-upload filter-white-icon mr-2"src={IconUploadYoutube} alt="アップロード" width="45" />
                        {animation.additional_info.title || animation.url}
                      </span>
                      <span>{animation.additional_info.duration}</span>
                    </div>
                  </div>
                }
              </div>
              <div className='akaire-information cursor-pointer'>
                <span className="text-ellipsis">{animation.created_name}</span>
                <span className="text-ellipsis">{animation.volumn_file}｜残容量{animation.remain_data}</span>
              </div>
              <div className='akaire-expired cursor-pointer text-ellipsis'>{animation.expired_time}</div>
            </div>
            <div className="text-center div-button-assign-file">
              <Button color="danger" className="button-assign-file" data-animation-id={animation.id} onClick={(event) => this.assignAnimationId(event)} data-type={this.checkTypeAnimation(animation)}
                data-type-capture-url={animation.use_account_login}
                data-from-project-id={animation.additional_info.from_project_id} data-from-task-id={animation.additional_info.from_task_id} data-from-work-id={animation.additional_info.from_work_id}
                data-from-page-id={animation.additional_info.from_page_id} data-from-akaire-file-id={animation.additional_info.from_akaire_file_id} data-toggle="modal">データを保存して作業を開始する</Button>
            </div>
          </div>
        ))}
      </div>
    )
  }

  render(){
    return this.renderNormal()
  }
}
export default reactWindowSize(ListTempFile);

export { ListTempFile };
